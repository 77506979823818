
<template>
	<div
		class="results-grid"
		:class="{ 'results-grid--loading': loadingProjects }"
	>
		<TransitionGroup
			name="grid"
			tag="ul"
			class="grid grid--xs flex flex--wrap relative mb-32"
		>
			<li
				v-for="project in projects"
				:key="'project-' + project.id"
				class="grid__item a3-15 c3-12 e3-6 f6-6 mb-8"
			>
				<router-link
					:to="{ name: 'projects__project', params: { project_id: project.id } }"
					class="project-card"
				>
					<img v-if="getThumbnailOption( project )"
						:src="getThumbnailOption( project ).image_small_url || getThumbnailOption( project ).thumbnail_url"
						:alt="getThumbnailOption( project ).title"
						class="project-card__image"
					>

					<div
						v-else
						class="project-card__image project-card__image--default"
					/>
					<h2 class="project-card__title">
						{{ project.title }}
					</h2>

					<button
						:ref="`favourite-${project.id}`"
						class="project-card__favourite"
						:class="{
							'project-card__favourite--selected': userHasFavouritedProject( project.id ),
							'project-card__favourite--toggled': toggled_favourites.includes( project.id ),
							'project-card__favourite--loading': loading_favourites.includes( project.id )
						}"
						@click.prevent="toggleProjectAsFavourite( project.id )"
					/>

					<router-link
						v-if="project.notification_count"
						:to="{ name: 'projects__project', params: { project_id: project.id }, query: { tab: 'notifications' } }"
						class="project-card__notifications"
					>
						<div class="project-card__alerts">
							<span class="project-card__notifications-count">
								{{ project.notification_count }}
							</span>
						</div>
					</router-link>
				</router-link>
			</li>
		</TransitionGroup>
	</div>
</template>

<script setup>
import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useDataStore } from '../stores/data';
import { getProjectThumbnailOption } from '../../helpers';

const props = defineProps( {
	projects: {
		required: true,
		type: Array
	},
	loadingProjects: {
		required: true,
		type: Boolean
	}
} );

const data_store = useDataStore();

const {
	getUserProfile,
	toggleFavouriteProject
} = data_store;

const { user_profile } = storeToRefs( data_store );

// Projects
const toggled_favourites = ref( [] );
const loading_favourites = ref( [] );

const toggleProjectAsFavourite = async ( project_id ) => {
	const toggled_index = toggled_favourites.value.indexOf( project_id );
	if ( toggled_index ) {
		toggled_favourites.value.splice( toggled_favourites.value.indexOf( project_id ), 1 );
	}
	loading_favourites.value.push( project_id );
	try {
		const response = await toggleFavouriteProject( parseInt( project_id ) );
		if ( response.data ) {
			await getUserProfile();
		}
	} catch ( error ) {
		// console.log( error );
	}
	toggled_favourites.value.push( project_id );
	loading_favourites.value.splice( loading_favourites.value.indexOf( project_id ), 1 );
};

const userHasFavouritedProject = ( project_id ) => {
	if ( !user_profile.value ) {
		return false;
	}
	return user_profile.value.favourite_projects.find( project => parseInt( project.id ) === parseInt( project_id ) );
};

const getThumbnailOption = ( project ) => {
	return getProjectThumbnailOption( project );
};
</script>