import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["src", "alt"]
const _hoisted_2 = {
  key: 1,
  class: "comment-form__thumbnail comment-form__thumbnail--pdf"
}
const _hoisted_3 = { class: "comment-form__filename" }
const _hoisted_4 = ["onClick"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_drag_drop = _resolveComponent("drag-drop")

  return (_openBlock(), _createElementBlock("div", {
    id: "image-upload",
    class: _normalizeClass(["comment-form__drag-drop flex", { 'comment-form__drag-drop--hover': $data.hover }])
  }, [
    ($data.uppy)
      ? (_openBlock(), _createBlock(_component_drag_drop, {
          key: 0,
          uppy: $data.uppy,
          props: $data.uppy_config
        }, null, 8 /* PROPS */, ["uppy", "props"]))
      : _createCommentVNode("v-if", true),
    _createVNode(_TransitionGroup, {
      name: "list",
      tag: "ul",
      class: "comment-form__files"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.files, (file, file_id) => {
          return (_openBlock(), _createElementBlock("li", {
            key: file_id,
            class: "comment-form__file"
          }, [
            (file.preview)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  class: "comment-form__thumbnail",
                  src: file.preview,
                  alt: file.filename
                }, null, 8 /* PROPS */, _hoisted_1))
              : (file.extension === 'pdf')
                ? (_openBlock(), _createElementBlock("div", _hoisted_2))
                : _createCommentVNode("v-if", true),
            _createElementVNode("p", _hoisted_3, _toDisplayString(file.filename), 1 /* TEXT */),
            _createElementVNode("button", {
              class: "comment-form__remove-file",
              onClick: $event => ($options.removeFile( file_id ))
            }, null, 8 /* PROPS */, _hoisted_4)
          ]))
        }), 128 /* KEYED_FRAGMENT */))
      ]),
      _: 1 /* STABLE */
    })
  ], 2 /* CLASS */))
}