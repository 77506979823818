<template>
	<div
		class="upload-modal-wrapper"
		:class="{ 'upload-modal-wrapper--show': show }"
	>
		<div class="upload-modal">
			<button
				class="upload-modal__close"
				aria-label="Close modal"
				@click="closeModal"
			/>
			<Transition name="fade">
				<div
					v-if="upload_format === null"
					class="upload-modal__format-selection"
				>
					<h2 class="upload-modal__format-selection-title">
						Upload Media files
					</h2>
					<p class="upload-modal__format-selection-subtitle">
						Please select the media you wish to upload here
					</p>

					<div class="upload-modal__format-buttons">
						<button
							class="upload-modal__format-button upload-modal__format-button--draft"
							@click="selectDraft"
						>
							Draft
						</button>
						<button
							class="upload-modal__format-button upload-modal__format-button--image"
							@click="selectImage"
						>
							Image
						</button>
						<button
							class="upload-modal__format-button upload-modal__format-button--video"
							@click="selectVideo"
						>
							Video
						</button>
						<button
							class="upload-modal__format-button upload-modal__format-button--explore"
							@click="selectExplore"
						>
							Explore
						</button>
					</div>
				</div>

				<DraftUploadComponent
					v-else-if="is_draft_upload"
					:project="project"
					@back-to-format-select="upload_format = null"
				/>

				<ImageUploadComponent
					v-else-if="is_image_upload"
					:project="project"
					@back-to-format-select="upload_format = null"
				/>

				<VideoUploadComponent
					v-else-if="is_video_upload"
					:project="project"
					@back-to-format-select="upload_format = null"
				/>

				<ExploreUploadComponent
					v-else-if="is_explore_upload"
					:project="project"
					@back-to-format-select="upload_format = null"
				/>
			</Transition>
		</div>
	</div>
</template>

<script>
import DraftUploadComponent from './DraftUploadComponent.vue';
import ImageUploadComponent from './ImageUploadComponent.vue';
import VideoUploadComponent from './VideoUploadComponent.vue';
import ExploreUploadComponent from './ExploreUploadComponent.vue';

import {
	UPLOAD_FORMAT_DRAFT,
	UPLOAD_FORMAT_IMAGE,
	UPLOAD_FORMAT_VIDEO,
	UPLOAD_FORMAT_EXPLORE
} from '../../constants';

export default {
	name: 'UploadModalComponent',
	components: {
		DraftUploadComponent,
		ImageUploadComponent,
		VideoUploadComponent,
		ExploreUploadComponent
	},
	props: {
		show: { required: true, type: Boolean },
		project: { required: true, type: Object }
	},
	emits: [ 'close-modal' ],
	data() {
		return {
			upload_format: null
		};
	},
	computed: {
		is_draft_upload() {
			return this.upload_format === UPLOAD_FORMAT_DRAFT;
		},
		is_image_upload() {
			return this.upload_format === UPLOAD_FORMAT_IMAGE;
		},
		is_video_upload() {
			return this.upload_format === UPLOAD_FORMAT_VIDEO;
		},
		is_explore_upload() {
			return this.upload_format === UPLOAD_FORMAT_EXPLORE;
		}
	},
	methods: {
		selectDraft() {
			this.upload_format = UPLOAD_FORMAT_DRAFT;
		},
		selectImage() {
			this.upload_format = UPLOAD_FORMAT_IMAGE;
		},
		selectVideo() {
			this.upload_format = UPLOAD_FORMAT_VIDEO;
		},
		selectExplore() {
			this.upload_format = UPLOAD_FORMAT_EXPLORE;
		},
		closeModal() {
			this.$emit( 'close-modal' );
			this.upload_format = null;
		}
	}
};
</script>