<template>
	<CheckboxesDropdownComponent
		id="client"
		label="Client"
		placeholder="Search"
		:query="client.search_query"
		:options="filtered_clients"
		:selection="active_client_ids"
		:stretch="true"
		:loading="loading"
		@select="handleClientSelect"
		@search="handleClientSearchInputChange"
	/>
</template>

<script setup>
import CheckboxesDropdownComponent from '../CheckboxesDropdownComponent.vue';

import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useProjectFiltersStore } from '../../stores/project-filters';

const props = defineProps( {
	loading: {
		required: false,
		type: Boolean,
		default: false
	}
} );

const filters_store = useProjectFiltersStore();

const {
	setFilterSearchQuery,
	setActiveFilterValue,
	removeActiveFilterValue,
	resetProjectPagination,
	updateQueryStringFromProjectFilters,
} = filters_store;

const { client, filtered_clients } = storeToRefs( filters_store );

const active_client_ids = computed( () => {
	return client.value.active.map( company => company.value );
} );

const handleClientSelect = ( company ) => {
	company.checked ? setActiveFilterValue( company, 'client' ) : removeActiveFilterValue( company, 'client' );
	resetProjectPagination();
	updateQueryStringFromProjectFilters();
};

const handleClientSearchInputChange = async ( value ) => {
	setFilterSearchQuery( value, 'client' );
};
</script>