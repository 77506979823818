import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["CheckboxesDropdownComponent"], {
    id: "landscape-architect",
    label: "Landscape Architect",
    placeholder: "Search",
    query: $setup.landscape_architect.search_query,
    options: $setup.filtered_landscape_architects,
    selection: $setup.active_landscape_architect_ids,
    stretch: true,
    loading: $props.loading,
    onSelect: $setup.handleArchitectSelect,
    onSearch: $setup.handleArchitectSearchInputChange
  }, null, 8 /* PROPS */, ["query", "options", "selection", "loading"]))
}