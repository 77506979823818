<template>
	<div class="grid flex flex--wrap flex--justify-space-between flex--align-center">
		<div class="grid__item a4-12 c6-12 f6-6">
			<form
				@submit.prevent="updateProfile"
			>
				<div class="form__set">
					<label
						for="first_name"
						class="form__label"
					>
						First name
					</label>
					<input
						id="first_name"
						v-model="form_fields.first_name"
						class="form__input form__input--stretch"
						type="text"
						name="first_name"
					>
					<p
						v-if="this.errors.first_name"
						class="form__error"
					>
						{{  this.errors.first_name }}
					</p>
				</div>

				<div class="form__set">
					<label
						for="last_name"
						class="form__label"
					>
						Last name
					</label>
					<input
						id="last_name"
						v-model="form_fields.last_name"
						class="form__input form__input--stretch"
						type="text"
						name="last_name"
					>
					<p
						v-if="this.errors.last_name"
						class="form__error"
					>
						{{  this.errors.last_name }}
					</p>
				</div>

				<div class="form__set">
					<label
						for="email"
						class="form__label"
					>
						Email
					</label>
					<p>
						{{ user_profile.email }}
					</p>
				</div>

				<div class="form__set">
					<label
						for="photo"
						class="form__label"
					>
						Upload a new photo
					</label>
					<div class="form__file-container">
						<div class="form__file-button">
							<template v-if="form_fields.photo">
								<div class="form__selected-file">
									<img
										:src="form_fields.photo.base64"
										alt=""
									>
								</div>
								<p class="form__selected-file-name">
									{{ form_fields.photo.name }}
								</p>
							</template>
							<div class="form__file-icon icon icon--upload" />
							<p
								class="form__file-instruction"
							>
								Select a file or drag and drop here
							</p>
							<span class="form__file-browse">Browse</span>
							<input
								id="photo"
								ref="photo"
								type="file"
								name="photo"
								class="form__file-input"
								accept="image/jpg, image/png"
								@input="updatePhotoField"
							>
						</div>
					</div>
				</div>

				<p
					v-if="error_message"
				>
					{{ error_message }}
				</p>

				<button
					class="button button--fill mt-32"
					:class="{ 'button--loading' : loading }"
					type="submit"
				>
					Save
				</button>
			</form>
		</div>
	</div>
</template>

<script>
import { useDataStore } from '../stores/data';
import { storeToRefs } from 'pinia';

export default {
	setup() {
		const data_store = useDataStore();
		const { user_profile } = storeToRefs( data_store );
		const {
			addUserPhoto,
			updateUserProfile,
			getUserProfile
		} = data_store;

		return {
			addUserPhoto,
			updateUserProfile,
			getUserProfile,
			user_profile
		};
	},
	data() {
		return {
			loading: false,
			form_fields: {
				first_name: '',
				last_name: '',
				photo: null
			},
			errors: {},
			new_photo_id: null
		}
	},
	mounted() {
		this.form_fields.first_name = this.user_profile.firstName;
		this.form_fields.last_name = this.user_profile.lastName;
	},
	methods: {
		updatePhotoField() {
			const files = this.$refs[ 'photo' ].files;

			if ( files.length > 0 ) {
				const file_name = files[0].name;
				const reader = new FileReader();
				reader.onload = ( event ) => {
					this.form_fields.photo = { name: file_name, base64: event.target.result };
				};
				reader.readAsDataURL( files[0] );
			}
		},
		getFormError( key ) {
			switch( key ) {
				case 'first_name':
					return 'Please enter a first name';
				case 'last_name':
					return 'Please enter a last name';
			}
		},
		async updateProfile() {
			this.errors = {};
			this.loading = true;

			const form_data = {
				first_name: this.form_fields.first_name ? this.form_fields.first_name : this.user_profile.firstName,
				last_name: this.form_fields.last_name ? this.form_fields.last_name : this.user_profile.lastName
			}

			Object.keys( form_data ).forEach( key => {
				if ( !form_data[key] ) {
					this.errors[key] = this.getFormError( key );
				}
			} );

			if ( this.errors.lenght > 0 ) {
				return;
			}

			if ( this.form_fields.photo ) {
				try {
					const photo_response = await this.addUserPhoto(
						{
							filename: this.form_fields.photo.name,
							fileData: this.form_fields.photo.base64
						}
					);
					if ( photo_response.data ) {
						this.new_photo_id = photo_response.data.save_user_photos_Asset.id;
					}
				} catch ( error ) {
					// console.log( error );
				}
			}

			try {
				const user_response = await this.updateUserProfile( {
					first_name: this.form_fields.first_name ? this.form_fields.first_name : this.user_profile.firstName,
					last_name: this.form_fields.last_name ? this.form_fields.last_name : this.user_profile.lastName,
					photo: this.new_photo_id ? parseInt( this.new_photo_id ) : null,
				} );

				if ( user_response.data && user_response.data.updateUserProfile ) {
					await this.getUserProfile();

					this.form_fields = {
						first_name: this.user_profile.firstName,
						last_name: this.user_profile.lastName,
						photo: null
					}
				}
			} catch ( error ) {
				// console.log( error );
			}

			this.loading = false;
		}
	}
}
</script>
