import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["CheckboxesDropdownComponent"], {
    id: "business-development",
    label: "Business Development",
    placeholder: "Search",
    query: $setup.business_development.search_query,
    options: $setup.filtered_business_developers,
    selection: $setup.active_business_development_ids,
    stretch: true,
    loading: $props.loading,
    onSelect: $setup.handleBusinessDeveloperSelect,
    onSearch: $setup.handleBusinessDevelopmentSearchInputChange
  }, null, 8 /* PROPS */, ["query", "options", "selection", "loading"]))
}