<template>
	<div
		v-if="user"
		class="directory-card"
	>
		<div class="directory-card__image">
			<ProfilePictureComponent
				:user="user"
				size="lg"
			/>
		</div>
		<div class="directory-card__info">
			<h3 class="directory-card__title">
				{{ user.fullName }}
			</h3>
			<p class="directory-card__role">
				{{ user.user_role || '-' }}
			</p>
			<a
				v-if="!hideEmail"
				:href="`mailto:${user.email}`"
				class="directory-card__email"
			>
				{{ user.email }}
			</a>
		</div>
	</div>
	<button
		v-else
		class="directory-card directory-card--new"
		@click="$emit('add-users', userType)"
	>
		<div class="directory-card__image directory-card__image--new" />
		<div class="directory-card__info">
			<h3 class="directory-card__title">
				Add {{ userType }}
			</h3>
		</div>
	</button>
</template>

<script>
import ProfilePictureComponent from './ProfilePictureComponent.vue';

export default {
	components: {
		ProfilePictureComponent
	},
	props: {
		user: {
			required: false,
			type: Object,
			default: null
		},
		userType: {
			required: true,
			type: String
		},
		hideEmail: {
			required: false,
			type: Boolean,
			default: false
		}
	},
	emits: ['add-users']
}
</script>
