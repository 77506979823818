<template>
	<CheckboxesDropdownComponent
		id="published"
		label="Published"
		:options="published.options"
		:selection="active_values"
		:stretch="true"
		:search-disabled="true"
		@select="handleOptionSelect"
	/>
</template>

<script setup>
import CheckboxesDropdownComponent from '../CheckboxesDropdownComponent.vue';

import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useProjectFiltersStore } from '../../stores/project-filters';

const filters_store = useProjectFiltersStore();

const {
	setActiveFilterValue,
	removeActiveFilterValue,
	resetProjectPagination,
	updateQueryStringFromProjectFilters,
} = filters_store;

const { published } = storeToRefs( filters_store );

const active_values = computed( () => {
	return published.value.active.map( option => option.value );
} );

const handleOptionSelect = ( option ) => {
	option.checked ? setActiveFilterValue( option, 'published' ) : removeActiveFilterValue( option, 'published' );
	resetProjectPagination();
	updateQueryStringFromProjectFilters();
};
</script>