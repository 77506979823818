
import { defineStore } from 'pinia';
import { useDataStore } from './data.js';
import { router } from '../router.js';

import gql_query_project_tags from '../graphql/query/ProjectTags.gql';
import gql_query_common_project_tags from '../graphql/query/CommonProjectTags.gql';
import gql_query_project_locations from '../graphql/query/ProjectLocations.gql';
import gql_query_offices from '../graphql/query/Offices.gql';
import gql_query_project_types from '../graphql/query/ProjectTypes.gql';
import gql_query_common_project_filters from '../graphql/query/CommonProjectFilters.gql';
import gql_query_companies from '../graphql/query/Companies.gql';
import gql_query_media_types from '../graphql/query/MediaTypes.gql';

import AccountDirectorFilterComponent from '../components/project-filters/AccountDirectorFilterComponent';
import BusinessDevelopmentFilterComponent from '../components/project-filters/BusinessDevelopmentFilterComponent';
import BrandPartnerFilterComponent from '../components/project-filters/BrandPartnerFilterComponent';
import ClientFilterComponent from '../components/project-filters/ClientFilterComponent';
import DeveloperFilterComponent from '../components/project-filters/DeveloperFilterComponent';
import DevelopmentConsultantFilterComponent from '../components/project-filters/DevelopmentConsultantFilterComponent';
import CreativeAgencyFilterComponent from '../components/project-filters/CreativeAgencyFilterComponent';
import SalesMarketingFilterComponent from '../components/project-filters/SalesMarketingFilterComponent';
import HospitalityOperatorFilterComponent from '../components/project-filters/HospitalityOperatorFilterComponent';
import ArchitectFilterComponent from '../components/project-filters/ArchitectFilterComponent';
import LandscapeArchitectFilterComponent from '../components/project-filters/LandscapeArchitectFilterComponent';
import InteriorDesignFilterComponent from '../components/project-filters/InteriorDesignFilterComponent';
import DeliverablesFilterComponent from '../components/project-filters/DeliverablesFilterComponent.vue';
import LeadArtistFilterComponent from '../components/project-filters/LeadArtistFilterComponent';
import LeadOfficeFilterComponent from '../components/project-filters/LeadOfficeFilterComponent';
import ProjectLocationFilterComponent from '../components/project-filters/ProjectLocationFilterComponent.vue';
import ProjectManagerFilterComponent from '../components/project-filters/ProjectManagerFilterComponent';
import ProjectStatusFilterComponent from '../components/project-filters/ProjectStatusFilterComponent.vue';
import ProjectTypeFilterComponent from '../components/project-filters/ProjectTypeFilterComponent.vue';
import PublishedFilterComponent from '../components/project-filters/PublishedFilterComponent.vue';
import CompletionDateFilterComponent from '../components/project-filters/CompletionDateFilterComponent.vue';

const formatDropdownFilterOptions = ( data, options ) => {
	const label_key = options && options.label_key ? options.label_key : 'title';
	const value_key = options && options.value_key ? options.value_key : 'id';
	return data.map( element => {
		return {
			label: element[label_key],
			value: element[value_key]
		}
	} );
};

const normaliseString = ( string ) => {
	return string.normalize( 'NFD' ).replace( /[\u0300-\u036f]/g, '' );
}

const filterResultsByQuery = ( results, query ) => {
	const normalised_query = normaliseString( query.toLowerCase() );
	return results.filter( item => {
		const normalised_item_label = normaliseString( item.label.toLowerCase() );
		return normalised_item_label.includes( normalised_query );
	} );
}

export const useProjectFiltersStore = defineStore( 'project-filters', { // eslint-disable-line snakecasejs/snakecasejs
	persist: false,
	state: () => {
		return {
			active_projects_tab: 'my_projects',
			show_filters: false,
			dropdown_filter_components: [
				{
					value: 'project_location',
					label: 'Location',
					component: ProjectLocationFilterComponent,
					selected: true
				},
				{
					value: 'project_status',
					label: 'Project Status',
					component: ProjectStatusFilterComponent,
					selected: true
				},
				{
					value: 'project_type',
					label: 'Project Type',
					component: ProjectTypeFilterComponent,
					selected: false
				},
				{
					value: 'published',
					label: 'Published',
					component: PublishedFilterComponent,
					selected: true
				},
				{
					value: 'deliverables',
					label: 'Deliverables',
					component: DeliverablesFilterComponent,
					selected: false
				},
				{
					value: 'lead_office',
					label: 'Lead Office',
					component: LeadOfficeFilterComponent,
					selected: true
				},
				{
					value: 'client',
					label: 'Client',
					component: ClientFilterComponent,
					selected: true
				},
				{
					value: 'developer',
					label: 'Developer',
					component: DeveloperFilterComponent,
					selected: false
				},
				{
					value: 'development_consultant',
					label: 'Development Consultant',
					component: DevelopmentConsultantFilterComponent,
					selected: false
				},
				{
					value: 'brand_partner',
					label: 'Brand Partner',
					component: BrandPartnerFilterComponent,
					selected: false
				},
				{
					value: 'creative_agency',
					label: 'Creative Agency',
					component: CreativeAgencyFilterComponent,
					selected: false
				},
				{
					value: 'sales_marketing',
					label: 'Sales & Marketing',
					component: SalesMarketingFilterComponent,
					selected: true
				},
				{
					value: 'hospitality_operator',
					label: 'Hospitality Operator',
					component: HospitalityOperatorFilterComponent,
					selected: false
				},
				{
					value: 'architect',
					label: 'Architect',
					component: ArchitectFilterComponent,
					selected: true
				},
				{
					value: 'landscape_architect',
					label: 'Landscape Architect',
					component: LandscapeArchitectFilterComponent,
					selected: true
				},
				{
					value: 'interior_design',
					label: 'Interior Design',
					component: InteriorDesignFilterComponent,
					selected: false
				},
				{
					value: 'project_manager',
					label: 'Project Manager',
					component: ProjectManagerFilterComponent,
					selected: false
				},
				{
					value: 'account_director',
					label: 'Account Director',
					component: AccountDirectorFilterComponent,
					selected: true
				},
				{
					value: 'lead_artist',
					label: 'Lead Artist',
					component: LeadArtistFilterComponent,
					selected: true
				},
				{
					value: 'business_development',
					label: 'Business Development',
					component: BusinessDevelopmentFilterComponent,
					selected: false
				},
				{
					value: 'completion_date',
					label: 'Completion Date',
					component: CompletionDateFilterComponent,
					selected: true
				}
			],
			project_search_query: '',
			project_pagination: {
				limit: 20,
				page: 1,
				total_count: null
			},
			project_sorting: {
				selected_sort_order: 'title',
				title_sort_order: 'DESC',
				date_sort_order: 'DESC'
			},
			project_status: {
				statuses: [
					{ label: 'Live', value: 'live' },
					{ label: 'Paused', value: 'paused' },
					{ label: 'Completed', value: 'completed' },
				],
				active: []
			},
			published: {
				options: [
					{ label: 'Published', value: true },
					{ label: 'Not Published', value: false },
				],
				active: []
			},
			completion_date: {
				options: [
					{ label: 'Next week', value: 'next_week' },
					{ label: 'Next 2 weeks', value: 'next_2_weeks' },
					{ label: 'Next month', value: 'next_month' },
					{ label: 'Next 3 months', value: 'next_3_months' },
				],
				active: []
			},
			deliverables: {
				media_types: [],
				active: []
			},
			project_type: {
				types: [],
				active: []
			},
			project_tags: {
				search_query: '',
				common_tags: [],
				tags: [],
				active: [],
				total_count: null,
			},
			project_location: {
				search_query: '',
				common_locations: [],
				locations: [],
				active: []
			},
			lead_office: {
				offices: [],
				active: []
			},
			boundary_staff_users: [],
			project_manager: {
				search_query: '',
				common_users: [],
				active: [],
			},
			account_director: {
				search_query: '',
				common_users: [],
				active: [],
			},
			lead_artist: {
				search_query: '',
				common_users: [],
				active: [],
			},
			business_development: {
				search_query: '',
				common_users: [],
				active: [],
			},
			companies: [],
			client: {
				search_query: '',
				common_companies: [],
				active: [],
			},
			brand_partner: {
				search_query: '',
				common_companies: [],
				active: [],
			},
			developer: {
				search_query: '',
				common_companies: [],
				active: [],
			},
			development_consultant: {
				search_query: '',
				common_companies: [],
				active: [],
			},
			creative_agency: {
				search_query: '',
				common_companies: [],
				active: [],
			},
			sales_marketing: {
				search_query: '',
				common_companies: [],
				active: [],
			},
			hospitality_operator: {
				search_query: '',
				common_companies: [],
				active: [],
			},
			architect: {
				search_query: '',
				common_companies: [],
				active: [],
			},
			landscape_architect: {
				search_query: '',
				common_companies: [],
				active: [],
			},
			interior_design: {
				search_query: '',
				common_companies: [],
				active: [],
			},
		};
	},
	actions: {
		async getCommonProjectFilters() {
			const data_store = useDataStore();
			try {
				const response = await data_store.authenticatedQuery( gql_query_common_project_filters, {} );
				if ( !response || !response.data ) {
					return;
				}
				// Users
				this.account_director.common_users = formatDropdownFilterOptions( response.data.globalSet.common_account_directors, { label_key: 'fullName' } );
				this.business_development.common_users = formatDropdownFilterOptions( response.data.globalSet.common_business_development, { label_key: 'fullName' } );
				this.lead_artist.common_users = formatDropdownFilterOptions( response.data.globalSet.common_lead_artists, { label_key: 'fullName' } );
				this.project_manager.common_users = formatDropdownFilterOptions( response.data.globalSet.common_project_managers, { label_key: 'fullName' } );

				// Locations
				this.project_location.common_locations = formatDropdownFilterOptions( response.data.globalSet.common_project_locations );

				//Companies
				this.architect.common_companies = formatDropdownFilterOptions( response.data.globalSet.common_architects );
				this.brand_partner.common_companies = formatDropdownFilterOptions( response.data.globalSet.common_brand_partners );
				this.client.common_companies = formatDropdownFilterOptions( response.data.globalSet.common_clients );
				this.creative_agency.common_companies = formatDropdownFilterOptions( response.data.globalSet.common_creative_agencies );
				this.developer.common_companies = formatDropdownFilterOptions( response.data.globalSet.common_developers );
				this.development_consultant.common_companies = formatDropdownFilterOptions( response.data.globalSet.common_development_consultants );
				this.hospitality_operator.common_companies = formatDropdownFilterOptions( response.data.globalSet.common_hospitality_operators );
				this.interior_design.common_companies = formatDropdownFilterOptions( response.data.globalSet.common_interior_designers );
				this.landscape_architect.common_companies = formatDropdownFilterOptions( response.data.globalSet.common_landscape_architects );
				this.sales_marketing.common_companies = formatDropdownFilterOptions( response.data.globalSet.common_sales_marketing );
			} catch ( error ) {
				return error;
			}
		},
		setFilterSearchQuery( value, filter_handle ) {
			this[filter_handle].search_query = value;
		},
		setActiveFilterValue( value, filter_handle ) {
			this[filter_handle].active.push( value );
		},
		setActiveFilterValues( values, filter_handle ) {
			this[filter_handle].active = values;
		},
		removeActiveFilterValue( value, filter_handle ) {
			const index_to_remove = this[filter_handle].active.findIndex( active_value => active_value.value === value.value );
			if ( index_to_remove < 0 ) {
				return;
			}
			this[filter_handle].active.splice( index_to_remove, 1 );
		},
		setProjectSearchQuery( value ) {
			this.project_search_query = value;
		},
		setActiveProjectsTab( slug ) {
			this.active_projects_tab = slug;
		},
		setProjectSorting( data ) {
			this.project_sorting = data;
		},
		setActiveProjectStatuses( status_slugs ) {
			this.project_status.active = [];
			status_slugs.forEach( slug => {
				const status = this.project_status.statuses.find( status => status.value === slug );
				if ( status ) {
					this.project_status.active.push( status );
				}
			} );
		},
		setActivePublishedValues( values ) {
			this.published.active = [];
			values.forEach( value => {
				const option = this.published.options.find( option => option.value === value );
				if ( option ) {
					this.published.active.push( option );
				}
			} );
		},
		setActiveCompletionDateValues( values ) {
			this.completion_date.active = [];
			values.forEach( value => {
				const option = this.completion_date.options.find( option => option.value === value );
				if ( option ) {
					this.completion_date.active.push( option );
				}
			} );
		},
		async getBoundaryStaffUsers() {
			const data_store = useDataStore();
			try {
				const response = await data_store.getBoundaryStaffUsers();
				if ( !response || !response.data ) {
					return;
				}
				this.boundary_staff_users = formatDropdownFilterOptions( response.data.users, { label_key: 'fullName' } );
			} catch ( error ) {
				return error;
			}
		},
		async getCompanies() {
			const data_store = useDataStore();
			try {
				const response = await data_store.authenticatedQuery( gql_query_companies, { ids: null } );
				if ( !response || !response.data ) {
					return;
				}
				this.companies = formatDropdownFilterOptions( response.data.entries );
			} catch ( error ) {
				return error;
			}
		},
		async getActiveProjectManagers( ids ) {
			const data_store = useDataStore();
			try {
				const response = await data_store.getBoundaryStaffUsers( ids );
				if ( !response || !response.data ) {
					return;
				}
				this.project_manager.active = formatDropdownFilterOptions( response.data.users, { label_key: 'fullName' } );
			} catch ( error ) {
				return error;
			}
		},
		async getActiveAccountDirectors( ids ) {
			const data_store = useDataStore();
			try {
				const response = await data_store.getBoundaryStaffUsers( ids );
				if ( !response || !response.data ) {
					return;
				}
				this.account_director.active = formatDropdownFilterOptions( response.data.users, { label_key: 'fullName' } );
			} catch ( error ) {
				return error;
			}
		},
		async getActiveLeadArtists( ids ) {
			const data_store = useDataStore();
			try {
				const response = await data_store.getBoundaryStaffUsers( ids );
				if ( !response || !response.data ) {
					return;
				}
				this.lead_artist.active = formatDropdownFilterOptions( response.data.users, { label_key: 'fullName' } );
			} catch ( error ) {
				return error;
			}
		},
		async getActiveBusinessDevelopers( ids ) {
			const data_store = useDataStore();
			try {
				const response = await data_store.getBoundaryStaffUsers( ids );
				if ( !response || !response.data ) {
					return;
				}
				this.business_development.active = formatDropdownFilterOptions( response.data.users, { label_key: 'fullName' } );
			} catch ( error ) {
				return error;
			}
		},
		async getActiveCompanies( ids, slug ) {
			const data_store = useDataStore();
			try {
				const response = await data_store.authenticatedQuery( gql_query_companies, { ids: ids } );
				if ( !response || !response.data ) {
					return;
				}
				this[slug].active = formatDropdownFilterOptions( response.data.entries, { label_key: 'title' } );
			} catch ( error ) {
				return error;
			}
		},
		async getCommonProjectLocations() {
			const data_store = useDataStore();
			try {
				const response = await data_store.authenticatedQuery( gql_query_common_project_locations, {} );
				if ( !response || !response.data ) {
					return;
				}
				this.project_location.common_locations = formatDropdownFilterOptions( response.data.globalSet.common_project_locations );
			} catch ( error ) {
				return error;
			}
		},
		async getProjectLocations() {
			const data_store = useDataStore();
			try {
				const response = await data_store.authenticatedQuery( gql_query_project_locations, { ids: null } );
				if ( !response || !response.data ) {
					return;
				}
				this.project_location.locations = formatDropdownFilterOptions( response.data.categories );
			} catch ( error ) {
				return error;
			}
		},
		async getActiveProjectLocations( ids ) {
			const data_store = useDataStore();
			try {
				const response = await data_store.authenticatedQuery( gql_query_project_locations, { ids: ids } );
				if ( !response || !response.data ) {
					return;
				}
				this.project_location.active = formatDropdownFilterOptions( response.data.categories );
			} catch ( error ) {
				return error;
			}
		},
		async getOffices() {
			const data_store = useDataStore();
			try {
				const response = await data_store.authenticatedQuery( gql_query_offices, { ids: null } );
				if ( !response || !response.data ) {
					return;
				}
				this.lead_office.offices = formatDropdownFilterOptions( response.data.categories );
			} catch ( error ) {
				return error;
			}
		},
		async getActiveOffices( ids ) {
			const data_store = useDataStore();
			try {
				const response = await data_store.authenticatedQuery( gql_query_offices, { ids: ids } );
				if ( !response || !response.data ) {
					return;
				}
				this.lead_office.active = formatDropdownFilterOptions( response.data.categories );
			} catch ( error ) {
				return error;
			}
		},
		async getProjectTypes() {
			const data_store = useDataStore();
			try {
				const response = await data_store.authenticatedQuery( gql_query_project_types, { ids: null } );
				if ( !response || !response.data ) {
					return;
				}
				this.project_type.types = formatDropdownFilterOptions( response.data.categories );
			} catch ( error ) {
				return error;
			}
		},
		async getActiveProjectTypes( ids ) {
			const data_store = useDataStore();
			try {
				const response = await data_store.authenticatedQuery( gql_query_project_types, { ids: ids } );
				if ( !response || !response.data ) {
					return;
				}
				this.project_type.active = formatDropdownFilterOptions( response.data.categories );
			} catch ( error ) {
				return error;
			}
		},
		async getDeliverables() {
			const data_store = useDataStore();
			try {
				const response = await data_store.authenticatedQuery( gql_query_media_types, { ids: null } );
				if ( !response || !response.data ) {
					return;
				}
				this.deliverables.media_types = formatDropdownFilterOptions( response.data.categories );
			} catch ( error ) {
				return error;
			}
		},
		async getActiveDeliverables( ids ) {
			const data_store = useDataStore();
			try {
				const response = await data_store.authenticatedQuery(  gql_query_media_types, { ids: ids } );
				if ( !response || !response.data ) {
					return;
				}
				this.deliverables.active = formatDropdownFilterOptions( response.data.categories );
			} catch ( error ) {
				return error;
			}
		},
		async getProjectTags() {
			const data_store = useDataStore();
			try {
				const response = await data_store.authenticatedQuery( gql_query_project_tags, { search: this.project_tags.search_query, ids: null } );
				if ( !response || !response.data ) {
					return;
				}
				this.project_tags.tags = response.data.tags;
			} catch ( error ) {
				return error;
			}
		},
		async getActiveProjectTags( ids ) {
			const data_store = useDataStore();
			try {
				const response = await data_store.authenticatedQuery( gql_query_project_tags, { search: null, ids: ids } );
				if ( !response || !response.data ) {
					return;
				}
				this.project_tags.active = response.data.tags;
			} catch ( error ) {
				return error;
			}
		},
		async getCommonProjectTags() {
			const data_store = useDataStore();
			try {
				const response = await data_store.authenticatedQuery( gql_query_common_project_tags );
				if ( !response || !response.data ) {
					return;
				}
				this.project_tags.common_tags = response.data.globalSet.common_project_tags;
			} catch ( error ) {
				return error;
			}
		},
		async getTotalProjectTagsCount() {
			const data_store = useDataStore();
			try {
				const response = await data_store.authenticatedQuery( gql_query_project_tags, { search: '', ids: null } );
				if ( !response || !response.data ) {
					return;
				}
				this.project_tags.total_count = response.data.tags.length;
			} catch ( error ) {
				return error;
			}
		},
		setActiveProjectTag( tag ) {
			this.project_tags.active.push( tag );
		},
		setActiveProjectTags( tags ) {
			this.project_tags.active = tags;
		},
		removeActiveProjectTag( index ) {
			this.project_tags.active.splice( index, 1 );
		},
		setShowFilters( value ) {
			this.show_filters = value;
		},
		resetProjectPagination() {
			this.project_pagination.page = 1;
		},
		incrementProjectPagination() {
			this.project_pagination.page++;
		},
		updateProjectTotalCount( value ) {
			this.project_pagination.total_count = value;
		},
		updateQueryStringFromProjectFilters() {
			const query = {};
			query.tab = this.active_projects_tab;

			if ( this.active_projects_tab === 'example_projects' ) {
				this.show_filters = false;
				router.push( {
					name: 'projects',
					query
				} );
				return;
			}

			if ( this.project_search_query ) {
				query.search = this.project_search_query;
			}
			if ( this.active_projects_tab === 'my_projects' ) {
				this.show_filters = false;
				router.push( {
					name: 'projects',
					query
				} );
				return;
			}

			if ( this.project_status.active.length ) {
				query.status = this.project_status.active.map( status => status.value );
			}
			if ( this.published.active.length ) {
				query.published = this.published.active.map( option => option.value );
			}
			if ( this.project_type.active.length ) {
				query.type = this.project_type.active.map( type => type.value );
			}
			if ( this.deliverables.active.length ) {
				query.deliverable = this.deliverables.active.map( deliverable => deliverable.value );
			}
			if ( this.project_tags.active.length ) {
				query.tag = this.project_tags.active.map( tag => tag.id );
			}
			if ( this.project_location.active.length ) {
				query.location = this.project_location.active.map( location => location.value );
			}
			if ( this.lead_office.active.length ) {
				query.lead_office = this.lead_office.active.map( office => office.value );
			}
			if ( this.completion_date.active.length ) {
				query.completion_date = this.completion_date.active.map( option => option.value );
			}
			if ( this.project_manager.active.length ) {
				query.project_manager = this.project_manager.active.map( user => user.value );
			}
			if ( this.account_director.active.length ) {
				query.account_director = this.account_director.active.map( user => user.value );
			}
			if ( this.lead_artist.active.length ) {
				query.lead_artist = this.lead_artist.active.map( user => user.value );
			}
			if ( this.business_development.active.length ) {
				query.business_development = this.business_development.active.map( user => user.value );
			}
			if ( this.client.active.length ) {
				query.client = this.client.active.map( client => client.value );
			}
			if ( this.brand_partner.active.length ) {
				query.brand_partner = this.brand_partner.active.map( brand_partner => brand_partner.value );
			}
			if ( this.developer.active.length ) {
				query.developer = this.developer.active.map( developer => developer.value );
			}
			if ( this.development_consultant.active.length ) {
				query.development_consultant = this.development_consultant.active.map( development_consultant => development_consultant.value );
			}
			if ( this.creative_agency.active.length ) {
				query.creative_agency = this.creative_agency.active.map( creative_agency => creative_agency.value );
			}
			if ( this.sales_marketing.active.length ) {
				query.sales_marketing = this.sales_marketing.active.map( sales_marketing => sales_marketing.value );
			}
			if ( this.hospitality_operator.active.length ) {
				query.hospitality_operator = this.hospitality_operator.active.map( hospitality_operator => hospitality_operator.value );
			}
			if ( this.architect.active.length ) {
				query.architect = this.architect.active.map( architect => architect.value );
			}
			if ( this.landscape_architect.active.length ) {
				query.landscape_architect = this.landscape_architect.active.map( landscape_architect => landscape_architect.value );
			}
			if ( this.interior_design.active.length ) {
				query.interior_design = this.interior_design.active.map( interior_design => interior_design.value );
			}

			query.sort = this.project_sorting.selected_sort_order;
			if ( this.project_sorting.selected_sort_order === 'title' ) {
				query.order = this.project_sorting.title_sort_order;
			} else {
				query.order = this.project_sorting.date_sort_order;
			}

			router.push( {
				name: 'projects',
				query
			} );
		},
		removeAllActiveDropdownFilters() {
			this.project_status.active = [];
			this.project_type.active = [];
			this.published.active = [];
			this.project_location.active = [];
			this.lead_office.active = [];
			this.completion_date.active = [];
			this.deliverables.active = [];
			this.project_manager.active = [];
			this.account_director.active = [];
			this.business_development.active = [];
			this.lead_artist.active = [];
			this.client.active = [];
			this.brand_partner.active = [];
			this.developer.active = [];
			this.development_consultant.active = [];
			this.sales_marketing.active = [];
			this.hospitality_operator.active = [];
			this.architect.active = [];
			this.landscape_architect.active = [];
			this.interior_design.active = [];
		},
		resetActiveAdvancedFilters() {
			this.project_tags.active = [];
			this.removeAllActiveDropdownFilters();
		},
		setSelectedDropdownFilters( selection ) {
			this.dropdown_filter_components.forEach( filter => {
				filter.selected = selection.includes( filter.value );
			} );
		},
		removeUnselectedFilters() {
			let active_filters_changed = false;
			this.dropdown_filter_components.forEach( filter => {
				if ( !filter.selected ) {
					if ( this[filter.value].active.length ) {
						this[filter.value].active = [];
						active_filters_changed = true;
					}
				}
			} );
			return active_filters_changed;
		},
		selectMissingActiveDropdowns( selection ) {
			this.dropdown_filter_components.forEach( filter => {
				if ( !filter.selected && selection.includes( filter.value ) ) {
					filter.selected = true;
				}
			} );
		}
	},
	getters: {
		selected_dropdown_filter_components: ( state ) => {
			return state.dropdown_filter_components.filter( filter => filter.selected );
		},
		total_filter_count(state) {
			return state.project_tags.active.length + this.active_dropdown_filters.length;
		},
		active_dropdown_filters: ( state ) => {
			return [
				...state.project_status.active,
				...state.project_type.active,
				...state.published.active,
				...state.deliverables.active,
				...state.project_location.active,
				...state.lead_office.active,
				...state.completion_date.active,
				...state.project_manager.active,
				...state.account_director.active,
				...state.business_development.active,
				...state.lead_artist.active,
				...state.client.active,
				...state.brand_partner.active,
				...state.developer.active,
				...state.development_consultant.active,
				...state.creative_agency.active,
				...state.sales_marketing.active,
				...state.hospitality_operator.active,
				...state.architect.active,
				...state.landscape_architect.active,
				...state.interior_design.active,
			];
		},
		filtered_project_locations: ( state ) => {
			if ( !state.project_location.search_query ) {
				if ( !state.project_location.common_locations.length ) {
					return state.project_location.locations;
				}
				return state.project_location.common_locations;
			}
			return filterResultsByQuery( state.project_location.locations, state.project_location.search_query );
		},
		filtered_account_directors: ( state ) => {
			if ( !state.account_director.search_query ) {
				if ( !state.account_director.common_users.length ) {
					return state.boundary_staff_users;
				}
				return state.account_director.common_users;
			}
			return filterResultsByQuery( state.boundary_staff_users, state.account_director.search_query );
		},
		filtered_project_managers: ( state ) => {
			if ( !state.project_manager.search_query ) {
				if ( !state.account_director.common_users.length ) {
					return state.boundary_staff_users;
				}
				return state.project_manager.common_users;
			}
			return filterResultsByQuery( state.boundary_staff_users, state.project_manager.search_query );
		},
		filtered_lead_artists: ( state ) => {
			if ( !state.lead_artist.search_query ) {
				if ( !state.account_director.common_users.length ) {
					return state.boundary_staff_users;
				}
				return state.lead_artist.common_users;
			}
			return filterResultsByQuery( state.boundary_staff_users, state.lead_artist.search_query );
		},
		filtered_business_developers: ( state ) => {
			if ( !state.business_development.search_query ) {
				if ( !state.account_director.common_users.length ) {
					return state.boundary_staff_users;
				}
				return state.business_development.common_users;
			}
			return filterResultsByQuery( state.boundary_staff_users, state.business_development.search_query );
		},
		filtered_clients: ( state ) => {
			if ( !state.client.search_query ) {
				if ( !state.client.common_companies.length ) {
					return state.companies;
				}
				return state.client.common_companies;
			}
			return filterResultsByQuery( state.companies, state.client.search_query );
		},
		filtered_brand_partners: ( state ) => {
			if ( !state.brand_partner.search_query ) {
				if ( !state.brand_partner.common_companies.length ) {
					return state.companies;
				}
				return state.brand_partner.common_companies;
			}
			return filterResultsByQuery( state.companies, state.brand_partner.search_query );
		},
		filtered_developers: ( state ) => {
			if ( !state.developer.search_query ) {
				if ( !state.developer.common_companies.length ) {
					return state.companies;
				}
				return state.developer.common_companies;
			}
			return filterResultsByQuery( state.companies, state.developer.search_query );
		},
		filtered_development_consultants: ( state ) => {
			if ( !state.development_consultant.search_query ) {
				if ( !state.development_consultant.common_companies.length ) {
					return state.companies;
				}
				return state.development_consultant.common_companies;
			}
			return filterResultsByQuery( state.companies, state.development_consultant.search_query );
		},
		filtered_creative_agencies: ( state ) => {
			if ( !state.creative_agency.search_query ) {
				if ( !state.creative_agency.common_companies.length ) {
					return state.companies;
				}
				return state.creative_agency.common_companies;
			}
			return filterResultsByQuery( state.companies, state.creative_agency.search_query );
		},
		filtered_sales_marketing: ( state ) => {
			if ( !state.sales_marketing.search_query ) {
				if ( !state.sales_marketing.common_companies.length ) {
					return state.companies;
				}
				return state.sales_marketing.common_companies;
			}
			return filterResultsByQuery( state.companies, state.sales_marketing.search_query );
		},
		filtered_hospitality_operators: ( state ) => {
			if ( !state.hospitality_operator.search_query ) {
				if ( !state.hospitality_operator.common_companies.length ) {
					return state.companies;
				}
				return state.hospitality_operator.common_companies;
			}
			return filterResultsByQuery( state.companies, state.hospitality_operator.search_query );
		},
		filtered_architects: ( state ) => {
			if ( !state.architect.search_query ) {
				if ( !state.architect.common_companies.length ) {
					return state.companies;
				}
				return state.architect.common_companies;
			}
			return filterResultsByQuery( state.companies, state.architect.search_query );
		},
		filtered_landscape_architects: ( state ) => {
			if ( !state.landscape_architect.search_query ) {
				if ( !state.landscape_architect.common_companies.length ) {
					return state.companies;
				}
				return state.landscape_architect.common_companies;
			}
			return filterResultsByQuery( state.companies, state.landscape_architect.search_query );
		},
		filtered_interior_designers: ( state ) => {
			if ( !state.interior_design.search_query ) {
				if ( !state.interior_design.common_companies.length ) {
					return state.companies;
				}
				return state.interior_design.common_companies;
			}
			return filterResultsByQuery( state.companies, state.interior_design.search_query );
		}
	}
} );
