<template>
	<div class="filter-modal-wrapper">
		<div class="filter-modal">
			<button
				class="filter-modal__close"
				aria-label="Close modal"
				@click="closeModal"
			/>
			<div class="filter-modal__content">
				<h2 class="filter-modal__heading">
					Choose filters
				</h2>

				<div class="flex flex--wrap flex--gap-extra-large">
					<ul class="mb-16">
						<template
							v-for="filter, index in dropdown_filter_components"
							:key="`dropdown-filter-${filter.value}`"
						>
							<li
								v-if="index < dropdown_filter_components.length / 2"
							>
								<label class="checkbox-wrapper">
									<input
										class="checkbox"
										type="checkbox"
										:value="filter.value"
										v-model="selection"
									>
									<span class="checkbox__label">{{ filter.label }}</span>
								</label>
							</li>
						</template>
					</ul>

					<ul class="mb-16">
						<template
							v-for="filter, index in dropdown_filter_components"
							:key="`dropdown-filter-${filter.value}`"
						>
							<li
								v-if="index >= dropdown_filter_components.length / 2"
							>
								<label class="checkbox-wrapper">
									<input
										class="checkbox"
										type="checkbox"
										:value="filter.value"
										v-model="selection"
									>
									<span class="checkbox__label">{{ filter.label }}</span>
								</label>
							</li>
						</template>
					</ul>
				</div>

				<div class="flex flex--align-end flex--justify-end flex--gap-large">
					<div class="filter-modal__buttons mb-16">
						<button
							class="button"
							:disabled="loading"
							@click="closeModal"
						>
							Cancel
						</button>
						<button
							class="button button--fill"
							@click="setSelectedFilters"
						>
							Save
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { useProjectFiltersStore } from '../../stores/project-filters';
import { ref, onMounted } from 'vue';
import { storeToRefs } from 'pinia';

const emit = defineEmits( ['close-modal'] );

const filter_store = useProjectFiltersStore();

const {
	setSelectedDropdownFilters,
	removeUnselectedFilters,
	updateQueryStringFromProjectFilters
} = filter_store;
const { dropdown_filter_components } = storeToRefs( filter_store );

const selection = ref( [] );

const closeModal = () => {
	emit( 'close-modal' );
};

const setSelectedFilters = () => {
	setSelectedDropdownFilters( selection.value );
	const active_filters_changed = removeUnselectedFilters();
	if ( active_filters_changed ) {
		updateQueryStringFromProjectFilters();
	}
	emit( 'close-modal' );
};

onMounted( () => {
	selection.value = dropdown_filter_components.value.filter( filter => filter.selected ).map( filter => filter.value );
} );
</script>