
<template>
	<div class="search-bar mb-16">
		<div
			class="search-bar__field a4-12"
			@click="focusTagSearch"
		>
			<input
				ref="tag_search_input"
				v-model="project_tags.search_query"
				class="search__input"
				type="text"
				placeholder="Search for a tag"
				@input="handleTagSearchInputChange"
			>
		</div>
	</div>

	<div
		ref="tags_container"
		class="tags-container"
	>
		<TransitionGroup
			name="grid"
			tag="ul"
			class="tags tags--project-search tags--overflow-scroll"
			:class="{
				'tags--loading': loading_tags,
				'tags--overflowing': tags_overflowing
			}"
		>
			<li
				v-for="tag in non_active_tags"
				:key="'non-active-tag-' + tag.id"
				class="tags__tag"
			>
				<button @click="setActiveTag( tag )">
					{{ tag.title }}
				</button>
			</li>

			<li v-if="!loading_tags && ( !non_active_tags || non_active_tags.length === 0 )">
				<p class="tags__none-found">
					No tags found
				</p>
			</li>

			<li
				v-show="show_common_tags"
				class="tags__tag tags__tag--show-all"
			>
				<button @click="showAllTags">
					Show all <template v-if="project_tags.total_count">({{ project_tags.total_count }})</template>
				</button>
			</li>
		</TransitionGroup>
	</div>

	<TransitionGroup
		name="grid"
		tag="ul"
		class="tags mt-8"
	>
		<li
			v-for="tag, index in project_tags.active"
			:key="'active-tag-' + tag.id"
			class="tags__tag tags__tag--selected"
		>
			{{ tag.title }}
			<button
				class="tags__remove-tag"
				@click="removeActiveTag( index )"
			/>
		</li>
	</TransitionGroup>
</template>

<script setup>
import { ref, computed, nextTick, watch, onMounted, onBeforeUnmount } from 'vue';
import { storeToRefs } from 'pinia';
import { useProjectFiltersStore } from '../../stores/project-filters';

const filters_store = useProjectFiltersStore();

const {
	getProjectTags,
	getTotalProjectTagsCount,
	getCommonProjectTags,
	setActiveProjectTag,
	removeActiveProjectTag,
	resetProjectPagination,
	updateQueryStringFromProjectFilters
} = filters_store;

const {
	show_filters,
	project_tags
} = storeToRefs( filters_store );

// Tags
const loading_tags = ref ( false );
const show_common_tags = ref( true );
const tags_overflowing = ref( false );
const tag_search_input = ref( null );
const tags_container = ref( null );

const tags = computed( () => {
	return show_common_tags.value ? project_tags.value.common_tags : project_tags.value.tags;
} );

const non_active_tags = computed( () => {
	return tags.value.filter( tag => !project_tags.value.active.find( active_tag => active_tag.id === tag.id ) );
} );

const showAllTags = () => {
	show_common_tags.value = false;
	fetchTags();
};

const fetchTags = async () => {
	loading_tags.value = true;
	try {
		if ( !project_tags.value.search_query && show_common_tags.value ) {
			await getCommonProjectTags();
			return;
		}
		await getProjectTags();
	} catch ( error ) {
		// console.log( error );
	} finally {
		loading_tags.value = false;
	}
};

const fetchTotalTagCount = async () => {
	await getTotalProjectTagsCount();
};

const setActiveTag = ( tag ) => {
	setActiveProjectTag( tag );
	resetProjectPagination();
	updateQueryStringFromProjectFilters();
	console.log(project_tags.active);
};

const removeActiveTag = ( index ) => {
	removeActiveProjectTag( index );
	resetProjectPagination();
	updateQueryStringFromProjectFilters();
}

const checkTagsOverflow = () => {
	nextTick( () => {
		const container = tags_container.value;
		const tags_list = container ? container.firstChild : null;

		if ( tags_list ) {
			tags_overflowing.value = tags_list.scrollHeight > tags_list.clientHeight || tags_list.scrollWidth > tags_list.clientWidth;
		}
	} );
};

const handleTagSearchInputChange = async ( e ) => {
	const input = e.target.value;
	setTimeout( async () => {
		if ( project_tags.value.search_query === input ) {
			await fetchTags();
			if ( project_tags.value.search_query ) {
				show_common_tags.value = false;
			}
		}
	}, 500 );
};

const focusTagSearch = () => {
	tag_search_input.value.focus();
};

watch( tags, () => {
	checkTagsOverflow();
} );

watch( show_filters, async ( new_value, old_value ) => {
	if ( new_value !== old_value && new_value === false ) {
		show_common_tags.value = true;
		await nextTick();
		fetchTags();
	}
} );

// Mount
onMounted( async () => {
	await fetchTags();
	await fetchTotalTagCount();

	checkTagsOverflow();
	window.addEventListener( 'resize', checkTagsOverflow );

	loading_tags.value = false;
} );

// Destroy
onBeforeUnmount( () => {
	window.removeEventListener( 'resize', this.checkTagsOverflow );
} );
</script>