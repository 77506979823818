import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, Teleport as _Teleport } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "grid grid--xs flex flex--wrap mb-32"
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = { class: "image-grid-card__title" }
const _hoisted_5 = {
  key: 0,
  class: "image-grid-card__details-container"
}
const _hoisted_6 = { class: "image-grid-card__details" }
const _hoisted_7 = ["aria-label", "onClick"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_CommentsGalleryModalComponent = _resolveComponent("CommentsGalleryModalComponent")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        ($props.submission.options && $props.submission.options.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.submission.options, (option, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: 'option-' + option.id,
                  class: "grid__item a6-12 d6-6 mb-8"
                }, [
                  _createElementVNode("div", {
                    class: "image-grid-card image-grid-card--submission",
                    onClick: $event => ($options.openModal( index ))
                  }, [
                    _createElementVNode("img", {
                      src: option.image_small_url || option.thumbnail_url,
                      alt: option.title,
                      class: "image-grid-card__image"
                    }, null, 8 /* PROPS */, _hoisted_3),
                    _createElementVNode("div", {
                      class: _normalizeClass(["image-grid-card__info", { 'image-grid-card__info--with-revision': option.related_revision }])
                    }, [
                      _createElementVNode("p", _hoisted_4, _toDisplayString(option.title), 1 /* TEXT */),
                      (option.related_revision)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                            _createElementVNode("div", _hoisted_6, [
                              _createElementVNode("span", {
                                class: _normalizeClass([
										'image-grid-card__stage',
										$options.getRevisionPipClass( option )
									])
                              }, _toDisplayString($options.getRevisionPipNumber( option )), 3 /* TEXT, CLASS */)
                            ])
                          ]))
                        : _createCommentVNode("v-if", true)
                    ], 2 /* CLASS */),
                    (option.image_small_url)
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 0,
                          class: _normalizeClass(["image-grid-card__checkbox", { 'image-grid-card__checkbox--checked': option.checked }]),
                          "aria-label": option.checked ? `Select image for download` : `Deselect image for download`,
                          onClick: _withModifiers($event => ($options.toggleOptionCheck( index )), ["stop"])
                        }, null, 10 /* CLASS, PROPS */, _hoisted_7))
                      : _createCommentVNode("v-if", true)
                  ], 8 /* PROPS */, _hoisted_2)
                ]))
              }), 128 /* KEYED_FRAGMENT */))
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    }),
    (_openBlock(), _createBlock(_Teleport, { to: "#modal" }, [
      _createVNode(_Transition, { name: "fade" }, {
        default: _withCtx(() => [
          ($data.modal_options && $data.modal_options.length && $data.show_modal)
            ? (_openBlock(), _createBlock(_component_CommentsGalleryModalComponent, {
                key: 0,
                ref: "comments-modal",
                options: $data.modal_options,
                onCloseModal: $options.closeModal
              }, null, 8 /* PROPS */, ["options", "onCloseModal"]))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      })
    ]))
  ], 64 /* STABLE_FRAGMENT */))
}