import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["CheckboxesDropdownComponent"], {
    id: "lead-artist",
    label: "Lead Artist",
    placeholder: "Search",
    query: $setup.lead_artist.search_query,
    options: $setup.filtered_lead_artists,
    selection: $setup.active_lead_artist_ids,
    stretch: true,
    loading: $props.loading,
    onSelect: $setup.handleLeadArtistSelect,
    onSearch: $setup.handleLeadArtistSearchInputChange
  }, null, 8 /* PROPS */, ["query", "options", "selection", "loading"]))
}