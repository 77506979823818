import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["CheckboxesDropdownComponent"], {
    id: "interio-design",
    label: "Interior Design",
    placeholder: "Search",
    query: $setup.interior_design.search_query,
    options: $setup.filtered_interior_designers,
    selection: $setup.active_interior_design_ids,
    stretch: true,
    loading: $props.loading,
    onSelect: $setup.handleDesignerSelect,
    onSearch: $setup.handleDesignerSearchInputChange
  }, null, 8 /* PROPS */, ["query", "options", "selection", "loading"]))
}