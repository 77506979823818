<template>
	<CheckboxesDropdownComponent
		id="lead-office"
		label="Lead Office"
		placeholder="Search"
		:options="lead_office.offices"
		:selection="active_office_ids"
		:stretch="true"
		:search-disabled="true"
		@select="handleOfficeSelect"
	/>
</template>

<script setup>
import CheckboxesDropdownComponent from '../CheckboxesDropdownComponent.vue';

import { computed, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useProjectFiltersStore } from '../../stores/project-filters';

const filters_store = useProjectFiltersStore();

const {
	getOffices,
	setActiveFilterValue,
	removeActiveFilterValue,
	resetProjectPagination,
	updateQueryStringFromProjectFilters
} = filters_store;

const { lead_office } = storeToRefs( filters_store );

const active_office_ids = computed( () => {
	return lead_office.value.active.map( office => office.value );
} );

const handleOfficeSelect = ( office ) => {
	office.checked ? setActiveFilterValue( office, 'lead_office' ) : removeActiveFilterValue( office, 'lead_office' );
	resetProjectPagination();
	updateQueryStringFromProjectFilters();
};

// Mount
onMounted( async () => {
	await getOffices();
} );
</script>