<template>
	<Transition name="fade">
		<div v-if="!loading">
			<h3 class="h4">
				Project tags
			</h3>
			<TransitionGroup
				v-if="project.project_tags?.length"
				name="grid"
				tag="ul"
				class="tags mb-48"
			>
				<li
					v-for="tag in project.project_tags"
					:key="tag.id"
					class="tags__tag tags__tag--selected"
				>
					{{ tag.title }}
					<button
						class="tags__remove-tag"
						@click="removeTag( tag.id )"
					/>
				</li>
			</TransitionGroup>
			<p
				v-else
				class="mb-48"
			>
				No suggested tags found.
			</p>
			<div class="a6-12 d6-6">
				<h3 class="h4">
					Add tags
				</h3>
				<div class="form__set mb-16">
					<label
						for="new_tag"
						class="form__label"
					>
						Enter a tag
					</label>
					<input
						ref="new-tag-input"
						id="new_tag"
						v-model="new_tag"
						class="form__input form__input--lowercase"
						type="text"
						name="new_tag"
						:disabled="loading_tag"
						@keyup.enter="submitNewTag"
					>
				</div>

				<button
					class="button button--small mb-16"
					:class="{ 'button--loading': loading_tag }"
					:disabled="!new_tag || loading_tag"
					@click="submitNewTag"
				>
					Create tag
				</button>

				<p
					v-if="error_message"
					class="error-message"
				>
					{{ error_message }}
				</p>
			</div>
		</div>
		<PageLoaderComponent v-else />
	</Transition>
</template>

<script>
import { useDataStore } from '../stores/data';

import PageLoaderComponent from './PageLoaderComponent.vue';

export default {
	components: {
		PageLoaderComponent
	},
	props: {
		project: {
			required: true,
			type: Object
		}
	},
	setup() {
		const data_store = useDataStore();
		const { getProjectTagsByTitle, saveProjectTag, saveTagsToProject } = data_store;
		return { getProjectTagsByTitle, saveProjectTag, saveTagsToProject };
	},
	data() {
		return {
			loading: false,
			loading_tag: false,
			new_tag: '',
			error_message: null
		};
	},
	methods: {
		async addTag( title ) {
			this.error_message = null;
			this.loading_tag = true;

			let matching_tag = null;

			try {
				const response = await this.getProjectTagsByTitle( title );
				if ( response.data.tags && response.data.tags.length ) {
					matching_tag = response.data.tags.find( tag => tag.title === title );
				}
			} catch ( error ) {
				// this.error_message = error;
			}


			if ( !matching_tag ) {
				try {
					const response = await this.saveProjectTag( title );
					matching_tag = response.data.save_project_tags_Tag;
				} catch ( error ) {
					this.error_message = error;
					this.loading = false;
					return;
				}
			}

			try {
				if ( !matching_tag ) {
					this.error_message = 'Something went wrong';
					this.loading_tag = false;
					return;
				}
				const tag_id = matching_tag.id;
				const tags = [
					...this.project.project_tags.map( tag => parseInt( tag.id ) ),
					parseInt( tag_id )
				];
				const response = await this.saveTagsToProject( { project_id: this.project.id, tags: tags } );
				this.project.project_tags = response.data.save_projects_default_Entry.project_tags;
			} catch ( error ) {
				this.error_message = error;
				this.loading_tag = false;
				return;
			}

			this.loading_tag = false;
		},
		async removeTag( tag_id ) {
			this.loading = true;
			try {
				const tags = [
					...this.project.project_tags
						.filter( tag => tag.id !== tag_id )
						.map( tag => parseInt( tag.id ) )
				];
				const response = await this.saveTagsToProject( { project_id: this.project.id, tags: tags } );
				this.project.project_tags = response.data.save_projects_default_Entry.project_tags;
			} catch ( error ) {
				this.error_message = error;
				this.loading = false;
				return;
			}

			this.loading = false;
		},
		async submitNewTag() {
			if ( !this.new_tag ) {
				return;
			}
			await this.addTag( this.new_tag );
			this.new_tag = '';
			this.$refs['new-tag-input'].focus();
		}
	}
};
</script>

<style>

</style>