<template>
	<Transition name="fade">
		<PageLoaderComponent v-if="loading" />
		<div
			v-else
			class="container"
		>
			<TabNavigationComponent
				v-if="tabs"
				:tabs="tabs"
				:active-tab="active_tab"
				@set-tab="setTab"
			/>
			<div class="flex flex--gap-large flex--align-center mb-32">
				<p>
					View:
				</p>
				<LightswitchComponent
					label="All"
					off-label="All"
					:checked="all_filters_active"
					:disabled="loading || loading_notifications"
					@change="toggleAllFilters"
				/>
				<LightswitchComponent
					v-for="filter, index in filters"
					:key="`filter-${index}`"
					:label="filter.label"
					:off-label="filter.label"
					:checked="filter.active"
					:disabled="loading || loading_notifications"
					@change="toggleFilter( filter )"
				/>
			</div>

			<PageLoaderComponent v-if="loading_first_page_notifications" />
			<NotificationsComponent
				v-else
				:notifications="all_notifications"
				:pending-count="all_notifications_pagination.pending_count"
				:loading="loading_notifications"
				@mark-all-as-read="markAllAsRead"
			/>

			<div
				v-show="all_notifications_pagination.total_count > all_notifications.length"
				ref="load_more_trigger"
				class="load-more-trigger"
			/>
		</div>
	</Transition>
</template>

<script>
import PageLoaderComponent from '../components/PageLoaderComponent.vue';
import NotificationsComponent from '../components/NotificationsComponent.vue';
import TabNavigationComponent from '../components/TabNavigationComponent.vue';
import LightswitchComponent from '../components/LightswitchComponent.vue';

import { USER_GROUP_STAFF } from '../constants';
import { useDataStore } from '../stores/data';
import { storeToRefs } from 'pinia';
import { ref } from 'vue';
import { useElementVisibility } from '@vueuse/core';

export default {
	components: {
		PageLoaderComponent,
		NotificationsComponent,
		TabNavigationComponent,
		LightswitchComponent
	},
	setup() {
		const data_store = useDataStore();
		const {
			all_notifications,
			all_notifications_pagination,
			all_notifications_filters,
			loading_first_page_notifications,
			user_group
		} = storeToRefs( data_store );
		const {
			getAllNotifications,
			markAllNotificationsAsRead,
			setNotificationFilter,
			setBreadcrumbs,
		} = data_store;

		const load_more_trigger = ref( null );
		const trigger_is_visible = useElementVisibility( load_more_trigger );

		return {
			all_notifications,
			all_notifications_pagination,
			all_notifications_filters,
			loading_first_page_notifications,
			user_group,
			load_more_trigger,
			trigger_is_visible,
			getAllNotifications,
			markAllNotificationsAsRead,
			setNotificationFilter,
			setBreadcrumbs,
		};
	},
	data() {
		return {
			loading: false,
			loading_notifications: false,
			active_tab: 'my_projects',
		};
	},
	computed: {
		user_is_staff() {
			return this.user_group === USER_GROUP_STAFF;
		},
		my_projects_only() {
			return this.active_tab === 'my_projects';
		},
		tabs() {
			if ( !this.user_is_staff ) {
				return null;
			}
			return [
				{
					slug: 'my_projects',
					label: 'My projects'
				},
				{
					slug: 'all_projects',
					label: 'All projects'
				},
			];
		},
		filters() {
			if ( this.user_is_staff ) {
				return this.all_notifications_filters.filter( filter => filter.slug !== 'submissions' );
			}
			return this.all_notifications_filters.filter( filter => filter.slug !== 'uploads'  && filter.slug !== 'client_approvals' )
		},
		all_filters_active() {
			return this.filters.every( filter => filter.active === true );
		}
	},
	watch: {
		async trigger_is_visible( new_value, old_value ) {
			if ( new_value && !old_value && !this.loading_notifications ) {
				this.loading_notifications = true;
				await this.getAllNotifications( {
					user_related: this.my_projects_only,
					page: this.all_notifications_pagination.page + 1,
					per_page: this.all_notifications_pagination.per_page
				} );
				this.loading_notifications = false;
			}
		},
		async my_projects_only( new_value, old_value ) {
			if ( new_value !== old_value ) {
				this.loading_notifications = true;
				await this.getFirstPageOfNotifications();
				this.loading_notifications = false;
			}
		},
	},
	async mounted() {
		this.loading = true;
		await this.getFirstPageOfNotifications();
		this.setBreadcrumbs( [ { label: 'Notifications', icon: 'bell'} ] );
		this.loading = false;
	},
	methods: {
		async getFirstPageOfNotifications() {
			await this.getAllNotifications( {
				user_related: this.my_projects_only,
				page: 1,
				per_page: 10
			} );
		},
		async markAllAsRead() {
			await this.markAllNotificationsAsRead( { user_related: this.my_projects_only } );
		},
		setTab( slug ) {
			this.active_tab = slug;
		},
		async toggleAllFilters() {
			if ( this.all_filters_active ) {
				this.filters.forEach( filter => {
					if ( filter.active ) {
						this.setNotificationFilter( filter.slug, false );
					}
				} );
			} else {
				this.filters.forEach( filter => {
					if ( !filter.active ) {
						this.setNotificationFilter( filter.slug, true );
					}
				} );
			}

			this.loading_notifications = true;
			await this.getFirstPageOfNotifications();
			this.loading_notifications = false;
		},
		async toggleFilter( filter ) {
			this.setNotificationFilter( filter.slug, !filter.active );

			this.loading_notifications = true;
			await this.getFirstPageOfNotifications();
			this.loading_notifications = false;
		}
	}
};
</script>
