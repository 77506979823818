<template>
	<CheckboxesDropdownComponent
		id="creative-agency"
		label="Creative Agency"
		placeholder="Search"
		:query="creative_agency.search_query"
		:options="filtered_creative_agencies"
		:selection="active_creative_agency_ids"
		:stretch="true"
		:loading="loading"
		@select="handleAgencySelect"
		@search="handleAgencySearchInputChange"
	/>
</template>

<script setup>
import CheckboxesDropdownComponent from '../CheckboxesDropdownComponent.vue';

import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useProjectFiltersStore } from '../../stores/project-filters';

const props = defineProps( {
	loading: {
		required: false,
		type: Boolean,
		default: false
	}
} );

const filters_store = useProjectFiltersStore();

const {
	setFilterSearchQuery,
	setActiveFilterValue,
	removeActiveFilterValue,
	resetProjectPagination,
	updateQueryStringFromProjectFilters,
} = filters_store;

const { creative_agency, filtered_creative_agencies } = storeToRefs( filters_store );

const active_creative_agency_ids = computed( () => {
	return creative_agency.value.active.map( company => company.value );
} );

const handleAgencySelect = ( company ) => {
	company.checked ? setActiveFilterValue( company, 'creative_agency' ) : removeActiveFilterValue( company, 'creative_agency' );
	resetProjectPagination();
	updateQueryStringFromProjectFilters();
};

const handleAgencySearchInputChange = async ( value ) => {
	setFilterSearchQuery( value, 'creative_agency' );
};
</script>