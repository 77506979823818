<template>
	<CheckboxesDropdownComponent
		id="lead-artist"
		label="Lead Artist"
		placeholder="Search"
		:query="lead_artist.search_query"
		:options="filtered_lead_artists"
		:selection="active_lead_artist_ids"
		:stretch="true"
		:loading="loading"
		@select="handleLeadArtistSelect"
		@search="handleLeadArtistSearchInputChange"
	/>
</template>

<script setup>
import CheckboxesDropdownComponent from '../CheckboxesDropdownComponent.vue';

import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useProjectFiltersStore } from '../../stores/project-filters';

const props = defineProps( {
	loading: {
		required: false,
		type: Boolean,
		default: false
	}
} );

const filters_store = useProjectFiltersStore();

const {
	setFilterSearchQuery,
	setActiveFilterValue,
	removeActiveFilterValue,
	resetProjectPagination,
	updateQueryStringFromProjectFilters,
} = filters_store;

const { lead_artist, filtered_lead_artists } = storeToRefs( filters_store );

const active_lead_artist_ids = computed( () => {
	return lead_artist.value.active.map( user => user.value );
} );

const handleLeadArtistSelect = ( user ) => {
	user.checked ? setActiveFilterValue( user, 'lead_artist' ) : removeActiveFilterValue( user, 'lead_artist' );
	resetProjectPagination();
	updateQueryStringFromProjectFilters();
};

const handleLeadArtistSearchInputChange = async ( value ) => {
	setFilterSearchQuery( value, 'lead_artist' );
};
</script>