<template>
	<div class="activity-sidebar">
		<div class="activity-sidebar__content-wrapper">
			<div
				class="activity-sidebar__content"
				:class="{ 'activity-sidebar__content--loading': loading }"
			>
				<div class="activity-sidebar__header">
					<h2>
						My notifications
					</h2>

					<router-link
						class="arrow-link"
						:to="{ name: 'notifications' }"
					>
						See all
					</router-link>
				</div>

				<SidebarNotificationsComponent
					:notifications="notifications"
				/>

				<div
					v-if="notifications.length"
					class="flex flex--justify-center mb-16"
				>
					<router-link
						class="arrow-link"
						:to="{ name: 'notifications' }"
					>
						See all notifications
					</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import SidebarNotificationsComponent from '../SidebarNotificationsComponent.vue';

import { useDataStore } from '../../stores/data';
import { storeToRefs } from 'pinia';

export default {
	components: {
		SidebarNotificationsComponent
	},
	props: {
		expand: { required: true, type: Boolean }
	},
	setup() {
		const data_store = useDataStore();
		const { getAllNotifications } = data_store;
		const { all_notifications, all_notifications_pagination } = storeToRefs( data_store );
		return {
			all_notifications,
			all_notifications_pagination,
			getAllNotifications
		};
	},
	data() {
		return {
			loading: false
		};
	},
	computed: {
		notifications() {
			if ( !this.all_notifications ) {
				return [];
			}
			return this.all_notifications.slice( 0, 10 );
		}
	},
	async mounted() {
		this.loading = true;
		await this.getAllNotifications( {
			per_page: 10,
			user_related: true,
			page: 1
		} );
		this.loading = false;
	}
};
</script>
