<template>
	<CheckboxesDropdownComponent
		id="deliverables"
		label="Deliverables"
		placeholder="Search"
		:options="deliverables.media_types"
		:selection="active_deliverables"
		:stretch="true"
		:search-disabled="true"
		:loading="loading_locations"
		@select="handleDeliverableSelect"
	/>
</template>

<script setup>
import CheckboxesDropdownComponent from '../CheckboxesDropdownComponent.vue';

import { ref, computed, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useProjectFiltersStore } from '../../stores/project-filters';

const filters_store = useProjectFiltersStore();

const {
	getDeliverables,
	setActiveFilterValue,
	removeActiveFilterValue,
	resetProjectPagination,
	updateQueryStringFromProjectFilters,
} = filters_store;

const { deliverables } = storeToRefs( filters_store );

const loading_deliverables = ref( true );

const active_deliverables = computed( () => {
	return deliverables.value.active.map( deliverable => deliverable.value );
} );

const handleDeliverableSelect = ( deliverable ) => {
	deliverable.checked ? setActiveFilterValue( deliverable, 'deliverables' ) : removeActiveFilterValue( deliverable, 'deliverables' );
	resetProjectPagination();
	updateQueryStringFromProjectFilters();
};

// Mount
onMounted( async () => {
	await getDeliverables();

	loading_deliverables.value = false;
} );
</script>