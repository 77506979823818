<template>
	<CheckboxesDropdownComponent
		id="location"
		label="Location"
		placeholder="Search"
		:query="project_location.search_query"
		:options="filtered_project_locations"
		:selection="active_project_location_ids"
		:stretch="true"
		@search="handleProjectLocationSearchInputChange"
		@select="handleLocationSelect"
	/>
</template>

<script setup>
import CheckboxesDropdownComponent from '../CheckboxesDropdownComponent.vue';

import { computed, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useProjectFiltersStore } from '../../stores/project-filters';

const filters_store = useProjectFiltersStore();

const {
	setFilterSearchQuery,
	setActiveFilterValue,
	removeActiveFilterValue,
	resetProjectPagination,
	updateQueryStringFromProjectFilters
} = filters_store;

const { project_location, filtered_project_locations } = storeToRefs( filters_store );

const active_project_location_ids = computed( () => {
	return project_location.value.active.map( location => location.value );
} );

const handleLocationSelect = ( location ) => {
	location.checked ? setActiveFilterValue( location, 'project_location' ) : removeActiveFilterValue( location, 'project_location' );
	resetProjectPagination();
	updateQueryStringFromProjectFilters();
};

const handleProjectLocationSearchInputChange = async ( value ) => {
	setFilterSearchQuery( value, 'project_location' );
};
</script>