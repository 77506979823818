<template>
	<div
		class="support-card"
		:class="`support-card--${colour}`"
	>
		<div class="support-card__header">
			<div
				class="support-card__icon"
				:class="'support-card__icon--' + icon"
			/>
			<TabNavigationComponent
				v-if="showVisibilityToggle"
				:tabs="visibilities"
				:active-tab="active_visibility"
				:no-margin="true"
				:theme="`${colour}`"
				@set-tab="setVisibility"
			/>
		</div>
		<div class="support-card__content">
			<div class="support-card__summary">
				<div>
					<h2 class="support-card__heading">
						{{ title }}
					</h2>
					<ul
						v-if="filtered_slideshows.length"
						class="support-card__item-list"
					>
						<div class="support-card__item-list-items">
							<li
								v-for="slideshow, index in filtered_slideshows"
								:key="`slideshow-${index}`"
								class="support-card__item-list-item support-card__item-list-item--arrow"
							>
								<button
									v-if="slideshow.slideshow_url"
									class="support-card__item-link"
									@click="active_slideshow = slideshow"
								>
									<span>
										{{ slideshow.title }}
									</span>
								</button>
								<span v-else>
									{{ slideshow.title }}
								</span>
								<ul
									v-if="slideshow.children && slideshow.children.length"
									class="support-card__item-list support-card__item-list--sub"
								>
									<div class="support-card__item-list-items">
										<li
											v-for="child_slideshow, i in filterChildren( slideshow.children )"
											:key="`slideshow-child-${index}-${i}`"
											class="support-card__item-list-item support-card__item-list-item--arrow"
										>
											<button
												v-if="child_slideshow.slideshow_url"
												class="support-card__item-link"
												@click="active_slideshow = slideshow"
											>
												<span>
													{{ child_slideshow.title }}
												</span>
											</button>
											<span v-else>
												{{ child_slideshow.title }}
											</span>
										</li>
									</div>
								</ul>
							</li>
						</div>
					</ul>
					<ul
						v-if="filtered_links.length"
						class="support-card__item-list"
					>
						<div class="support-card__item-list-items">
							<li
								v-for="link, index in filtered_links"
								:key="`link-${index}`"
								class="support-card__item-list-item support-card__item-list-item--external"
							>
								<a
									v-if="link.gitbook_page_url"
									:href="link.gitbook_page_url"
									target="_blank"
									class="support-card__item-link"
								>
									<span>
										{{ link.title }}
									</span>
								</a>
								<span v-else>
									{{ link.title }}
								</span>
								<ul
									v-if="link.children && link.children.length"
									class="support-card__item-list support-card__item-list--sub"
								>
									<div class="support-card__item-list-items">
										<li
											v-for="child_link, i in filterChildren( link.children )"
											:key="`link-child-${index}-${i}`"
											class="support-card__item-list-item support-card__item-list-item--external"
										>
											<a
												v-if="child_link.gitbook_page_url"
												:href="child_link.gitbook_page_url"
												target="_blank"
												class="support-card__item-link"
											>
												<span>
													{{ child_link.title }}
												</span>
											</a>
											<span v-else>
												{{ child_link.title }}
											</span>
										</li>
									</div>
								</ul>
							</li>
						</div>
					</ul>

					<ul
						v-if="isDevelopmentCard"
						class="support-card__item-list"
					>
						<div class="support-card__item-list-items">
							<li
								v-if="active_visibility === 'staff'"
								class="support-card__item-list-item support-card__item-list-item--external"
							>
								<a
									href="https://app.gitbook.com/o/yopOHsDRsBst8DT9oIRY/s/6U0Pq8qmhZ0b47iYaBG7/new-features"
									target="_blank"
									class="support-card__item-link"
								>
									<span>
										New features
									</span>
								</a>
							</li>
							<li class="support-card__item-list-item support-card__item-list-item--arrow">
								<router-link
									:to="{
										name: 'support__bug-report'
									}"
									class="support-card__item-link"
								>
									<span>
										Bug report
									</span>
								</router-link>
							</li>
							<li class="support-card__item-list-item support-card__item-list-item--arrow">
								<router-link
									:to="{
										name: 'support__feature-request'
									}"
									class="support-card__item-link"
								>
									<span>
										Feature request
									</span>
								</router-link>
							</li>
						</div>
					</ul>
				</div>
			</div>
		</div>
	</div>

	<Teleport to="#modal">
		<Transition name="fade">
			<SlideshowModalComponent
				v-if="active_slideshow"
				:slideshow="active_slideshow"
				@close-modal="active_slideshow = null"
			/>
		</Transition>
	</Teleport>
</template>

<script>
import SlideshowModalComponent from './modals/SlideshowModalComponent';
import TabNavigationComponent from './TabNavigationComponent.vue';

import { useDataStore } from '../stores/data';
import {
	USER_GROUP_CLIENT
} from '../constants';

export default {
  	components: {
		TabNavigationComponent,
		SlideshowModalComponent
	},
	props: {
		title: {
			required: true,
			type: String
		},
		icon: {
			required: true,
			type: String
		},
		colour: {
			required: true,
			type: String
		},
		slideshows: {
			required: false,
			type: Array,
			default: []
		},
		links: {
			required: false,
			type: Array,
			default: []
		},
		showVisibilityToggle: {
			required: false,
			type: Boolean,
			default: false
		},
		isDevelopmentCard: {
			required: false,
			type: Boolean,
			default: false
		}
	},
	setup() {
		const { user_group } = useDataStore();
		return { user_group };
	},
	data() {
		return {
			active_visibility: 'staff',
			visibilities: [
				{
					slug: 'staff',
					label: 'Staff'
				},
				{
					slug: 'client',
					label: 'Client'
				}
			],
			active_slideshow: null
		}
	},
	mounted(){
		if ( this.user_group === USER_GROUP_CLIENT ) {
			this.active_visibility = 'client';
		}
	},
	computed: {
		filtered_slideshows() {
			return this.slideshows.filter( slideshow => {
				return !slideshow.visibility || slideshow.visibility.includes( this.active_visibility )
			} );
		},
		filtered_links() {
			return this.links.filter( link => {
				return !link.visibility || link.visibility.includes( this.active_visibility )
		 	} );
		}
	},
	methods: {
		setVisibility( slug ) {
			this.active_visibility = slug;
		},
		filterChildren( children ) {
			return children.filter( child => {
				return !child.visibility || child.visibility.includes( this.active_visibility );
			} );
		}
	}
}
</script>
