<template>
	<CheckboxesDropdownComponent
		id="project-manager"
		label="Project Manager"
		placeholder="Search"
		:query="project_manager.search_query"
		:options="filtered_project_managers"
		:selection="active_project_manager_ids"
		:stretch="true"
		:loading="loading"
		@select="handleProjectManagerSelect"
		@search="handleProjectManagerSearchInputChange"
	/>
</template>

<script setup>
import CheckboxesDropdownComponent from '../CheckboxesDropdownComponent.vue';

import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useProjectFiltersStore } from '../../stores/project-filters';

const props = defineProps( {
	loading: {
		required: false,
		type: Boolean,
		default: false
	}
} );

const filters_store = useProjectFiltersStore();

const {
	setFilterSearchQuery,
	setActiveFilterValue,
	removeActiveFilterValue,
	resetProjectPagination,
	updateQueryStringFromProjectFilters,
} = filters_store;

const { project_manager, filtered_project_managers } = storeToRefs( filters_store );

const active_project_manager_ids = computed( () => {
	return project_manager.value.active.map( user => user.value );
} );

const handleProjectManagerSelect = ( user ) => {
	user.checked ? setActiveFilterValue( user, 'project_manager' ) : removeActiveFilterValue( user, 'project_manager' );
	resetProjectPagination();
	updateQueryStringFromProjectFilters();
};

const handleProjectManagerSearchInputChange = async ( value ) => {
	setFilterSearchQuery( value, 'project_manager' );
};
</script>