<template>
	<div
		class="page-loader-wrapper"
		:class="{
			'page-loader-wrapper--small': small,
			'page-loader-wrapper--smaller': smaller
		}"
	>
		<svg
			class="page-loader"
			width="38"
			height="38"
			viewBox="0 0 38 38"
			xmlns="http://www.w3.org/2000/svg"
		>
			<defs>
				<linearGradient
					id="a"
					x1="8.042%"
					y1="0%"
					x2="65.682%"
					y2="23.865%"
				>
					<stop
						:stop-color="invertColours ? '#fff' : '#58614d'"
						stop-opacity="0"
						offset="0%"
					/>
					<stop
						:stop-color="invertColours ? '#fff' : '#58614d'"
						stop-opacity=".631"
						offset="63.146%"
					/>
					<stop
						:stop-color="invertColours ? '#fff' : '#58614d'"
						offset="100%"
					/>
				</linearGradient>
			</defs>
			<g
				fill="none"
				fill-rule="evenodd"
			>
				<g transform="translate(1 1)">
					<path
						id="Oval-2"
						d="M36 18c0-9.94-8.06-18-18-18"
						stroke="url(#a)"
						stroke-width="2"
					>
						<animateTransform
							attributeName="transform"
							type="rotate"
							from="0 18 18"
							to="360 18 18"
							dur="0.9s"
							repeatCount="indefinite"
						/>
					</path>
					<circle
						:fill="invertColours ? '#fff' : '#58614d'"
						cx="36"
						cy="18"
						r="1"
					>
						<animateTransform
							attributeName="transform"
							type="rotate"
							from="0 18 18"
							to="360 18 18"
							dur="0.9s"
							repeatCount="indefinite"
						/>
					</circle>
				</g>
			</g>
		</svg>

	</div>
</template>

<script>
export default {
	props: {
		invertColours: {
			required: false,
			type: Boolean,
			default: false
		},
		small: {
			required: false,
			type: Boolean,
			default: false
		},
		smaller: {
			required: false,
			type: Boolean,
			default: false
		}
	}
};
</script>
