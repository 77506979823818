<template>
	<div class="mb-16">
		<TransitionGroup
			v-if="notifications && notifications.length"
			name="list"
			tag="ul"
		>
			<SidebarNotificationComponent
				v-for="notification in notifications"
				:key="notification.id"
				:notification="notification"
			/>
		</TransitionGroup>
		<div
			v-else
			class="activity-sidebar__no-content"
		>
			<p>No new notifications.</p>
		</div>
	</div>
</template>

<script>
import SidebarNotificationComponent from './SidebarNotificationComponent.vue';

export default {
	components: {
		SidebarNotificationComponent
	},
	props: {
		notifications: { required: true, type: Array }
	}
};
</script>
