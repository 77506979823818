<template>
	<div
		class="lightswitch"
		:class="[
			{ 'lightswitch--disabled': disabled },
			{ 'lightswitch--justify-end': justify === 'end' }
		]"
	>
		{{ checked ? label : offLabel ?? label }}
		<label class="lightswitch__input">
			<input
				v-model="checked"
				:checked="checked"
				type="checkbox"
				@change="$emit( 'change', $event.target.checked )"
			>
			<span class="lightswitch__toggle" />
		</label>
	</div>
</template>

<script>
export default {
	props: {
		label: {
			required: false,
			type: String,
			default: null
		},
		offLabel: {
			required: false,
			type: String,
			default: null
		},
		justify: {
			required: false,
			type: String,
			default: 'start'
		},
		checked: {
			required: true,
			type: Boolean
		},
		disabled: {
			required: false,
			type: Boolean,
			default: false
		}
	},
	emits: ['change']
};
</script>
