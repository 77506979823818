<template>
	<div class="new-asset-modal-wrapper">
		<div class="new-asset-modal">
			<button
				class="new-asset-modal__close"
				aria-label="Close modal"
				@click="closeModal"
			/>
			<div class="new-asset-modal__content">
				<h2 class="new-asset-modal__heading">
					Add asset
				</h2>

				<div class="flex flex--justify-space-between flex--align-end flex--gap-large">
					<div class="form__set">
						<label
							for="new_tag"
							class="form__label"
						>
							Title
						</label>
						<input
							id="title"
							:value="title"
							class="form__input form__input--stretch"
							type="text"
							name="title"
							placeholder=""
							:disabled="loading"
							@input="validateTitleInput"
						>
					</div>

					<div class="new-asset-modal__buttons mb-16">
						<button
							class="button"
							:disabled="loading"
							@click="closeModal"
						>
							Cancel
						</button>
						<button
							class="button button--fill"
							:class="{ 'button--loading' : loading }"
							:disabled="loading"
							@click="createNewAsset"
						>
							Add
						</button>
					</div>
				</div>


				<p
					v-if="error_message"
					class="error-message"
				>
					{{ error_message }}
				</p>
			</div>
		</div>
	</div>
</template>

<script>
import { useDataStore } from '../../stores/data';

export default {
	props: {
		project: {
			required: true,
			type: Object
		},
		mediaTypeId: {
			required: true,
			type: Number
		},
	},
	emits: [ 'asset-added', 'close-modal' ],
	setup() {
		const data_store = useDataStore();
		const { createAsset } = data_store;
		return { createAsset };
	},
	data() {
		return {
			loading: false,
			title: '',
			error_message: null
		};
	},
	methods: {
		closeModal() {
			this.$emit( 'close-modal' );
		},
		validateTitleInput( e ) {
			const prev_value = this.title;
			this.title = null;

			const regex = /^[a-zA-Z0-9\ \-]*$/;
			const input = e.target.value;

			if ( regex.test( input ) ) {
				this.title = input;
			} else {
				this.title = prev_value;
			}
		},
		async createNewAsset() {
			this.error_message = null;
			this.loading = true;

			if ( !this.title ) {
				this.error_message = 'Please enter a title';
				this.loading = false;
				return;
			}

			try {
				const new_asset = await this.createAsset( {
					project: this.project,
					asset_title: this.title,
					media_type_id: this.mediaTypeId
				} );
				if ( new_asset ) {
					this.$emit( 'asset-added', {
						new_asset,
						media_type_id: this.mediaTypeId
					} );
				}
			} catch ( error ) {
				this.error_message = error;
				this.loading = false;
			}
		}
	}
};
</script>