<template>
	<router-link
		:to="{
			name: 'my-boundary__page',
			params: {
				page: page.slug
			}
		}"
		class="support-card"
		:class="`support-card--${colour}`"
	>
		<div
			class="support-card__icon"
			:class="'support-card__icon--' + page.icon.replace( /_/g, '-' )"
		/>
		<div class="support-card__content">
			<div
				class="support-card__summary"
				:class="{ 'support-card__summary--half-width': page.quick_links.length }"
			>
				<div>
					<h2 class="support-card__heading">
						{{ page.title }}
					</h2>
					<ul class="support-card__item-list">
						<div class="support-card__item-list-items">
							<li
								v-for="item, index in item_list"
								:key="index"
								class="support-card__item-list-item"
							>
								<router-link
									:to="{
										name: 'my-boundary__page',
										params: {
											page: page.slug
										},
										hash: `#${ slugifyTitle( item ) }`
									}"
									class="support-card__item-link"
								>
									<span>
										{{ item }}
									</span>
								</router-link>
							</li>
						</div>
					</ul>
				</div>
			</div>
			<div
				v-if="page.quick_links.length"
				class="support-card__quick-links"
			>
				<h3 class="support-card__quick-links-heading">Quick links</h3>
				<template
					v-for="quick_link in page.quick_links"
					:key="quick_link"
				>
					<a
						v-if="quick_link.logo_monochrome.length || quick_link.logo_colour.length"
						:href="quick_link.external_url"
						target="_blank"
						@click.stop
					>
						<img
							v-if="quick_link.logo_monochrome.length"
							:src="quick_link.logo_monochrome[0].url"
							class="support-card__quick-link"
						/>
						<img
							v-else-if="quick_link.logo_colour.length"
							:src="quick_link.logo_colour[0].url"
							class="support-card__quick-link"
						/>
					</a>
				</template>
			</div>
		</div>
	</router-link>
</template>

<script>
import slugify from 'slugify';

export default {
	props: {
		page: {
			required: true,
			type: Object
		},
		colour: {
			required: true,
			type: String
		}
	},
	computed: {
		item_list() {
			return this.page.content_blocks
				.filter( block => block.typeHandle === 'heading_and_text' )
				.map( block => block.heading )
			;
		},
	},
	methods: {
		slugifyTitle( title ) {
			return slugify( title ).toLowerCase();
		}
	}
}
</script>
