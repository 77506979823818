import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createVNode as _createVNode, Transition as _Transition, withCtx as _withCtx, Teleport as _Teleport, renderList as _renderList, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { class: "flex flex--wrap flex--justify-space-between" }
const _hoisted_3 = { class: "a7-12 f6-6" }
const _hoisted_4 = { class: "h3 mb-8" }
const _hoisted_5 = { class: "mb-32" }
const _hoisted_6 = { class: "a5-12 f6-6 mb-16" }
const _hoisted_7 = {
  key: 0,
  class: "flex flex--justify-end mb-16"
}
const _hoisted_8 = { class: "flex flex--column flex--align-end divider divider--bottom" }
const _hoisted_9 = { class: "p--tiny p--light" }
const _hoisted_10 = {
  key: 1,
  class: "flex flex--justify-end flex--align-start"
}
const _hoisted_11 = { class: "flex flex--align-center flex--gap-large" }
const _hoisted_12 = ["disabled"]
const _hoisted_13 = { key: 2 }
const _hoisted_14 = ["onClick"]
const _hoisted_15 = ["src", "alt"]
const _hoisted_16 = {
  key: 1,
  class: "submission-card__image submission-card__image--default"
}
const _hoisted_17 = { class: "submission-card__content" }
const _hoisted_18 = { class: "submission-card__info" }
const _hoisted_19 = { class: "submission-card__title" }
const _hoisted_20 = {
  key: 0,
  class: "submission-card__description"
}
const _hoisted_21 = { class: "submission-card__actions" }
const _hoisted_22 = ["onClick"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_PageLoaderComponent = _resolveComponent("PageLoaderComponent")
  const _component_SubmissionGridComponent = _resolveComponent("SubmissionGridComponent")
  const _component_CreateSubmissionNotificationModal = _resolveComponent("CreateSubmissionNotificationModal")

  return (_openBlock(), _createBlock(_Transition, { name: "fade" }, {
    default: _withCtx(() => [
      ($data.loading)
        ? (_openBlock(), _createBlock(_component_PageLoaderComponent, { key: 0 }))
        : ($data.active_submission)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("button", {
                class: "back-to mb-32",
                onClick: _cache[0] || (_cache[0] = (...args) => ($options.removeActiveSubmission && $options.removeActiveSubmission(...args)))
              }, " Back to submissions "),
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("h2", _hoisted_4, _toDisplayString($data.active_submission.title), 1 /* TEXT */),
                  _createElementVNode("p", _hoisted_5, _toDisplayString($data.active_submission.submission_note), 1 /* TEXT */)
                ]),
                _createElementVNode("div", _hoisted_6, [
                  ($data.user_is_staff_admin || $data.user_is_staff_super_admin)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        _createElementVNode("div", _hoisted_8, [
                          _createElementVNode("button", {
                            class: "button button--smaller mb-8",
                            onClick: _cache[1] || (_cache[1] = (...args) => ($options.openNotificationModal && $options.openNotificationModal(...args)))
                          }, " Notify client of submission "),
                          _createElementVNode("p", _hoisted_9, [
                            ($options.last_notification_date && $options.last_notification_user)
                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                  _createTextVNode(" Last notified by " + _toDisplayString($options.last_notification_user.fullName) + " on " + _toDisplayString($options.last_notification_date), 1 /* TEXT */)
                                ], 64 /* STABLE_FRAGMENT */))
                              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                  _createTextVNode(" This submission has not been shared yet ")
                                ], 64 /* STABLE_FRAGMENT */))
                          ])
                        ])
                      ]))
                    : _createCommentVNode("v-if", true),
                  ($data.active_submission.options.find( option => option.image_small_url ))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                        _createElementVNode("div", _hoisted_11, [
                          _createElementVNode("button", {
                            class: "a a--underline",
                            onClick: _cache[2] || (_cache[2] = (...args) => ($options.toggleAll && $options.toggleAll(...args)))
                          }, _toDisplayString($options.toggle_all_label), 1 /* TEXT */),
                          _createElementVNode("button", {
                            class: _normalizeClass(["button button--fill", { 'button--loading': $data.loading_download }]),
                            disabled: !$data.active_submission.options.find( option => option.checked ),
                            onClick: _cache[3] || (_cache[3] = (...args) => ($options.downloadSelectedOptions && $options.downloadSelectedOptions(...args)))
                          }, " Download selected ", 10 /* CLASS, PROPS */, _hoisted_12)
                        ])
                      ]))
                    : _createCommentVNode("v-if", true)
                ])
              ]),
              _createVNode(_component_SubmissionGridComponent, {
                submission: $data.active_submission,
                project: $props.project,
                selected_options: _ctx.selected_options,
                onToggleOption: $options.toggleOption
              }, null, 8 /* PROPS */, ["submission", "project", "selected_options", "onToggleOption"]),
              (_openBlock(), _createBlock(_Teleport, { to: "#modal" }, [
                _createVNode(_Transition, { name: "fade" }, {
                  default: _withCtx(() => [
                    ($data.active_submission && $data.show_notification_modal)
                      ? (_openBlock(), _createBlock(_component_CreateSubmissionNotificationModal, {
                          key: 0,
                          submission: $data.active_submission,
                          onCloseModal: $options.closeNotificationModal,
                          onNotificationCreated: $options.finishNotificationFlow
                        }, null, 8 /* PROPS */, ["submission", "onCloseModal", "onNotificationCreated"]))
                      : _createCommentVNode("v-if", true)
                  ]),
                  _: 1 /* STABLE */
                })
              ]))
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_13, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.submissions, (submission) => {
                return (_openBlock(), _createElementBlock("button", {
                  key: submission.id,
                  class: "submission-card",
                  onClick: $event => ($options.setActiveSubmission( submission.id ))
                }, [
                  ($options.getLatestSubmissionOption( submission ))
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 0,
                        src: $options.getLatestSubmissionOption( submission ).image_small_url || $options.getLatestSubmissionOption( submission ).thumbnail_url,
                        alt: $options.getLatestSubmissionOption( submission ).title,
                        class: "submission-card__image"
                      }, null, 8 /* PROPS */, _hoisted_15))
                    : (_openBlock(), _createElementBlock("div", _hoisted_16)),
                  _createElementVNode("div", _hoisted_17, [
                    _createElementVNode("div", _hoisted_18, [
                      _createElementVNode("h2", _hoisted_19, _toDisplayString(submission.title), 1 /* TEXT */),
                      (submission.submission_note)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_20, _toDisplayString(submission.submission_note), 1 /* TEXT */))
                        : _createCommentVNode("v-if", true)
                    ]),
                    _createElementVNode("div", _hoisted_21, [
                      (submission.options.find( option => option.image_small_url ))
                        ? (_openBlock(), _createElementBlock("button", {
                            key: 0,
                            class: "submission-card__action",
                            onClick: _withModifiers($event => ($options.downloadAllImagesFromSubmission( submission.id )), ["stop"])
                          }, " Download images ", 8 /* PROPS */, _hoisted_22))
                        : _createCommentVNode("v-if", true)
                    ])
                  ])
                ], 8 /* PROPS */, _hoisted_14))
              }), 128 /* KEYED_FRAGMENT */))
            ]))
    ]),
    _: 1 /* STABLE */
  }))
}