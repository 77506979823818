<template>
	<div class="approval-modal-wrapper">
		<div class="approval-modal">
			<button
				class="approval-modal__close"
				aria-label="Close modal"
				@click="$emit( 'close-modal' )"
			/>
			<div class="approval-modal__content">
				<template v-if="type_is_staff">
					<p class="approval-modal__heading">
						Are you sure you want to approve this upload?
					</p>
					<p class="approval-modal__text">
						You are about to approve this upload and make it visible to the client.
					</p>
				</template>

				<template v-if="type_is_client">
					<p class="approval-modal__heading">
						Are you sure you want to approve Stage {{ stage }}?
					</p>
					<p
						v-if="approval_is_final"
						class="approval-modal__text"
					>
						You are about to approve this stage as the final asset. The asset and all its comment threads will be locked and you will be able to download the high-res version(s) of it.
					</p>
					<template v-else>
						<p class="approval-modal__text approval-modal__text--extra-margin">
							You are about to approve the following asset:
						</p>

						<img
							v-if="option.thumbnail_url || option.image_small_url"
							class="approval-modal__thumbnail"
							:src="option.thumbnail_url || option.image_small_url"
							:alt="option.title"
						>
						<p class="approval-modal__thumbnail-title">
							{{ option.title }}
						</p>

						<p class="approval-modal__text">
							The Boundary will be notified and move to Stage B.
						</p>
					</template>

				</template>

				<div class="approval-modal__buttons">
					<button
						class="approval-modal__cancel"
						@click="$emit( 'close-modal' )"
					>
						Cancel
					</button>
					<button
						class="approval-modal__approve"
						@click="emitApproval"
					>
						Approve
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {
	USER_GROUP_CLIENT,
	USER_GROUP_STAFF
} from '../../constants';

export default {
	props: {
		type: {
			required: true,
			type: String
		},
		revision: {
			required: true,
			type: Object
		},
		option: {
			required: true,
			type: Object
		}
	},
	emits: [ 'close-modal', 'approve', 'approve-final-revision' ],
	computed: {
		type_is_client() {
			return this.type === USER_GROUP_CLIENT;
		},
		type_is_staff() {
			return this.type === USER_GROUP_STAFF;
		},
		approval_is_final() {
			return this.stage === 'B';
		},
		stage() {
			return this.revision.revision_number[0];
		}
	},
	methods: {
		emitApproval() {
			if ( this.type_is_client && this.approval_is_final ) {
				this.$emit( 'approve-final-revision' );
				return;
			}
			this.$emit( 'approve' );
		}
	}
};
</script>