<template>
	<img
		v-if="user.photo"
		:src="user.photo.url"
		alt="profile-picture"
		:class="[ 'profile-picture', size_class, rounded_class, border_class ]"
	>
	<div
		v-else
		:class="[ 'profile-picture', 'profile-picture--default', size_class, rounded_class, border_class ]"
	/>
</template>

<script>
export default {
	props: {
		user: { required: true, type: Object },
		size: { required: false, type: String, default: 'md'}, // options: xs, sm, md, auto
		rounded: { required: false, type: Boolean, default: false },
		bordered: { required: false, type: Boolean, default: false }
	},
	computed: {
		size_class() {
			return 'profile-picture--' + this.size;
		},
		rounded_class() {
			return this.rounded ? 'profile-picture--rounded' : '';
		},
		border_class() {
			return this.bordered ? 'profile-picture--border' : '';
		}
	}
};
</script>
