
<template>
	<div class="container">
		<Transition name="fade">
			<PageLoaderComponent v-if="loading" />
			<div v-else class="mb-64">
				<div class="mb-32">
					<h1 class="h1">
						My Boundary
					</h1>
				</div>
				<div class="grid grid--xs flex flex--wrap flex--align-stretch flex--row-gap-medium">
					<div class="grid__item a3-12 d3-6 f6-6">
						<SupportCardComponent
							title="Onboarding slideshows"
							icon="slides"
							colour="sand"
							:slideshows="onboarding_slideshows"
							:show-visibility-toggle="user_is_staff"
						/>
					</div>
					<div class="grid__item a3-12 d3-6 f6-6">
						<SupportCardComponent
							title="Documentation"
							icon="book"
							colour="forest-green"
							:links="documentation_pages"
							:show-visibility-toggle="user_is_staff"
						/>
					</div>
					<div class="grid__item a3-12 d3-6 f6-6">
						<SupportCardComponent
							title="ABC Work stages"
							icon="images"
							colour="light-green"
							:slideshows="abc_work_stages"
						/>
					</div>
					<div class="grid__item a3-12 d3-6 f6-6">
						<SupportCardComponent
							title="TBL Development"
							icon="sparkle"
							colour="clay-orange"
							:is-development-card="true"
						/>
					</div>
				</div>
			</div>
		</Transition>
	</div>
</template>

<script>
import PageLoaderComponent from '../components/PageLoaderComponent';
import SupportCardComponent from '../components/SupportCardComponent';

import { useDataStore } from '../stores/data';
import { storeToRefs } from 'pinia';
import { USER_GROUP_STAFF } from '../constants';

export default {
	components: {
		PageLoaderComponent,
		SupportCardComponent
	},
	setup() {
		const data_store = useDataStore();
		const {
			isUserStaffAdmin,
			isUserStaffSuperAdmin,
			getOnboardingSlideshows,
			getDocumentationPages,
			getABCWorkStages,
			setBreadcrumbs
		} = data_store;

		const { user_group } = storeToRefs( data_store );

		return {
			user_group,
			isUserStaffAdmin,
			isUserStaffSuperAdmin,
			getOnboardingSlideshows,
			getDocumentationPages,
			getABCWorkStages,
			setBreadcrumbs
		};
	},
	data() {
		return {
			onboarding_slideshows: [],
			documentation_pages: [],
			abc_work_stages: [],
			loading: false
		};
	},
	computed: {
		user_is_staff() {
			return this.user_group === USER_GROUP_STAFF;
		},
	},
	async mounted() {
		this.loading = true;

		this.setBreadcrumbs( [ { label: 'Support', icon: 'help' } ] );
		try {
			const response = await this.getOnboardingSlideshows();
			if ( response.data && response.data.entries ) {
				this.onboarding_slideshows = response.data.entries;
			}
		} catch( error ) {
			// console.log( error );
		}
		try {
			const response = await this.getDocumentationPages();
			if ( response.data && response.data.entries ) {
				this.documentation_pages = response.data.entries;
			}
		} catch( error ) {
			// console.log( error );
		}
		try {
			const response = await this.getABCWorkStages();
			if ( response.data && response.data.entries ) {
				this.abc_work_stages = response.data.entries;
			}
		} catch( error ) {
			// console.log( error );
		}

		this.loading = false;
	},
	methods: {
		getCardColour( index ) {
			return [ 'sand', 'forest-green', 'light-green', 'clay-orange' ][ index % 4 ];
		}
	}
};

</script>
