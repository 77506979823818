<template>
	<li class="notification-wrapper">
		<router-link
			class="notification"
			:class="{ 'notification--read': notification.read }"
			:to="notification.action_url"
			@click="markAsRead( notification )"
		>
			<div class="notification__main">
				<div
					v-if="['OptionCommentPosted', 'DiscussionCommentPosted'].includes(notification.type) && comment_notification_label"
					class="notification__label"
				>
					{{ comment_notification_label }}
				</div>
				<div class="notification__main-container">
					<ProfilePictureComponent
						:user="related_user"
					/>
					<div class="notification__content">
						<h2 class="notification__heading">
							{{ related_user.fullName }}
						</h2>
						<div
							v-if="['OptionCommentPosted', 'DiscussionCommentPosted'].includes(notification.type)"
							class="notification__body"
						>
							<div
								v-if="notification.entry && notification.entry.comment_content"
								class="notification__comment-content"
								v-html="notification.entry.comment_content"
							/>

							<div class="notification__comment-metadata">
								<div
									v-if="notification.entry && getImages( notification.entry.comment_attachments ).length"
									class="notification__image-attachments"
								>
									<div
										v-for="attachment, index in getImages( notification.entry.comment_attachments )"
										:key="`${notification.entry.id}-image-attachment-${index}`"
										class="notification__image-attachment-thumbnail"
									>
										<img
											class="notification__image-attachment"
											:src="attachment.attachment_thumbnail_url"
										>
									</div>
								</div>
								<p
									v-if="notification.entry && filesPending( notification.entry )"
									class="notification__files-pending"
								>
									{{ filesPending( notification.entry ) }} attachment(s) processing
								</p>

								<div
									v-if="notification.entry && getFiles( notification.entry.comment_attachments ).length"
									class="notification__links"
								>
									<p
										v-for="attachment, index in getFiles( notification.entry.comment_attachments )"
										:key="`${notification.entry.id}-image-attachment-${index}`"
										class="notification__pdf-attachment"
									>
										{{ attachment.attachment_filename }}
									</p>
								</div>
							</div>
						</div>

						<div
							v-else-if="notification.type === 'DiscussionCreated'"
							class="notification__body"
						>
							<div class="notification__comment-content">
								<p>
									{{ discussion_notification_label }}
								</p>
							</div>
						</div>

						<div
							v-else-if="notification.type === 'UploadAdded'"
							class="notification__body"
						>
							<div class="notification__comment-content">
								<p>
									{{ upload_notification_label }}
								</p>
							</div>

							<div
								v-if="notification.entries"
								class="notification__thumbnails"
							>
								<template
									v-for="option, index in notification.entries"
									:key="option.id"
								>
									<img
										v-if="index < 10 && ( option.image_small_url || option.thumbnail_url )"
										class="notification__thumbnail"
										:src="option.image_small_url || option.thumbnail_url"
									>
								</template>
							</div>
						</div>

						<div
							v-else-if="notification.type === 'SubmissionCreated'"
							class="notification__body"
						>
							<div class="notification__comment-content">
								<p>
									{{ submission_notification_label }}
								</p>
							</div>

							<div
								v-if="notification.entry && notification.entry.options"
								class="notification__thumbnails"
							>
								<template
									v-for="option, index in notification.entry.options"
									:key="option.id"
								>
									<img
										v-if="index < 10 && ( option.image_small_url || option.thumbnail_url )"
										class="notification__thumbnail"
										:src="option.image_small_url || option.thumbnail_url"
									>
								</template>
							</div>
						</div>

						<div
							v-else-if="notification.type === 'OptionClientApproved'"
							class="notification__body"
						>
							<div class="notification__comment-content">
								<p>
									{{ approval_notification_label }}
								</p>
							</div>

							<div
								v-if="notification.entry"
								class="notification__thumbnails"
							>
								<img
									v-if="notification.entry.image_small_url || notification.entry.thumbnail_url"
									class="notification__thumbnail"
									:src="notification.entry.image_small_url || notification.entry.thumbnail_url"
								>
							</div>
						</div>

						<div
							v-else-if="notification.type === 'RevisionClientApproved'"
							class="notification__body"
						>
							<div class="notification__comment-content">
								<p>
									{{ final_approval_notification_label }}
								</p>
							</div>

							<div
								v-if="notification.entry"
								class="notification__thumbnails"
							>
								<template
									v-for="option in notification.entry.revision_options"
									:key="option.id"
								>
									<img
										v-if="option.image_small_url || option.thumbnail_url"
										class="notification__thumbnail"
										:src="option.image_small_url || option.thumbnail_url"
									>
								</template>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="notification__aside">
				<p class="notification__timestamp">
					{{ timestamp }}
				</p>
			</div>
		</router-link>
	</li>
</template>

<script>
import ProfilePictureComponent from './ProfilePictureComponent.vue';

import { getTimePassed } from '../../helpers';
import { useDataStore } from '../stores/data';
import {
	USER_GROUP_STAFF
} from '../constants';

export default {
	components: {
		ProfilePictureComponent
	},
	props: {
		notification: { required: true, type: Object }
	},
	emits: [ 'mark-as-read' ],
	setup() {
		const { user_group } = useDataStore();
		return { user_group };
	},
	computed: {
		related_user() {
			switch ( this.notification.type ) {
				case 'OptionCommentPosted':
				case 'DiscussionCommentPosted':
				case 'DiscussionCreated':
					if ( !this.notification.entry || !this.notification.entry.author ) {
						return { fullName: 'Unknown user' };
					}
					return this.notification.entry.author;
				default:
					if ( !this.notification.user || !this.notification.user.fullName ) {
						return { fullName: 'Unknown user' };
					}
					return this.notification.user;
			}
		},
		user_is_staff() {
			return this.user_group === USER_GROUP_STAFF;
		},
		comment_notification_label() {
			if ( !this.notification.entry ) {
				return null;
			}
			switch ( this.notification.entry.typeHandle ) {
				case 'option_comment':
					return this.related_user.fullName + ' left a comment on ' + ( this.notification.asset ? this.notification.asset.title : 'an asset' ) + ' in ' + this.notification.project ? this.notification.project.title : 'a project';
				case 'discussion_comment':
					return this.related_user.fullName + ' left a comment on a discussion in ' + this.notification.project ? this.notification.project.title : 'a project';
				default:
					return null;
			}
		},
		discussion_notification_label() {
			return this.related_user.fullName + ' created a discussion in ' + ( this.notification.project ? this.notification.project.title : 'a project' );
		},
		upload_notification_label() {
			if ( !this.notification.entries || !this.notification.entries.length ) {
				return null;
			}
			let label = `${this.related_user.fullName} added ${this.notification.entries.length} ${this.notification.entries[0].typeHandle}${this.notification.entries.length > 1 ? 's' : ''} to ${this.notification.project ? this.notification.project.title : 'a project'}`;

			if ( this.user_is_staff ) {
				label += this.notification.entries.length > 1 ? '. These need to be approved by a staff member before they are visible to the client.' : '. It needs to be approved by a staff member before it is visible to the client.';
			}
			return label;
		},
		submission_notification_label() {
			if ( !this.notification.entry || !this.notification.entry.options.length ) {
				return null;
			}
			return `${this.related_user.fullName} created the ${this.notification.entry.title} submission in ${this.notification.project ? this.notification.project.title : 'a project'}`;
		},
		approval_notification_label() {
			if ( !this.notification.entry ) {
				return null;
			}
			return `${this.related_user.fullName} approved ${this.notification.entry.typeHandle === 'image' ? 'an image' : 'a ' + this.notification.entry.typeHandle} in ${this.notification.project ? this.notification.project.title : 'a project'}`;
		},
		final_approval_notification_label() {
			if ( !this.notification.entry ) {
				return null;
			}
			return `${this.related_user.fullName} approved ${this.notification.entry.title} in ${this.notification.project ? this.notification.project.title : 'a project'} as the final asset`;
		},
		timestamp() {
			return getTimePassed( this.notification.dateCreated );
		}
	},
	methods: {
		markAsRead( notification ) {
			if ( !notification.read ) {
				this.$emit( 'mark-as-read', notification.id );
			}
		},
		filesPending( comment ) {
			if ( !comment.number_of_files_attached ) {
				return 0;
			}
			const files_pending = comment.number_of_files_attached - comment.comment_attachments.length;
			return files_pending <= 0 ? 0 : files_pending;
		},
		getImages( attachments ) {
			if ( !attachments ) {
				return [];
			}
			return attachments.filter( attachment => attachment.attachment_type === 'image' && attachment.attachment_thumbnail_url );
		},
		getFiles( attachments ) {
			if ( !attachments ) {
				return [];
			}
			return attachments.filter( attachment => attachment.attachment_type !== 'image' || !attachment.attachment_thumbnail_url );
		}
	}
};
</script>
