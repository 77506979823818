<template>
	<Transition name="fade">
		<PageLoaderComponent v-if="loading" />
		<div
			v-else-if="active_submission"
		>
			<button
				class="back-to mb-32"
				@click="removeActiveSubmission"
			>
				Back to submissions
			</button>

			<div class="flex flex--wrap flex--justify-space-between">
				<div class="a7-12 f6-6">
					<h2 class="h3 mb-8">
						{{ active_submission.title }}
					</h2>
					<p class="mb-32">
						{{ active_submission.submission_note }}
					</p>
				</div>
				<div class="a5-12 f6-6 mb-16">
					<div
						v-if="user_is_staff_admin || user_is_staff_super_admin"
						class="flex flex--justify-end mb-16"
					>
						<div class="flex flex--column flex--align-end divider divider--bottom">
							<button
								class="button button--smaller mb-8"
								@click="openNotificationModal"
							>
								Notify client of submission
							</button>
							<p class="p--tiny p--light">
								<template v-if="last_notification_date && last_notification_user">
									Last notified by {{ last_notification_user.fullName }} on {{ last_notification_date }}
								</template>
								<template v-else>
									This submission has not been shared yet
								</template>
							</p>
						</div>
					</div>
					<div
						v-if="active_submission.options.find( option => option.image_small_url )"
						class="flex flex--justify-end flex--align-start"
					>
						<div class="flex flex--align-center flex--gap-large">
							<button
								class="a a--underline"
								@click="toggleAll"
							>
								{{ toggle_all_label }}
							</button>
							<button
								class="button button--fill"
								:class="{ 'button--loading': loading_download }"
								:disabled="!active_submission.options.find( option => option.checked )"
								@click="downloadSelectedOptions"
							>
								Download selected
							</button>
						</div>
					</div>
				</div>
			</div>

			<SubmissionGridComponent
				:submission="active_submission"
				:project="project"
				:selected_options="selected_options"
				@toggle-option="toggleOption"
			/>

			<Teleport to="#modal">
				<Transition name="fade">
					<CreateSubmissionNotificationModal
						v-if="active_submission && show_notification_modal"
						:submission="active_submission"
						@close-modal="closeNotificationModal"
						@notification-created="finishNotificationFlow"
					/>
				</Transition>
			</Teleport>
		</div>

		<div v-else>
			<button
				v-for="submission in submissions"
				:key="submission.id"
				class="submission-card"
				@click="setActiveSubmission( submission.id )"
			>
				<img
					v-if="getLatestSubmissionOption( submission )"
					:src="getLatestSubmissionOption( submission ).image_small_url || getLatestSubmissionOption( submission ).thumbnail_url"
					:alt="getLatestSubmissionOption( submission ).title"
					class="submission-card__image"
				>
				<div
					v-else
					class="submission-card__image submission-card__image--default"
				/>
				<div class="submission-card__content">
					<div class="submission-card__info">
						<h2 class="submission-card__title">
							{{ submission.title }}
						</h2>
						<p
							v-if="submission.submission_note"
							class="submission-card__description"
						>
							{{ submission.submission_note }}
						</p>
					</div>
					<div class="submission-card__actions">
						<button
							v-if="submission.options.find( option => option.image_small_url )"
							class="submission-card__action"
							@click.stop="downloadAllImagesFromSubmission( submission.id )"
						>
							Download images
						</button>
					</div>
				</div>
			</button>
		</div>
	</Transition>
</template>

<script>
import CreateSubmissionNotificationModal from './modals/CreateSubmissionNotificationModal.vue';
import PageLoaderComponent from './PageLoaderComponent.vue';
import SubmissionGridComponent from './SubmissionGridComponent.vue';

import { useDataStore } from '../stores/data';

import moment from 'moment';

export default {
	components: {
		CreateSubmissionNotificationModal,
		PageLoaderComponent,
		SubmissionGridComponent
	},
	props: {
		submissions: {
			required: true,
			type: Array
		},
		project: {
			required: true,
			type: Object
		}
	},
	setup() {
		const data_store = useDataStore();
		const {
			createArchive,
			setAlertMessage,
			isUserStaffAdmin,
			isUserStaffSuperAdmin
		} = data_store;
		return {
			createArchive,
			setAlertMessage,
			isUserStaffAdmin,
			isUserStaffSuperAdmin
		};
	},
	data() {
		return {
			loading: false,
			active_submission: null,
			loading_download: false,
			download_finished: false,
			user_is_staff_admin: false,
			user_is_staff_super_admin: false,
			show_notification_modal: false
		};
	},
	computed: {
		toggle_all_label() {
			if ( this.active_submission.options.find( option => !option.selected ) ) {
				return 'Select all';
			}
			return 'Deselect all';
		},
		last_notification_date() {
			if ( !this.active_submission && !this.active_submission.last_notification_timestamp ) {
				return null;
			}
			return moment( new Date( this.active_submission.last_notification_timestamp ) ).format( 'D/M/YY [at] HH:mm' );
		},
		last_notification_user() {
			if ( !this.active_submission && !this.active_submission.last_notification_user.length ) {
				return null;
			}
			return this.active_submission.last_notification_user[0];
		}
	},
	async mounted() {
		this.loading = true;
		this.user_is_staff_admin = await this.isUserStaffAdmin();
		this.user_is_staff_super_admin = await this.isUserStaffSuperAdmin();

		if ( this.$route.query.submission ) {
			this.setActiveSubmission( this.$route.query.submission );
		}
		this.loading = false;
	},
	methods: {
		async downloadSelectedOptions() {
			this.loading_download = true;
			this.setAlertMessage( null );

			const option_ids = this.active_submission.options
				.filter( option => option.checked )
				.map( ( option ) => parseInt( option.id ) );

			try {
				const response = await this.createArchive( {
					option_ids: option_ids,
					project_id: parseInt( this.$route.params.project_id ),
					submission_id: parseInt( this.active_submission.id )
				} );

				if ( response && response.data ) {
					this.setAlertMessage( `Your download is being prepared, we will email you a link when it's ready` ); // eslint-disable-line quotes
					this.setActiveSubmission( this.active_submission.id );
				}
			} catch ( error ) {
				// console.log( error );
			}
			this.loading_download = false;
		},
		async downloadAllImagesFromSubmission( submission_id ) {
			this.setAlertMessage( null );
			const submission = this.submissions.find( submission => submission.id === submission_id );

			if ( !submission ) {
				this.setAlertMessage( 'Submission not found' );
				return;
			}
			const option_ids = submission.options
				.filter( option => option.image_small_url )
				.map( option => parseInt( option.id ) )
			;

			try {
				const response = await this.createArchive( {
					option_ids: option_ids,
					project_id: parseInt( this.$route.params.project_id ),
					submission_id: parseInt( submission.id )
				} );

				if ( response && response.errors ) {
					this.setAlertMessage( 'Something went wrong, please try again' );
					return;
				}
				if ( response && response.data ) {
					this.setAlertMessage( "Your download is being prepared, we will email you a link when it's ready" ); // eslint-disable-line quotes
				}
			} catch ( error ) {
				// console.log( error );
			}
		},
		getLatestSubmissionOption( submission ) {
			if ( !submission.options || !submission.options.length ) {
				return null;
			}
			const sorted_options = submission.options
				.slice()
				.sort( ( a,b ) => a.title > b.title ? 1 : -1 );
			const latest_option = sorted_options.at( -1 );

			return latest_option || null;
		},
		async setActiveSubmission( submission_id ) {
			this.loading = true;

			const selected_submission = this.submissions.find( submission => submission.id === submission_id );
			if ( !selected_submission ) {
				return;
			}

			this.active_submission = {
				...selected_submission,
				options: selected_submission.options.map( option => {
					const related_asset = this.getAssetByOptionId( option.id );
					const related_revision = this.getRevisionByOptionId( option.id );

					return {
						...option,
						checked: false,
						related_asset,
						related_revision
					}
				} )
			};

			const query = { tab: 'submissions', submission: submission_id };
			if ( this.$route.query.option ) {
				query['option'] = this.$route.query.option;
			}
			if ( this.$route.query.show_comments ) {
				query['show_comments'] = this.$route.query.show_comments;
			}

			this.$router.push( {
				name: 'projects__project',
				params: { project_id: this.$route.params.project_id },
				query: query
			} );

			this.loading = false;
		},
		removeActiveSubmission() {
			this.active_submission = null;
			this.$router.push( {
				name: 'projects__project',
				params: { project_id: this.$route.params.project_id },
				query: { tab: 'submissions' }
			} );
		},
		getAssetByOptionId( option_id ) {
			return this.project.project_assets.find( asset => {
				return asset.asset_revisions.find( revision => {
					return revision.revision_options.find( option => option.id === option_id );
				} );
			} );
		},
		getRevisionByOptionId( option_id ) {
			const asset = this.getAssetByOptionId( option_id );
			if ( !asset ) {
				return null;
			}

			return asset.asset_revisions.find( revision => {
				return revision.revision_options.find( option => option.id === option_id );
			} );
		},
		toggleOption( option_id ) {
			this.active_submission.options = this.active_submission.options.map( option => {
				if ( option.id === option_id ) {
					return { ...option, selected: !option.selected };
				}
				return option;
			} );
		},
		toggleAll() {
			if ( this.active_submission.options.find( option => !option.selected ) ) {
				this.active_submission.options =  this.active_submission.options.map( option => {
					return { ...option, selected: true, checked: true };
				} );
				return;
			}
			this.active_submission.options =  this.active_submission.options.map( option => {
				return { ...option, selected: false, checked: false };
			} );
		},
		openNotificationModal() {
			this.show_notification_modal = true;
		},
		closeNotificationModal() {
			this.show_notification_modal = false;
		},
		finishNotificationFlow() {
			this.closeNotificationModal();
			this.setAlertMessage( 'A notification has been sent out to all clients on this project' );
		}
	}
};
</script>
