<template>
	<div class="container">
		<Transition name="fade">
			<PageLoaderComponent v-if="!page_load_finished" />
			<div v-else>
				<div class="mb-32">
					<h1 class="h1">
						Database
					</h1>
				</div>
				<div class="flex flex--justify-space-between flex--align-end mb-32">
					<TabNavigationComponent
						:tabs="tabs"
						:active-tab="active_tab"
						:disabled="loading_results"
						:no-margin="true"
						@set-tab="setTab"
					/>

					<div class="form__set form__set--no-margin">
						<label
							for="type"
							class="form__label"
						>
							Filter by type
						</label>
						<div
							v-if="media_types"
							class="form__icon form__icon--down"
						>
							<select
								v-model="media_type_id"
								class="form__select"
								:disabled="loading_results"
								@change="handleTypeInputChange"
							>
								<option
									:value="null"
								>
									All types
								</option>
								<option
									v-for="media_type, id in media_types"
									:key="`media_type-${ id }`"
									:value="id"
								>
									{{ media_type.title }}
								</option>
							</select>
						</div>
					</div>
				</div>

				<div>
					<div class="flex flex--justify-space-between flex--align-center flex--gap-extra-large mb-40">
						<div class="search-bar">
							<div
								class="search-bar__field a6-12"
								@click="focusSearch"
							>
								<input
									ref="database-search"
									v-model="search_query"
									type="text"
									placeholder="Search for an asset, project or tag"
									@input="handleSearchInputChange"
								>
							</div>

							<TransitionGroup
								v-if="active_tags.length || non_active_tags.length"
								name="grid"
								tag="ul"
								class="tags tags--align-right"
							>
								<li
									v-for="tag, index in active_tags"
									:key="'active-tag-' + tag.id"
									class="tags__tag tags__tag--selected"
								>
									{{ tag.title }}
									<button
										class="tags__remove-tag"
										@click="removeActiveTag( index )"
									/>
								</li>

								<li
									v-for="tag in non_active_tags"
									:key="'non-active-tag-' + tag.id"
									class="tags__tag"
								>
									<button @click="setActiveTag( tag )">
										{{ tag.title }}
									</button>
								</li>
							</TransitionGroup>

							<p
								v-else
								class="search-bar__count"
							>
								Showing {{ total_options_count }} asset{{ total_options_count > 1 ? 's' : '' }}
							</p>

						</div>
						<div class="flex flex--gap-large">
							<button
								class="sort-button"
								:class="[
									date_sort_order === 'DESC' ? 'sort-button--desc' : 'sort-button--asc',
									{ 'sort-button--selected': selected_sort_order === 'date' }
								]"
								@click="toggleDateSortOrder"
							>
								<span class="sort-button__label">
									Date
								</span>
							</button>
							<button
								class="sort-button"
								:class="[
									likes_sort_order === 'DESC' ? 'sort-button--desc' : 'sort-button--asc',
									{ 'sort-button--selected': selected_sort_order === 'likes' }
								]"
								@click="toggleLikesSortOrder"
							>
								<span class="sort-button__label">
									Popularity
								</span>
							</button>
						</div>
					</div>
					<div
						class="results-grid"
						:class="{ 'results-grid--loading': loading_results || loading_tags }"
					>
						<TransitionGroup
							name="grid"
							tag="ul"
							class="grid grid--xs flex flex--wrap relative mb-32"
						>
							<li
								v-for="option, index in database_options"
								:key="'database-option-' + option.id"
								class="grid__item a3-12 f6-6"
							>
								<router-link
									:to="{ name: 'database__option', params: { option_id: option.id } }"
									class="database-grid-card"
								>
									<img
										v-if="option.image_small_url || option.thumbnail_url"
										:src="option.image_small_url || option.thumbnail_url"
										:alt="option.title"
										class="database-grid-card__image"
									>
									<div
										v-else
										class="database-grid-card__image database-grid-card__image--default"
									/>
									<div class="database-grid-card__info">
										<h2 class="database-grid-card__title">
											{{ option.related_project[0].title }}
										</h2>

										<div class="database-grid-card__stats flex flex--align-center flex--gap-large">
											<button
												class="database-grid-card__like-button"
												:class="[
													liked_options.includes( parseInt( option.id ) ) ? 'database-grid-card__like-button--liked' : 'database-grid-card__like-button--unliked',
													{ 'database-grid-card__like-button--loading': loading_likes.includes( option.id ) }
												]"
												:disabled="loading_likes.includes( option.id )"
												@click.prevent="toggleOptionLike( option.id, index )"
											>
												{{ option.likes ?? 0 }}
											</button>
											<span class="flex flex--align-center flex--gap-small icon icon--calendar">{{ getDate( option.postDate ) }}</span>
										</div>
									</div>

									<button
										class="database-grid-card__fullscreen"
										aria-label="View image fullscreen"
										@click.prevent="openGallery( index )"
									/>
								</router-link>
							</li>
						</TransitionGroup>
					</div>

					<div
						v-show="total_options_count > database_options.length"
						ref="load_more_trigger"
						class="load-more-trigger"
					/>
				</div>
			</div>
		</Transition>
	</div>

	<Teleport to="#modal">
		<Transition name="fade">
			<GalleryModalComponent
				v-if="gallery_images && gallery_images.length && show_gallery_modal"
				:images="gallery_images"
				@close-modal="closeGalleryModal"
			/>
		</Transition>
	</Teleport>
</template>

<script>
import GalleryModalComponent from '../components/modals/GalleryModalComponent.vue';
import PageLoaderComponent from '../components/PageLoaderComponent.vue';
import TabNavigationComponent from '../components/TabNavigationComponent';

import { USER_GROUP_CLIENT } from '../constants';
import { filterOutDuplicates } from '../../helpers';

import moment from 'moment';
import { useDataStore } from '../stores/data';
import { useElementVisibility } from '@vueuse/core';
import { nextTick } from 'vue';
import { ref } from 'vue';
import { storeToRefs } from 'pinia';

export default {
	components: {
		GalleryModalComponent,
		PageLoaderComponent,
		TabNavigationComponent
	},
	setup() {
		const data_store = useDataStore();
		const {
			getDatabaseOptions,
			getDatabaseOptionById,
			getDatabaseTagsBySearch,
			getLikedOptions,
			likeOption,
			unlikeOption,
			setBreadcrumbs
		} = data_store;

		const {
			user_group,
			media_types
		} = storeToRefs( data_store );

		const load_more_trigger = ref( null );
		const trigger_is_visible = useElementVisibility( load_more_trigger );

		return {
			user_group,
			media_types,
			load_more_trigger,
			trigger_is_visible,
			getDatabaseOptions,
			getDatabaseOptionById,
			getDatabaseTagsBySearch,
			getLikedOptions,
			likeOption,
			unlikeOption,
			setBreadcrumbs
		};
	},
	data() {
		return {
			database_options: [],
			liked_options: [],
			search_query: '',
			limit: 12,
			page: 1,
			total_options_count: null,
			active_option: null,
			gallery_images: null,
			show_gallery_modal: false,
			page_load_finished: false,
			loading_results: false,
			loading_tags: false,
			loading_likes: [],
			tags: [],
			active_tags: [],
			selected_sort_order: 'date',
			date_sort_order: 'DESC',
			likes_sort_order: 'DESC',
			media_type_id: null,
			active_tab: 'all',
			tabs: [
				{
					slug: 'all',
					label: 'All'
				},
				{
					slug: 'published',
					label: 'Published'
				}
			]
		};
	},
	computed: {
		user_is_client() {
			return this.user_group === USER_GROUP_CLIENT;
		},
		non_active_tags() {
			return this.tags.filter( tag => !this.active_tags.find( active_tag => active_tag.id === tag.id ) );
		}
	},
	watch: {
		async trigger_is_visible( new_value, old_value ) {
			if ( new_value && !old_value && !this.loading_results ) {
				this.loading_results = true;
				await this.getMoreOptions();
				this.loading_results = false;
			}
		}
	},
	async mounted() {
		if ( this.user_is_client ) {
			this.$router.push( { name: 'home' } );
			return;
		}
		this.setBreadcrumbs( [ { label: 'Database', icon: 'layout-grid' } ] );
		if ( this.$route.query && this.$route.query.tab ) {
			this.active_tab = this.$route.query.tab;
		}

		await this.getOptions();
		await this.getLikes();
		this.page_load_finished = true;
	},
	methods: {
		async getOptions() {
			this.loading_results = true;
			try {
				const response = await this.getDatabaseOptions( {
					limit: this.limit,
					query: this.search_query,
					offset: ( this.page - 1 ) * this.limit,
					relatedTo: this.getRelatedToArray(),
					orderBy: this.getSortOrder(),
					published: this.active_tab === 'published' ? true : null
				} );
				if ( this.page === 1 ) {
					this.database_options = response.data.entries;
				} else {
					this.addOptionsToArray( response.data.entries );
				}
				this.total_options_count = response.data.total_results;
			} catch ( error ) {
				// console.log( error );
			}
			this.loading_results = false;
		},
		async getMoreOptions() {
			this.page++;
			await nextTick();
			this.getOptions();
		},
		async getLikes() {
			try {
				const response = await this.getLikedOptions();
				if ( response.data && response.data.likedOptions ) {
					this.liked_options = response.data.likedOptions
				}
			} catch ( error ) {
				// console.log( error );
			}
		},
		async fetchTags() {
			this.loading_tags = true;
			if ( !this.search_query ) {
				this.tags = [];
				this.loading_tags = false;
				return;
			}
			try {
				const response = await this.getDatabaseTagsBySearch( this.search_query );
				if ( response.data && response.data.tags ) {
					this.tags = response.data.tags;
				} else {
					this.tags = [];
				}
			} catch ( error ) {
				// console.log( error );
			}
			this.loading_tags = false;
		},
		setActiveTag( tag ) {
			this.active_tags.push( tag );
			this.tags = [];
			this.page = 1;
			this.search_query = '';
			this.getOptions();
		},
		removeActiveTag( index ) {
			this.active_tags.splice( index, 1 );
			this.page = 1;
			this.getOptions();
		},
		async handleSearchInputChange( e ) {
			const input = e.target.value;
			setTimeout( async() => {
				if ( this.search_query === input ) {
					this.page = 1;
					await nextTick();
					this.getOptions();
					this.fetchTags();
				}
			}, 1000 );
		},
		async handleTypeInputChange() {
			this.page = 1;
			await nextTick();
			await this.getOptions();
		},
		addOptionsToArray( options ) {
			this.database_options.push( ...options );
			this.database_options = filterOutDuplicates( this.database_options );
		},
		focusSearch() {
			this.$refs[ 'database-search' ].focus();
		},
		toggleDateSortOrder() {
			this.page = 1;
			if ( this.selected_sort_order === 'likes' ) {
				this.likes_sort_order = 'DESC';
				this.selected_sort_order = 'date';
				this.getOptions();
				return;
			}
			this.date_sort_order = this.date_sort_order === 'DESC' ? 'ASC' : 'DESC';
			this.getOptions();
		},
		toggleLikesSortOrder() {
			this.page = 1;
			if ( this.selected_sort_order === 'date' ) {
				this.date_sort_order = 'DESC';
				this.selected_sort_order = 'likes';
				this.getOptions();
				return;
			}
			this.likes_sort_order = this.likes_sort_order === 'DESC' ? 'ASC' : 'DESC';
			this.getOptions();
		},
		getSortOrder() {
			if ( this.selected_sort_order === 'date' ) {
				return `postDate ${this.date_sort_order}`;
			}
			return `likes ${this.likes_sort_order}, postDate ${this.date_sort_order}`;
		},
		getRelatedToArray() {
			const related_to_array = [];
			if ( this.active_tags.length ) {
				related_to_array.push( ...this.active_tags.map( tag => tag.id ) );
			}
			if ( this.media_type_id ) {
				related_to_array.push( this.media_type_id );
			}

			return related_to_array.length ? ['and', ...related_to_array] : null;
		},
		getDate( timestamp ) {
			return moment( new Date( timestamp ) ).format( 'D/M/YY' );
		},
		async toggleOptionLike( option_id, index ) {
			this.loading_likes.push( option_id );
			if ( this.liked_options.includes( parseInt( option_id ) ) ) {
				await this.unlikeOption( parseInt( option_id ) );
			} else {
				await this.likeOption( parseInt( option_id ) );
			}
			await this.getLikes();
			await this.updateOption( option_id, index );
			this.loading_likes.splice( this.loading_likes.indexOf( option_id ), 1 );
		},
		async updateOption( option_id, index ) {
			try {
				const response = await this.getDatabaseOptionById( option_id );
				if ( response.data.entry ) {
					this.database_options[index] = response.data.entry;
				}
			} catch( error ) {
				// console.log( error );
			}
		},
		async setTab( slug ) {
			this.active_tab = slug;
			this.page = 1;
			this.getOptions();
			this.$router.push( {
				name: 'database',
				query: { tab: slug }
			} );
		},
		openGallery( index ) {
			this.gallery_images = [ ...this.database_options ]
				.map( ( option, i ) => {
					return {
						title: `${option.related_project[0].title} - ${option.title}`,
						image_url: option.image_large_url,
						selected: index === i
					};
				} )
			;
			this.show_gallery_modal = true;
		},
		closeGalleryModal() {
			this.show_gallery_modal = false;
			this.gallery_images = null;
		},
	}
};
</script>
