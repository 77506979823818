
<template>
	<div class="container">
		<div>
			<div class="mb-32">
				<h1 class="h1">
					Request a new feature
				</h1>
			</div>

			<router-link
				:to="{
					name: 'support'
				}"
				class="back-to mb-48"
			>
				Back to support
			</router-link>

			<div class="a8-12 f6-6 mb-64">
				<PageLoaderComponent v-if="loading"/>
				<iframe
					v-resize
					class="clickup-embed clickup-dynamic-height"
					:class="{ 'clickup-embed--hidden': loading }"
					src="https://forms.clickup.com/6629531/f/6aa4v-21952/1TRWZ1H2XN4WFKLB58"
					onwheel=""
					width="100%"
					style="background: transparent;"
					@load="loading = false"
				></iframe>
			</div>
		</div>
	</div>
</template>

<script>
import PageLoaderComponent from '../components/PageLoaderComponent';

export default {
	components: {
		PageLoaderComponent
	},
	data() {
		return {
			loading: true
		}
	}
};
</script>
