import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["CheckboxesDropdownComponent"], {
    id: "sales-marketing",
    label: "Sales & Marketing",
    placeholder: "Search",
    query: $setup.sales_marketing.search_query,
    options: $setup.filtered_sales_marketing,
    selection: $setup.active_sales_marketing_ids,
    stretch: true,
    loading: $props.loading,
    onSelect: $setup.handleCompanySelect,
    onSearch: $setup.handleCompanySearchInputChange
  }, null, 8 /* PROPS */, ["query", "options", "selection", "loading"]))
}