import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, TransitionGroup as _TransitionGroup, withCtx as _withCtx } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_NotificationComponent = _resolveComponent("NotificationComponent")

  return (_openBlock(), _createBlock(_TransitionGroup, {
    name: "list",
    tag: "ul"
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.notifications, (notification) => {
        return (_openBlock(), _createBlock(_component_NotificationComponent, {
          key: notification.id,
          notification: notification,
          onMarkAsRead: _cache[0] || (_cache[0] = id => _ctx.$emit( 'mark-as-read', id ))
        }, null, 8 /* PROPS */, ["notification"]))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _: 1 /* STABLE */
  }))
}