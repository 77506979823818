<template>
	<div
		v-if="slideshow && slideshow_url"
		class="slideshow-modal-wrapper"
	>
		<div
			ref="modal"
			class="slideshow-modal"
			:style="size_styles"
		>
			<div
				ref="modal-header"
				class="slideshow-modal__header"
			>
				<p
					v-if="slideshow.title"
					class="slideshow-modal__title"
				>
					{{ slideshow.title }}
				</p>

				<button
					class="slideshow-modal__close-modal"
					@click="$emit( 'close-modal' )"
				/>
			</div>

			<div class="slideshow-modal__content">
				<div class="slideshow-modal__content-wrapper">
					<iframe
						ref="slides"
						class="slideshow-modal__slideshow"
						:src="slideshow_url"
						frameborder="0"
						allowfullscreen="true"
						mozallowfullscreen="true"
						webkitallowfullscreen="true"
					></iframe>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		slideshow: {
			required: true,
			type: Object
		},
	},
	emits: [ 'close-modal' ],
	data() {
		return {
			size_styles: {
				height: '95vh',
				width: '95vw'
			}
		}
	},
	computed: {
		slideshow_url() {
			if ( !this.slideshow.slideshow_url ) {
				return null;
			}
			return this.slideshow.slideshow_url.replace( '/pub?', '/embed?' );
		}
	},
	mounted() {
		this.resizeModal();
		window.addEventListener( 'resize', this.resizeModal )
	},
	unmounted() {
		window.removeEventListener( 'resize', this.resizeModal );
	},
	methods: {
		resizeModal() {
			const window_height = window.innerHeight;
			const window_width = window.innerWidth;
			if ( window_height * 1.6 > window_width ) {
				this.size_styles.width = '95vw';
				this.size_styles.height = this.calculateModalHeight( window_width );
				return;
			}
			this.size_styles.height = '95vh';
			this.size_styles.width = this.calculateModalWidth();
		},
		calculateModalHeight() {
			const modal_header_height = this.$refs['modal-header'].offsetHeight;
			const navbar_height = 36 // Assuming window zoom is 100%; can't query this value as it's inside the iframe document
			return `calc( ${( 95 / 16 ) * 9}vw + ${ modal_header_height + navbar_height }px )`;
		},
		calculateModalWidth() {
			const modal_header_height = this.$refs['modal-header'].offsetHeight;
			const navbar_height = 36 // Assuming window zoom is 100%; can't query this value as it's inside the iframe document
			return `calc( ( 95vh - ${ modal_header_height + navbar_height }px ) * ( 16 / 9 ) )`;
		}
	}
};
</script>
