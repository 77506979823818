import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, TransitionGroup as _TransitionGroup } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "discussion-card__header" }
const _hoisted_3 = { class: "discussion-card__title-wrapper" }
const _hoisted_4 = { class: "discussion-card__title" }
const _hoisted_5 = { class: "discussion-card__comment-details" }
const _hoisted_6 = { class: "discussion-card__type-badge discussion-card__type-badge--discussion" }
const _hoisted_7 = { class: "discussion-card__comment-count" }
const _hoisted_8 = {
  key: 0,
  class: "discussion-card__attachment-count"
}
const _hoisted_9 = { class: "discussion-card__date" }
const _hoisted_10 = {
  key: 0,
  class: "discussion-card__last-comment"
}
const _hoisted_11 = { class: "discussion-card__last-comment-image" }
const _hoisted_12 = { class: "discussion-card__last-comment-content" }
const _hoisted_13 = { class: "discussion-card__last-comment-heading" }
const _hoisted_14 = ["innerHTML"]
const _hoisted_15 = { class: "discussion-card__header" }
const _hoisted_16 = { class: "discussion-card__title-wrapper" }
const _hoisted_17 = { class: "discussion-card__title" }
const _hoisted_18 = { class: "discussion-card__comment-details" }
const _hoisted_19 = { class: "discussion-card__type-badge discussion-card__type-badge--asset" }
const _hoisted_20 = { class: "discussion-card__comment-count" }
const _hoisted_21 = {
  key: 0,
  class: "discussion-card__attachment-count"
}
const _hoisted_22 = { class: "discussion-card__date" }
const _hoisted_23 = { class: "discussion-card__thumbnail" }
const _hoisted_24 = ["src", "alt"]
const _hoisted_25 = {
  key: 1,
  class: "discussion-card__thumbnail-image discussion-card__thumbnail-image--default"
}
const _hoisted_26 = {
  key: 0,
  class: "discussion-card__last-comment"
}
const _hoisted_27 = { class: "discussion-card__last-comment-image" }
const _hoisted_28 = { class: "discussion-card__last-comment-content" }
const _hoisted_29 = { class: "discussion-card__last-comment-heading" }
const _hoisted_30 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ProfilePictureComponent = _resolveComponent("ProfilePictureComponent")
  const _component_router_link = _resolveComponent("router-link")

  return (_openBlock(), _createBlock(_TransitionGroup, {
    name: "list",
    tag: "ul",
    class: "list-container discussions"
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.discussions, (discussion) => {
        return (_openBlock(), _createElementBlock("li", {
          key: discussion.id,
          class: "discussion-card",
          onClick: $event => (_ctx.$emit( 'set-active-discussion', discussion.id ))
        }, [
          (discussion.type === 'discussion')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("h2", _hoisted_4, _toDisplayString(discussion.title), 1 /* TEXT */),
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("span", _hoisted_6, _toDisplayString(discussion.type), 1 /* TEXT */),
                      _createElementVNode("p", _hoisted_7, _toDisplayString($options.getCommentCount( parseInt( discussion.total_comment_count ) )), 1 /* TEXT */),
                      (parseInt( discussion.total_attachment_count ) > 0)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString($options.getAttachmentCount( parseInt( discussion.total_attachment_count ) )), 1 /* TEXT */))
                        : _createCommentVNode("v-if", true)
                    ])
                  ]),
                  _createElementVNode("p", _hoisted_9, _toDisplayString($options.getDate( discussion.latest_comment_date || discussion.postDate )), 1 /* TEXT */)
                ]),
                (discussion.latest_comment_author)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      _createElementVNode("div", _hoisted_11, [
                        _createVNode(_component_ProfilePictureComponent, {
                          user: discussion.latest_comment_author,
                          size: "xs"
                        }, null, 8 /* PROPS */, ["user"])
                      ]),
                      _createElementVNode("div", _hoisted_12, [
                        _createElementVNode("h3", _hoisted_13, " Last comment by " + _toDisplayString(discussion.latest_comment_author.fullName), 1 /* TEXT */),
                        _createElementVNode("div", {
                          innerHTML: discussion.latest_comment_content,
                          class: "discussion-card__last-comment-body"
                        }, null, 8 /* PROPS */, _hoisted_14)
                      ])
                    ]))
                  : _createCommentVNode("v-if", true)
              ], 64 /* STABLE_FRAGMENT */))
            : (discussion.type === 'asset')
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 1,
                  to: discussion.action_url
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_15, [
                      _createElementVNode("div", _hoisted_16, [
                        _createElementVNode("h2", _hoisted_17, _toDisplayString(discussion.title), 1 /* TEXT */),
                        _createElementVNode("div", _hoisted_18, [
                          _createElementVNode("span", _hoisted_19, _toDisplayString(discussion.type), 1 /* TEXT */),
                          _createElementVNode("p", _hoisted_20, _toDisplayString($options.getCommentCount( discussion.total_comment_count )), 1 /* TEXT */),
                          (parseInt( discussion.total_attachment_count ) > 0)
                            ? (_openBlock(), _createElementBlock("p", _hoisted_21, _toDisplayString($options.getAttachmentCount( discussion.total_attachment_count )), 1 /* TEXT */))
                            : _createCommentVNode("v-if", true)
                        ])
                      ]),
                      _createElementVNode("p", _hoisted_22, _toDisplayString($options.getDate( discussion.latest_comment_date || discussion.postDate )), 1 /* TEXT */)
                    ]),
                    _createElementVNode("div", _hoisted_23, [
                      (discussion.thumbnail_image_url)
                        ? (_openBlock(), _createElementBlock("img", {
                            key: 0,
                            src: discussion.thumbnail_image_url,
                            alt: discussion.title,
                            class: "discussion-card__thumbnail-image"
                          }, null, 8 /* PROPS */, _hoisted_24))
                        : (_openBlock(), _createElementBlock("div", _hoisted_25))
                    ]),
                    (discussion.latest_comment_author)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                          _createElementVNode("div", _hoisted_27, [
                            _createVNode(_component_ProfilePictureComponent, {
                              user: discussion.latest_comment_author,
                              size: "xs"
                            }, null, 8 /* PROPS */, ["user"])
                          ]),
                          _createElementVNode("div", _hoisted_28, [
                            _createElementVNode("h3", _hoisted_29, " Last comment by " + _toDisplayString(discussion.latest_comment_author.fullName), 1 /* TEXT */),
                            _createElementVNode("div", {
                              innerHTML: discussion.latest_comment_content,
                              class: "discussion-card__last-comment-body"
                            }, null, 8 /* PROPS */, _hoisted_30)
                          ])
                        ]))
                      : _createCommentVNode("v-if", true)
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"]))
              : _createCommentVNode("v-if", true)
        ], 8 /* PROPS */, _hoisted_1))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _: 1 /* STABLE */
  }))
}