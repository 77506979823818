import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["CheckboxesDropdownComponent"], {
    id: "account-director",
    label: "Account Director",
    placeholder: "Search",
    query: $setup.account_director.search_query,
    options: $setup.filtered_account_directors,
    selection: $setup.active_account_director_ids,
    stretch: true,
    loading: $props.loading,
    onSearch: $setup.handleAccountDirectorSearchInputChange,
    onSelect: $setup.handleAccountDirectorSelect
  }, null, 8 /* PROPS */, ["query", "options", "selection", "loading"]))
}