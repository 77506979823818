<template>
	<div class="breadcrumbs">
		<div
			v-for="crumb in breadcrumbs"
			:key="crumb.label"
			class="breadcrumbs__item"
		>
			<p
				class="breadcrumbs__label"
				:class="{ 'breadcrumbs__label--link': crumb.route }"
			>
				<span
					v-if="crumb.icon"
					:class="`icon icon--${ crumb.icon }`"
				/>
				<router-link
					v-if="crumb.route"
					:to="crumb.route"
					class="breadcrumbs__link"
				>
					{{ crumb.label }}
				</router-link>
				<template v-else>
					{{ crumb.label }}
				</template>
			</p>
		</div>
	</div>
</template>

<script>
import { useDataStore } from '../stores/data';
import { storeToRefs } from 'pinia';

export default {
	setup() {
		const data_store = useDataStore();
		const { breadcrumbs } = storeToRefs( data_store );
		return { breadcrumbs };
	},
};
</script>
