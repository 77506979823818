import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($props.user.photo)
    ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        src: $props.user.photo.url,
        alt: "profile-picture",
        class: _normalizeClass([ 'profile-picture', $options.size_class, $options.rounded_class, $options.border_class ])
      }, null, 10 /* CLASS, PROPS */, _hoisted_1))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: _normalizeClass([ 'profile-picture', 'profile-picture--default', $options.size_class, $options.rounded_class, $options.border_class ])
      }, null, 2 /* CLASS */))
}