<template>
	<div
		v-if="gallery_options && gallery_options.length"
		class="gallery-modal-wrapper"
	>
		<div class="gallery-modal">
			<div class="gallery-modal__header">
				<p class="gallery-modal__count">
					{{ getCount() }}
				</p>
				<div class="gallery-modal__nav-buttons">
					<button
						class="gallery-modal__nav-prev"
						:class="{ 'gallery-modal__nav-prev--disabled': first_selected }"
						:disabled="first_selected"
						@click="viewPrev"
					/>
					<button
						class="gallery-modal__nav-next"
						:class="{ 'gallery-modal__nav-next--disabled': last_selected }"
						:disabled="last_selected"
						@click="viewNext"
					/>
				</div>
				<p
					v-if="selected_option.title"
					class="gallery-modal__title"
				>
					{{ selected_option.title }}
				</p>

				<div class="gallery-modal__buttons">
					<a
						v-if="config.downloadable && selected_option.image_url"
						class="gallery-modal__button"
						:href="selected_option_image_download_url"
					>
						Download
					</a>
				</div>

				<button
					class="gallery-modal__close-modal"
					@click="$emit( 'close-modal' )"
				/>
			</div>

			<div
				class="gallery-modal__content"
				:class="{ 'gallery-modal__content--show-sidebar': show_comments }"
			>
				<div
					class="gallery-modal__content-wrapper"
					:class="{ 'gallery-modal__content-wrapper--full-resolution': show_full_resolution }"
				>
					<img
						v-if="selected_option.image_url"
						class="gallery-modal__image"
						:class="{ 'gallery-modal__image--full-resolution': show_full_resolution }"
						:style="full_resolution_styles"
						:src="selected_option.image_url"
						@click="toggleFullResolution"
					>
					<video
						v-else-if="selected_option.video_url"
						class="gallery-modal__video"
						:src="selected_option.video_url"
						:alt="selected_option.title"
						autoplay
						controls
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		options: {
			required: true,
			type: Array
		},
		config: {
			required: false,
			type: Object,
			default: () => {
				return {
					downloadable: false
				};
			}
		}
	},
	emits: [ 'close-modal', 'view-prev', 'view-next' ],
	data() {
		return {
			gallery_options: null,
			loading: false,
			show_full_resolution: false,
			full_resolution_coordinates: {
				x: null,
				y: null
			}
		};
	},
	computed: {
		selected_option() {
			return this.options.find( option => option.selected );
		},
		selected_option_image_download_url() {
			const escaped_url = encodeURIComponent(
				this.selected_option.image_url
			);

			return `/download-option?url=${escaped_url}`;
		},
		first_selected() {
			return this.options[0] && this.options[0].selected;
		},
		last_selected() {
			return this.options.at( -1 ) && this.options.at( -1 ).selected;
		},
		full_resolution_styles() {
			if ( !this.show_full_resolution ) {
				return null;
			}
			return {
				objectPosition: `${this.full_resolution_coordinates.x} ${this.full_resolution_coordinates.y}`
			};
		}
	},
	watch: {
		options: {
			handler() {
				this.gallery_options = this.options;
			}
		}, deep: true
	},
	mounted() {
		window.addEventListener( 'keydown', this.handleKeyPress );
		this.gallery_options = this.options;
	},
	unmounted() {
		window.removeEventListener( 'keydown', this.handleKeyPress );
	},
	methods: {
		toggleFullResolution( e ) {
			this.show_full_resolution = !this.show_full_resolution;
			if ( !this.show_full_resolution ) {
				this.full_resolution_coordinates.x = null;
				this.full_resolution_coordinates.y = null;
			}
			this.full_resolution_coordinates.x = `${e.offsetX / e.target.offsetWidth * 100}%`;
			this.full_resolution_coordinates.y = `${e.offsetY / e.target.offsetHeight * 100}%`;
		},
		handleKeyPress( e ) {
			switch ( e.key ) {
				case 'ArrowRight':
					return this.viewNext();
				case 'ArrowLeft':
					return this.viewPrev();
				case 'Escape':
					return this.$emit( 'close-modal' );
				default:
					return;
			}
		},
		viewPrev() {
			this.show_full_resolution = false;
			const current_selection = this.gallery_options
				.map( option => option.selected )
				.indexOf( true );
			this.gallery_options[ current_selection ].selected = false;
			if ( current_selection === 0 ) {
				this.gallery_options[ this.gallery_options.length - 1 ].selected = true;

				return;
			}

			this.gallery_options[ current_selection - 1 ].selected = true;
		},
		viewNext() {
			this.show_full_resolution = false;
			const current_selection = this.gallery_options
				.map( option => option.selected )
				.indexOf( true );
			this.gallery_options[ current_selection ].selected = false;
			if ( current_selection === this.gallery_options.length - 1 ) {
				this.gallery_options[0].selected = true;

				return;
			}

			this.gallery_options[ current_selection + 1 ].selected = true;
		},
		getCount() {
			const selected_states = this.gallery_options.map( option => option.selected );
			const current_selection = selected_states.indexOf( true ) + 1;
			return `${ current_selection }/${ this.options.length }`;
		}
	}
};
</script>