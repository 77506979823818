import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["CheckboxesDropdownComponent"], {
    id: "location",
    label: "Location",
    placeholder: "Search",
    query: $setup.project_location.search_query,
    options: $setup.filtered_project_locations,
    selection: $setup.active_project_location_ids,
    stretch: true,
    onSearch: $setup.handleProjectLocationSearchInputChange,
    onSelect: $setup.handleLocationSelect
  }, null, 8 /* PROPS */, ["query", "options", "selection"]))
}