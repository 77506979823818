<template>
	<CheckboxesDropdownComponent
		id="project-type"
		label="Project Type"
		placeholder="Search"
		:options="project_type.types"
		:selection="active_types"
		:stretch="true"
		:search-disabled="true"
		:loading="loading_types"
		@select="handleTypeSelect"
	/>
</template>

<script setup>
import CheckboxesDropdownComponent from '../CheckboxesDropdownComponent.vue';

import { ref, computed, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useProjectFiltersStore } from '../../stores/project-filters';

const filters_store = useProjectFiltersStore();

const {
	getProjectTypes,
	setActiveFilterValue,
	removeActiveFilterValue,
	resetProjectPagination,
	updateQueryStringFromProjectFilters,
} = filters_store;

const { project_type } = storeToRefs( filters_store );

const loading_types = ref( true );

const active_types = computed( () => {
	return project_type.value.active.map( type => type.value );
} );

const handleTypeSelect = ( type ) => {
	type.checked ? setActiveFilterValue( type, 'project_type' ) : removeActiveFilterValue( type, 'project_type' );
	resetProjectPagination();
	updateQueryStringFromProjectFilters();
};

// Mount
onMounted( async () => {
	await getProjectTypes();

	loading_types.value = false;
} );
</script>