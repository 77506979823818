import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "mb-48" }
const _hoisted_4 = { class: "h1" }
const _hoisted_5 = { class: "a8-12 f6-6 flex flex--wrap flex--gap-medium mb-24" }
const _hoisted_6 = ["href"]
const _hoisted_7 = { class: "grid flex flex--wrap-reverse" }
const _hoisted_8 = { class: "grid__item a8-12 f6-6 mb-64" }
const _hoisted_9 = ["id"]
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = { class: "grid__item a4-12 f6-6" }
const _hoisted_12 = { class: "quick-links-container" }
const _hoisted_13 = { class: "mb-16" }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = ["href"]
const _hoisted_16 = ["src"]
const _hoisted_17 = ["src"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_PageLoaderComponent = _resolveComponent("PageLoaderComponent")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        ($data.loading)
          ? (_openBlock(), _createBlock(_component_PageLoaderComponent, { key: 0 }))
          : (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("h1", _hoisted_4, _toDisplayString($data.page.title), 1 /* TEXT */)
              ]),
              _createElementVNode("ul", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.page.content_blocks, (block) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: `nav-link-${block.id}`
                  }, [
                    _createElementVNode("a", {
                      href: `#${ $options.slugifyTitle( block.heading ) }`,
                      class: "p--small a a--underline"
                    }, _toDisplayString(block.heading), 9 /* TEXT, PROPS */, _hoisted_6)
                  ]))
                }), 128 /* KEYED_FRAGMENT */))
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.page.content_blocks, (block) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: `block-${block.id}`,
                      class: _normalizeClass(["my-boundary-content-block", $options.getContentBlockClass( block.typeHandle )])
                    }, [
                      (block.typeHandle === 'heading_and_text')
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            _createElementVNode("h2", {
                              id: $options.slugifyTitle( block.heading ),
                              class: "my-boundary-content-block__heading"
                            }, _toDisplayString(block.heading), 9 /* TEXT, PROPS */, _hoisted_9),
                            _createCommentVNode(" eslint-disable vue/no-v-html "),
                            _createElementVNode("div", {
                              class: "my-boundary-content-block__text typeset",
                              innerHTML: block.text
                            }, null, 8 /* PROPS */, _hoisted_10)
                          ], 64 /* STABLE_FRAGMENT */))
                        : _createCommentVNode("v-if", true)
                    ], 2 /* CLASS */))
                  }), 128 /* KEYED_FRAGMENT */))
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("div", _hoisted_13, [
                      ($data.page.quick_links.length)
                        ? (_openBlock(), _createElementBlock("h2", _hoisted_14, " Quick links "))
                        : _createCommentVNode("v-if", true)
                    ]),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.page.quick_links, (quick_link) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: quick_link.external_url,
                        class: "mb-32"
                      }, [
                        (quick_link.logo_colour.length || quick_link.logo_monochrome.length)
                          ? (_openBlock(), _createElementBlock("a", {
                              key: 0,
                              href: quick_link.external_url,
                              target: "_blank"
                            }, [
                              (quick_link.logo_colour.length)
                                ? (_openBlock(), _createElementBlock("img", {
                                    key: 0,
                                    src: quick_link.logo_colour[0].url,
                                    class: "quick-link"
                                  }, null, 8 /* PROPS */, _hoisted_16))
                                : (quick_link.logo_monochrome.length)
                                  ? (_openBlock(), _createElementBlock("img", {
                                      key: 1,
                                      src: quick_link.logo_monochrome[0].url,
                                      class: "quick-link"
                                    }, null, 8 /* PROPS */, _hoisted_17))
                                  : _createCommentVNode("v-if", true)
                            ], 8 /* PROPS */, _hoisted_15))
                          : _createCommentVNode("v-if", true)
                      ]))
                    }), 128 /* KEYED_FRAGMENT */))
                  ])
                ])
              ])
            ]))
      ]),
      _: 1 /* STABLE */
    })
  ]))
}