import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["CheckboxesDropdownComponent"], {
    id: "hospitality-operator",
    label: "Hospitality Operator",
    placeholder: "Search",
    query: $setup.hospitality_operator.search_query,
    options: $setup.filtered_hospitality_operators,
    selection: $setup.active_hospitality_operator_ids,
    stretch: true,
    loading: $props.loading,
    onSelect: $setup.handleOperatorSelect,
    onSearch: $setup.handleOperatorSearchInputChange
  }, null, 8 /* PROPS */, ["query", "options", "selection", "loading"]))
}