<template>
	<div class="create-submission-notification-modal-wrapper">
		<div class="create-submission-notification-modal">
			<button
				class="create-submission-notification-modal__close"
				aria-label="Close modal"
				@click="closeModal"
			/>
			<div class="create-submission-notification-modal__content">
				<h2 class="create-submission-notification-modal__heading">
					Notify client of submission
				</h2>

				<p class="create-submission-notification-modal__text">
					Are you sure? This will send out a Boundary Live notification and email to every client user added to this project.
				</p>


				<p
					v-if="error_message"
					class="error-message"
				>
					{{ error_message }}
				</p>
			</div>

			<div class="create-submission-notification-modal__buttons">
				<button
					class="button"
					@click="$emit( 'close-modal' )"
				>
					Cancel
				</button>
				<button
					class="button button--fill"
					:class="{ 'button--loading': loading }"
					@click="confirm"
				>
					Confirm
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import { useDataStore } from '../../stores/data';

export default {
	props: {
		submission: {
			required: true,
			type: Object
		},
	},
	emits: [ 'notification-created', 'close-modal' ],
	setup() {
		const data_store = useDataStore();
		const { createSubmissionNotification } = data_store;
		return { createSubmissionNotification };
	},
	data() {
		return {
			loading: false,
			error_message: null
		};
	},
	methods: {
		closeModal() {
			this.$emit( 'close-modal' );
		},
		async confirm() {
			this.error_message = null;
			this.loading = true;

			try {
				const response = await this.createSubmissionNotification( parseInt( this.submission.id ) );
				if ( response && response.data ) {
					this.$emit( 'notification-created' );
				}
			} catch ( error ) {
				this.error_message = error;
				this.loading = false;
			}
		}
	}
};
</script>