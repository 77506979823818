<template>
	<CheckboxesDropdownComponent
		id="interio-design"
		label="Interior Design"
		placeholder="Search"
		:query="interior_design.search_query"
		:options="filtered_interior_designers"
		:selection="active_interior_design_ids"
		:stretch="true"
		:loading="loading"
		@select="handleDesignerSelect"
		@search="handleDesignerSearchInputChange"
	/>
</template>

<script setup>
import CheckboxesDropdownComponent from '../CheckboxesDropdownComponent.vue';

import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useProjectFiltersStore } from '../../stores/project-filters';

const props = defineProps( {
	loading: {
		required: false,
		type: Boolean,
		default: false
	}
} );

const filters_store = useProjectFiltersStore();

const {
	setFilterSearchQuery,
	setActiveFilterValue,
	removeActiveFilterValue,
	resetProjectPagination,
	updateQueryStringFromProjectFilters,
} = filters_store;

const { interior_design, filtered_interior_designers } = storeToRefs( filters_store );

const active_interior_design_ids = computed( () => {
	return interior_design.value.active.map( company => company.value );
} );

const handleDesignerSelect = ( company ) => {
	company.checked ? setActiveFilterValue( company, 'interior_design' ) : removeActiveFilterValue( company, 'interior_design' );
	resetProjectPagination();
	updateQueryStringFromProjectFilters();
};

const handleDesignerSearchInputChange = async ( value ) => {
	setFilterSearchQuery( value, 'interior_design' );
};
</script>