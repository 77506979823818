<template>
	<div
		v-if="gallery_options && gallery_options.length"
		class="gallery-modal-wrapper"
	>
		<div class="gallery-modal">
			<div class="gallery-modal__header">
				<p class="gallery-modal__count">
					{{ getCount() }}
				</p>
				<div class="gallery-modal__nav-buttons">
					<button
						class="gallery-modal__nav-prev"
						:class="{ 'gallery-modal__nav-prev--disabled': first_selected }"
						:disabled="first_selected"
						@click="viewPrev"
					/>
					<button
						class="gallery-modal__nav-next"
						:class="{ 'gallery-modal__nav-next--disabled': last_selected }"
						:disabled="last_selected"
						@click="viewNext"
					/>
				</div>
				<p
					v-if="selected_option.title"
					class="gallery-modal__title"
				>
					{{ selected_option.title }}
				</p>

				<div class="gallery-modal__buttons">
					<router-link
						v-if="selected_option.router_link"
						:to="selected_option.router_link"
						class="gallery-modal__button"
					>
						View in project
					</router-link>

					<button
						v-if="selected_option.option_id"
						class="gallery-modal__button gallery-modal__button--invert"
						@click="showComments"
					>
						<span class="icon icon--message-circle" />
						Leave a comment
					</button>
				</div>

				<button
					class="gallery-modal__close-modal"
					@click="$emit( 'close-modal' )"
				/>
			</div>

			<div
				class="gallery-modal__content"
				:class="{ 'gallery-modal__content--show-sidebar': show_comments }"
			>
				<div
					class="gallery-modal__content-wrapper"
					:class="{ 'gallery-modal__content-wrapper--full-resolution': show_full_resolution }"
				>
					<img
						v-if="selected_option.image_url"
						class="gallery-modal__image"
						:class="{ 'gallery-modal__image--full-resolution': show_full_resolution }"
						:style="full_resolution_styles"
						:src="selected_option.image_url"
						@click="toggleFullResolution"
					>
					<video
						v-else-if="selected_option.video_url"
						class="gallery-modal__video"
						:src="selected_option.video_url"
						:alt="selected_option.title"
						autoplay
						controls
					/>
				</div>

				<div
					v-if="show_comments"
					class="gallery-modal__sidebar"
				>
					<div
						class="gallery-modal__sidebar-content"
						:class="{ 'gallery-modal__sidebar-content--loading': loading }"
					>
						<div class="gallery-modal__sidebar-heading">
							<h2 class="p mb-16">
								Comments
							</h2>
							<h3 class="p">
								{{ selected_option.title }}
							</h3>
						</div>
						<CommentFormComponent
							:option-id="selected_option.option_id"
							@comment-posted="getComments"
						/>
						<div class="gallery-modal__comments-heading">
							<h4 class="gallery-modal__sidebar-sub-title">
								Comments
							</h4>

							<button
								v-if="comments && comments.length"
								class="gallery-modal__comments-sort"
								:class="{ 'gallery-modal__comments-sort--asc': date_sort === 'asc' }"
								@click="toggleDateSort"
							>
								Date
							</button>
						</div>
						<CommentsComponent
							:comments="comments"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import CommentFormComponent from '../CommentFormComponent.vue';
import CommentsComponent from '../CommentsComponent.vue';

import { useDataStore } from '../../stores/data';

export default {
	components: {
		CommentFormComponent,
		CommentsComponent
	},
	props: {
		options: {
			required: true,
			type: Array
		}
	},
	emits: [ 'close-modal', 'view-prev', 'view-next' ],
	setup() {
		const { getCommentsByOptionId } = useDataStore();
		return { getCommentsByOptionId };
	},
	data() {
		return {
			gallery_options: null,
			show_comments: false,
			loading: false,
			comments: [],
			date_sort: 'desc',
			show_full_resolution: false,
			full_resolution_coordinates: {
				x: null,
				y: null
			}
		};
	},
	computed: {
		selected_option() {
			return this.options.find( option => option.selected );
		},
		first_selected() {
			return this.options[0] && this.options[0].selected;
		},
		last_selected() {
			return this.options.at( -1 ) && this.options.at( -1 ).selected;
		},
		full_resolution_styles() {
			if ( !this.show_full_resolution ) {
				return null;
			}
			return {
				objectPosition: `${this.full_resolution_coordinates.x} ${this.full_resolution_coordinates.y}`
			};
		}
	},
	watch: {
		selected_option() {
			this.getComments();
		},
		options: {
			handler() {
				this.gallery_options = this.options;
			}
		}, deep: true
	},
	mounted() {
		window.addEventListener( 'keydown', this.handleKeyPress );
		this.gallery_options = this.options;
	},
	unmounted() {
		window.removeEventListener( 'keydown', this.handleKeyPress );
	},
	methods: {
		handleKeyPress( e ) {
			switch ( e.key ) {
				case 'ArrowRight':
					return this.viewNext();
				case 'ArrowLeft':
					return this.viewPrev();
				case 'Escape':
					return this.$emit( 'close-modal' );
				default:
					return;
			}
		},
		toggleFullResolution( e ) {
			this.show_full_resolution = !this.show_full_resolution;
			if ( !this.show_full_resolution ) {
				this.full_resolution_coordinates.x = null;
				this.full_resolution_coordinates.y = null;
			}
			this.full_resolution_coordinates.x = `${e.offsetX / e.target.offsetWidth * 100}%`;
			this.full_resolution_coordinates.y = `${e.offsetY / e.target.offsetHeight * 100}%`;
		},
		viewPrev() {
			const current_selection = this.gallery_options
				.map( option => option.selected )
				.indexOf( true );
			this.gallery_options[ current_selection ].selected = false;
			if ( current_selection === 0 ) {
				this.gallery_options[ this.gallery_options.length - 1 ].selected = true;

				return;
			}

			this.gallery_options[ current_selection - 1 ].selected = true;
		},
		viewNext() {
			const current_selection = this.gallery_options
				.map( option => option.selected )
				.indexOf( true );
			this.gallery_options[ current_selection ].selected = false;
			if ( current_selection === this.gallery_options.length - 1 ) {
				this.gallery_options[0].selected = true;

				return;
			}

			this.gallery_options[ current_selection + 1 ].selected = true;
		},
		getCount() {
			const selected_states = this.gallery_options.map( option => option.selected );
			const current_selection = selected_states.indexOf( true ) + 1;
			return `${ current_selection }/${ this.options.length }`;
		},
		async showComments() {
			await this.getComments();
			this.show_comments = true;
		},
		async getComments() {
			if ( !this.selected_option.option_id ) {
				return;
			}
			this.loading = true;
			try {
				const response = await this.getCommentsByOptionId(
					parseInt( this.selected_option.option_id )
				);
				this.comments = response.data.entries;
			} catch ( error ) {
				// console.error( error );
			}
			this.loading = false;
		},
		toggleDateSort() {
			this.date_sort = this.date_sort === 'desc' ? 'asc' : 'desc';
			this.comments.reverse();
		}
	}
};
</script>