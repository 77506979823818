<template>
	<CheckboxesDropdownComponent
		id="project-status"
		label="Project Status"
		placeholder="Search"
		:options="project_status.statuses"
		:selection="active_statuses"
		:stretch="true"
		:search-disabled="true"
		@select="handleStatusSelect"
	/>
</template>

<script setup>
import CheckboxesDropdownComponent from '../CheckboxesDropdownComponent.vue';

import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useProjectFiltersStore } from '../../stores/project-filters';

const filters_store = useProjectFiltersStore();

const {
	setActiveFilterValue,
	removeActiveFilterValue,
	resetProjectPagination,
	updateQueryStringFromProjectFilters,
} = filters_store;

const { project_status } = storeToRefs( filters_store );

const active_statuses = computed( () => {
	return project_status.value.active.map( status => status.value );
} );

const handleStatusSelect = ( status ) => {
	status.checked ? setActiveFilterValue( status, 'project_status' ) : removeActiveFilterValue( status, 'project_status' );
	resetProjectPagination();
	updateQueryStringFromProjectFilters();
};
</script>