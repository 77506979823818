
// import { breakpoints } from '../variables.yml';

import 'focus-visible';
// import './vendor/lib.js';
// import { whatever } from './vendor/lib.js';
// import { getWindowWidth } from './helpers.js';

// export * from './CookieConsentManager.js';
export * from './vue/app.js';

( () => {
	// Example IIFE code block
	// code goes here...
} )();
