<template>
	<CheckboxesDropdownComponent
		id="business-development"
		label="Business Development"
		placeholder="Search"
		:query="business_development.search_query"
		:options="filtered_business_developers"
		:selection="active_business_development_ids"
		:stretch="true"
		:loading="loading"
		@select="handleBusinessDeveloperSelect"
		@search="handleBusinessDevelopmentSearchInputChange"
	/>
</template>

<script setup>
import CheckboxesDropdownComponent from '../CheckboxesDropdownComponent.vue';

import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useProjectFiltersStore } from '../../stores/project-filters';

const props = defineProps( {
	loading: {
		required: false,
		type: Boolean,
		default: false
	}
} );

const filters_store = useProjectFiltersStore();

const {
	setFilterSearchQuery,
	setActiveFilterValue,
	removeActiveFilterValue,
	resetProjectPagination,
	updateQueryStringFromProjectFilters,
} = filters_store;

const { business_development, filtered_business_developers } = storeToRefs( filters_store );

const active_business_development_ids = computed( () => {
	return business_development.value.active.map( user => user.value );
} );

const handleBusinessDeveloperSelect = ( user ) => {
	user.checked ? setActiveFilterValue( user, 'business_development' ) : removeActiveFilterValue( user, 'business_development' );
	resetProjectPagination();
	updateQueryStringFromProjectFilters();
};

const handleBusinessDevelopmentSearchInputChange = async ( value ) => {
	setFilterSearchQuery( value, 'business_development' );
};
</script>