
<template>
	<div class="container">
		<Transition name="fade">
			<div>
				<div class="flex flex--justify-space-between mb-32">
					<div class="flex flex--gap-large flex--align-center">
						<h1 class="h1">
							Settings
						</h1>
					</div>
				</div>

				<TabNavigationComponent
					:tabs="tabs"
					:active-tab="active_tab"
					@set-tab="setRoute"
				/>

				<Transition name="fade">
					<PageLoaderComponent v-if="loading_tab" />

					<ProfileFormComponent v-else-if="active_tab === 'profile'" />

					<NotificationSettingsComponent v-else-if="active_tab === 'notifications'" />

					<PasswordFormComponent v-else-if="active_tab === 'password'" />
				</Transition>
			</div>
		</Transition>
	</div>
</template>

<script>
import PageLoaderComponent from '../components/PageLoaderComponent.vue';
import TabNavigationComponent from '../components/TabNavigationComponent.vue';
import ProfileFormComponent from '../components/ProfileFormComponent.vue';
import NotificationSettingsComponent from '../components/NotificationSettingsComponent.vue';
import PasswordFormComponent from '../components/PasswordFormComponent.vue';

import { useDataStore } from '../stores/data';
import { storeToRefs } from 'pinia';

import { USER_GROUP_CLIENT } from '../constants';

export default {
	components: {
		PageLoaderComponent,
		TabNavigationComponent,
		ProfileFormComponent,
		NotificationSettingsComponent,
		PasswordFormComponent
	},
	setup() {
		const data_store = useDataStore();
		const {
			getUserProfile,
			setBreadcrumbs,
			user_group
		} = data_store;
		const { user_profile } = storeToRefs( data_store );

		return {
			user_profile,
			getUserProfile,
			setBreadcrumbs,
			user_group
		};
	},
	data() {
		return {
			active_tab: 'profile',
			loading_tab: true
		};
	},
	computed: {
		tabs() {
			return [
				{
					slug: 'profile',
					label: 'Profile'
				},
				{
					slug: 'notifications',
					label: 'Notifications'
				},
				{
					slug: 'password',
					label: 'Password'
				}
			];
		},
		user_is_client() {
			return this.user_group === USER_GROUP_CLIENT;
		}
	},
	watch: {
		$route( route ) {
			if ( route.query.tab ) {
				this.setTab( route.query.tab );
			}
		}
	},
	async mounted() {
		this.setBreadcrumbs( [ { label: 'Settings', icon: 'settings' } ] );

		if ( this.$route.query && this.$route.query.tab ) {
			this.loading_tab = true;
			this.active_tab = this.$route.query.tab;
		}
		this.loading_tab = false;
	},
	methods: {
		setRoute( slug ) {
			this.$router.push( {
				name: 'settings',
				query: { tab: slug }
			} );
		},
		async setTab( slug ) {
			this.loading_tab = true;
			this.active_tab = slug;
			this.loading_tab = false;
		}
	}
};

</script>
