<template>
	<CheckboxesDropdownComponent
		id="account-director"
		label="Account Director"
		placeholder="Search"
		:query="account_director.search_query"
		:options="filtered_account_directors"
		:selection="active_account_director_ids"
		:stretch="true"
		:loading="loading"
		@search="handleAccountDirectorSearchInputChange"
		@select="handleAccountDirectorSelect"
	/>
</template>

<script setup>
import CheckboxesDropdownComponent from '../CheckboxesDropdownComponent.vue';

import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useProjectFiltersStore } from '../../stores/project-filters';

const props = defineProps( {
	loading: {
		required: false,
		type: Boolean,
		default: false
	}
} );

const filters_store = useProjectFiltersStore();

const {
	setFilterSearchQuery,
	setActiveFilterValue,
	removeActiveFilterValue,
	resetProjectPagination,
	updateQueryStringFromProjectFilters
} = filters_store;

const { account_director, filtered_account_directors } = storeToRefs( filters_store );

const active_account_director_ids = computed( () => {
	return account_director.value.active.map( user => user.value );
} );

const handleAccountDirectorSelect = ( user ) => {
	user.checked ? setActiveFilterValue( user, 'account_director' ) : removeActiveFilterValue( user, 'account_director' );
	resetProjectPagination();
	updateQueryStringFromProjectFilters();
};

const handleAccountDirectorSearchInputChange = async ( value ) => {
	setFilterSearchQuery( value, 'account_director' );
};
</script>