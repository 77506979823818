<template>
	<CheckboxesDropdownComponent
		id="brand-partner"
		label="Brand Partner"
		placeholder="Search"
		:query="brand_partner.search_query"
		:options="filtered_brand_partners"
		:selection="active_brand_partner_ids"
		:stretch="true"
		:loading="loading"
		@select="handleBrandPartnerSelect"
		@search="handleBrandPartnerSearchInputChange"
	/>
</template>

<script setup>
import CheckboxesDropdownComponent from '../CheckboxesDropdownComponent.vue';

import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useProjectFiltersStore } from '../../stores/project-filters';

const props = defineProps( {
	loading: {
		required: false,
		type: Boolean,
		default: false
	}
} );

const filters_store = useProjectFiltersStore();

const {
	setFilterSearchQuery,
	setActiveFilterValue,
	removeActiveFilterValue,
	resetProjectPagination,
	updateQueryStringFromProjectFilters,
} = filters_store;

const { brand_partner, filtered_brand_partners } = storeToRefs( filters_store );

const active_brand_partner_ids = computed( () => {
	return brand_partner.value.active.map( company => company.value );
} );

const handleBrandPartnerSelect = ( company ) => {
	company.checked ? setActiveFilterValue( company, 'brand_partner' ) : removeActiveFilterValue( company, 'brand_partner' );
	resetProjectPagination();
	updateQueryStringFromProjectFilters();
};

const handleBrandPartnerSearchInputChange = async ( value ) => {
	setFilterSearchQuery( value, 'brand_partner' );
};
</script>