import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "notification-wrapper" }
const _hoisted_2 = { class: "notification__main" }
const _hoisted_3 = {
  key: 0,
  class: "notification__label"
}
const _hoisted_4 = { class: "notification__main-container" }
const _hoisted_5 = { class: "notification__content" }
const _hoisted_6 = { class: "notification__heading" }
const _hoisted_7 = {
  key: 0,
  class: "notification__body"
}
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { class: "notification__comment-metadata" }
const _hoisted_10 = {
  key: 0,
  class: "notification__image-attachments"
}
const _hoisted_11 = ["src"]
const _hoisted_12 = {
  key: 1,
  class: "notification__files-pending"
}
const _hoisted_13 = {
  key: 2,
  class: "notification__links"
}
const _hoisted_14 = {
  key: 1,
  class: "notification__body"
}
const _hoisted_15 = { class: "notification__comment-content" }
const _hoisted_16 = {
  key: 2,
  class: "notification__body"
}
const _hoisted_17 = { class: "notification__comment-content" }
const _hoisted_18 = {
  key: 0,
  class: "notification__thumbnails"
}
const _hoisted_19 = ["src"]
const _hoisted_20 = {
  key: 3,
  class: "notification__body"
}
const _hoisted_21 = { class: "notification__comment-content" }
const _hoisted_22 = {
  key: 0,
  class: "notification__thumbnails"
}
const _hoisted_23 = ["src"]
const _hoisted_24 = {
  key: 4,
  class: "notification__body"
}
const _hoisted_25 = { class: "notification__comment-content" }
const _hoisted_26 = {
  key: 0,
  class: "notification__thumbnails"
}
const _hoisted_27 = ["src"]
const _hoisted_28 = {
  key: 5,
  class: "notification__body"
}
const _hoisted_29 = { class: "notification__comment-content" }
const _hoisted_30 = {
  key: 0,
  class: "notification__thumbnails"
}
const _hoisted_31 = ["src"]
const _hoisted_32 = { class: "notification__aside" }
const _hoisted_33 = { class: "notification__timestamp" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ProfilePictureComponent = _resolveComponent("ProfilePictureComponent")
  const _component_router_link = _resolveComponent("router-link")

  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createVNode(_component_router_link, {
      class: _normalizeClass(["notification", { 'notification--read': $props.notification.read }]),
      to: $props.notification.action_url,
      onClick: _cache[0] || (_cache[0] = $event => ($options.markAsRead( $props.notification )))
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          (['OptionCommentPosted', 'DiscussionCommentPosted'].includes($props.notification.type) && $options.comment_notification_label)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString($options.comment_notification_label), 1 /* TEXT */))
            : _createCommentVNode("v-if", true),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_ProfilePictureComponent, { user: $options.related_user }, null, 8 /* PROPS */, ["user"]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("h2", _hoisted_6, _toDisplayString($options.related_user.fullName), 1 /* TEXT */),
              (['OptionCommentPosted', 'DiscussionCommentPosted'].includes($props.notification.type))
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    ($props.notification.entry && $props.notification.entry.comment_content)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: "notification__comment-content",
                          innerHTML: $props.notification.entry.comment_content
                        }, null, 8 /* PROPS */, _hoisted_8))
                      : _createCommentVNode("v-if", true),
                    _createElementVNode("div", _hoisted_9, [
                      ($props.notification.entry && $options.getImages( $props.notification.entry.comment_attachments ).length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.getImages( $props.notification.entry.comment_attachments ), (attachment, index) => {
                              return (_openBlock(), _createElementBlock("div", {
                                key: `${$props.notification.entry.id}-image-attachment-${index}`,
                                class: "notification__image-attachment-thumbnail"
                              }, [
                                _createElementVNode("img", {
                                  class: "notification__image-attachment",
                                  src: attachment.attachment_thumbnail_url
                                }, null, 8 /* PROPS */, _hoisted_11)
                              ]))
                            }), 128 /* KEYED_FRAGMENT */))
                          ]))
                        : _createCommentVNode("v-if", true),
                      ($props.notification.entry && $options.filesPending( $props.notification.entry ))
                        ? (_openBlock(), _createElementBlock("p", _hoisted_12, _toDisplayString($options.filesPending( $props.notification.entry )) + " attachment(s) processing ", 1 /* TEXT */))
                        : _createCommentVNode("v-if", true),
                      ($props.notification.entry && $options.getFiles( $props.notification.entry.comment_attachments ).length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.getFiles( $props.notification.entry.comment_attachments ), (attachment, index) => {
                              return (_openBlock(), _createElementBlock("p", {
                                key: `${$props.notification.entry.id}-image-attachment-${index}`,
                                class: "notification__pdf-attachment"
                              }, _toDisplayString(attachment.attachment_filename), 1 /* TEXT */))
                            }), 128 /* KEYED_FRAGMENT */))
                          ]))
                        : _createCommentVNode("v-if", true)
                    ])
                  ]))
                : ($props.notification.type === 'DiscussionCreated')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                      _createElementVNode("div", _hoisted_15, [
                        _createElementVNode("p", null, _toDisplayString($options.discussion_notification_label), 1 /* TEXT */)
                      ])
                    ]))
                  : ($props.notification.type === 'UploadAdded')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                        _createElementVNode("div", _hoisted_17, [
                          _createElementVNode("p", null, _toDisplayString($options.upload_notification_label), 1 /* TEXT */)
                        ]),
                        ($props.notification.entries)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.notification.entries, (option, index) => {
                                return (_openBlock(), _createElementBlock(_Fragment, {
                                  key: option.id
                                }, [
                                  (index < 10 && ( option.image_small_url || option.thumbnail_url ))
                                    ? (_openBlock(), _createElementBlock("img", {
                                        key: 0,
                                        class: "notification__thumbnail",
                                        src: option.image_small_url || option.thumbnail_url
                                      }, null, 8 /* PROPS */, _hoisted_19))
                                    : _createCommentVNode("v-if", true)
                                ], 64 /* STABLE_FRAGMENT */))
                              }), 128 /* KEYED_FRAGMENT */))
                            ]))
                          : _createCommentVNode("v-if", true)
                      ]))
                    : ($props.notification.type === 'SubmissionCreated')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                          _createElementVNode("div", _hoisted_21, [
                            _createElementVNode("p", null, _toDisplayString($options.submission_notification_label), 1 /* TEXT */)
                          ]),
                          ($props.notification.entry && $props.notification.entry.options)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.notification.entry.options, (option, index) => {
                                  return (_openBlock(), _createElementBlock(_Fragment, {
                                    key: option.id
                                  }, [
                                    (index < 10 && ( option.image_small_url || option.thumbnail_url ))
                                      ? (_openBlock(), _createElementBlock("img", {
                                          key: 0,
                                          class: "notification__thumbnail",
                                          src: option.image_small_url || option.thumbnail_url
                                        }, null, 8 /* PROPS */, _hoisted_23))
                                      : _createCommentVNode("v-if", true)
                                  ], 64 /* STABLE_FRAGMENT */))
                                }), 128 /* KEYED_FRAGMENT */))
                              ]))
                            : _createCommentVNode("v-if", true)
                        ]))
                      : ($props.notification.type === 'OptionClientApproved')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                            _createElementVNode("div", _hoisted_25, [
                              _createElementVNode("p", null, _toDisplayString($options.approval_notification_label), 1 /* TEXT */)
                            ]),
                            ($props.notification.entry)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                                  ($props.notification.entry.image_small_url || $props.notification.entry.thumbnail_url)
                                    ? (_openBlock(), _createElementBlock("img", {
                                        key: 0,
                                        class: "notification__thumbnail",
                                        src: $props.notification.entry.image_small_url || $props.notification.entry.thumbnail_url
                                      }, null, 8 /* PROPS */, _hoisted_27))
                                    : _createCommentVNode("v-if", true)
                                ]))
                              : _createCommentVNode("v-if", true)
                          ]))
                        : ($props.notification.type === 'RevisionClientApproved')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                              _createElementVNode("div", _hoisted_29, [
                                _createElementVNode("p", null, _toDisplayString($options.final_approval_notification_label), 1 /* TEXT */)
                              ]),
                              ($props.notification.entry)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.notification.entry.revision_options, (option) => {
                                      return (_openBlock(), _createElementBlock(_Fragment, {
                                        key: option.id
                                      }, [
                                        (option.image_small_url || option.thumbnail_url)
                                          ? (_openBlock(), _createElementBlock("img", {
                                              key: 0,
                                              class: "notification__thumbnail",
                                              src: option.image_small_url || option.thumbnail_url
                                            }, null, 8 /* PROPS */, _hoisted_31))
                                          : _createCommentVNode("v-if", true)
                                      ], 64 /* STABLE_FRAGMENT */))
                                    }), 128 /* KEYED_FRAGMENT */))
                                  ]))
                                : _createCommentVNode("v-if", true)
                            ]))
                          : _createCommentVNode("v-if", true)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_32, [
          _createElementVNode("p", _hoisted_33, _toDisplayString($options.timestamp), 1 /* TEXT */)
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["class", "to"])
  ]))
}