import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, TransitionGroup as _TransitionGroup } from "vue"

const _hoisted_1 = ["src", "alt"]
const _hoisted_2 = {
  key: 1,
  class: "project-card__image project-card__image--default"
}
const _hoisted_3 = { class: "project-card__title" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "project-card__alerts" }
const _hoisted_6 = { class: "project-card__notifications-count" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_link = _resolveComponent("router-link")

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["results-grid", { 'results-grid--loading': $props.loadingProjects }])
  }, [
    _createVNode(_TransitionGroup, {
      name: "grid",
      tag: "ul",
      class: "grid grid--xs flex flex--wrap relative mb-32"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.projects, (project) => {
          return (_openBlock(), _createElementBlock("li", {
            key: 'project-' + project.id,
            class: "grid__item a3-15 c3-12 e3-6 f6-6 mb-8"
          }, [
            _createVNode(_component_router_link, {
              to: { name: 'projects__project', params: { project_id: project.id } },
              class: "project-card"
            }, {
              default: _withCtx(() => [
                ($setup.getThumbnailOption( project ))
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      src: $setup.getThumbnailOption( project ).image_small_url || $setup.getThumbnailOption( project ).thumbnail_url,
                      alt: $setup.getThumbnailOption( project ).title,
                      class: "project-card__image"
                    }, null, 8 /* PROPS */, _hoisted_1))
                  : (_openBlock(), _createElementBlock("div", _hoisted_2)),
                _createElementVNode("h2", _hoisted_3, _toDisplayString(project.title), 1 /* TEXT */),
                _createElementVNode("button", {
                  ref_for: true,
                  ref: `favourite-${project.id}`,
                  class: _normalizeClass(["project-card__favourite", {
							'project-card__favourite--selected': $setup.userHasFavouritedProject( project.id ),
							'project-card__favourite--toggled': $setup.toggled_favourites.includes( project.id ),
							'project-card__favourite--loading': $setup.loading_favourites.includes( project.id )
						}]),
                  onClick: _withModifiers($event => ($setup.toggleProjectAsFavourite( project.id )), ["prevent"])
                }, null, 10 /* CLASS, PROPS */, _hoisted_4),
                (project.notification_count)
                  ? (_openBlock(), _createBlock(_component_router_link, {
                      key: 2,
                      to: { name: 'projects__project', params: { project_id: project.id }, query: { tab: 'notifications' } },
                      class: "project-card__notifications"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_5, [
                          _createElementVNode("span", _hoisted_6, _toDisplayString(project.notification_count), 1 /* TEXT */)
                        ])
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"]))
                  : _createCommentVNode("v-if", true)
              ]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"])
          ]))
        }), 128 /* KEYED_FRAGMENT */))
      ]),
      _: 1 /* STABLE */
    })
  ], 2 /* CLASS */))
}