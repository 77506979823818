
<template>
	<div class="container">
		<Transition name="fade">
			<PageLoaderComponent v-if="loading" />
			<div v-else-if="active_page">
				<div class="mb-32">
					<h1 class="h1">
						{{ active_page.title }}
					</h1>
				</div>
			</div>
			<div
				class="mb-64"
				v-else
			>
				<div class="mb-32">
					<h1 class="h1">
						My Boundary
					</h1>
				</div>
				<div
					v-if="pages.length"
					class="grid grid--xs flex flex--wrap flex--align-stretch flex--row-gap-medium"
				>
					<div
						v-for="page, index in pages"
						:key="page.id"
						class="grid__item a6-12 d6-6"
					>
						<MyBoundaryCardComponent
							:page="page"
							:colour="getCardColour( index )"
						/>
					</div>
				</div>
				<p v-else>
					No content.
				</p>
			</div>
		</Transition>
	</div>
</template>

<script>
import PageLoaderComponent from '../components/PageLoaderComponent';
import MyBoundaryCardComponent from '../components/MyBoundaryCardComponent';

import { useDataStore } from '../stores/data';
import { storeToRefs } from 'pinia';
import { USER_GROUP_STAFF } from '../constants';

export default {
	components: {
		PageLoaderComponent,
		MyBoundaryCardComponent
	},
	setup() {
		const data_store = useDataStore();
		const {
			getMyBoundaryPages,
			setBreadcrumbs
		} = data_store;

		const { user_group } = storeToRefs( data_store );

		return {
			user_group,
			getMyBoundaryPages,
			setBreadcrumbs
		};
	},
	data() {
		return {
			pages: [],
			loading: false
		};
	},
	computed: {
		user_is_staff() {
			return this.user_group === USER_GROUP_STAFF;
		},
	},
	async mounted() {
		this.loading = true;
		if ( !this.user_is_staff ) {
			this.$router.push( { name: 'projects' } );
		}
		this.setBreadcrumbs( [ { label: 'My Boundary', icon: 'user-outline' } ] );
		try {
			const response = await this.getMyBoundaryPages();
			if ( response.data && response.data.entries ) {
				this.pages = response.data.entries;
			}
		} catch( error ) {
			// console.log( error );
		}

		this.loading = false;
	},
	methods: {
		getCardColour( index ) {
			return [ 'light-green', 'clay-orange', 'sand', 'forest-green' ][ index % 4 ];
		}
	}
};

</script>
