import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "breadcrumbs" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_link = _resolveComponent("router-link")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.breadcrumbs, (crumb) => {
      return (_openBlock(), _createElementBlock("div", {
        key: crumb.label,
        class: "breadcrumbs__item"
      }, [
        _createElementVNode("p", {
          class: _normalizeClass(["breadcrumbs__label", { 'breadcrumbs__label--link': crumb.route }])
        }, [
          (crumb.icon)
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                class: _normalizeClass(`icon icon--${ crumb.icon }`)
              }, null, 2 /* CLASS */))
            : _createCommentVNode("v-if", true),
          (crumb.route)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 1,
                to: crumb.route,
                class: "breadcrumbs__link"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(crumb.label), 1 /* TEXT */)
                ]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"]))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                _createTextVNode(_toDisplayString(crumb.label), 1 /* TEXT */)
              ], 64 /* STABLE_FRAGMENT */))
        ], 2 /* CLASS */)
      ]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}