<template>
	<div class="database-modal-wrapper">
		<div
			class="database-modal"
			:class="{ 'database-modal--narrow': mode === 'remove' }"
		>
			<button
				class="database-modal__close"
				aria-label="Close modal"
				@click="closeModal"
			/>
			<div
				class="database-modal__content"
				:class="{ 'database-modal__content--loading': loading }"
			>
				<template v-if="mode === 'add'">
					<h2 class="database-modal__heading">
						Add asset to database
					</h2>
					<p class="database-modal__text">
						You are adding an asset to the database. Would you like to add tags to the image?
					</p>

					<div class="grid flex flex--wrap flex--justify-space-between">
						<div class="grid__item a6-12 f6-6">
							<h3 class="database-modal__sub-heading">
								Common tags
							</h3>
							<TransitionGroup
								v-if="filtered_common_tags.length"
								name="grid"
								tag="ul"
								class="tags mb-32"
							>
								<li
									v-for="tag in filtered_common_tags"
									:key="tag.id"
									class="tags__tag tags__tag--selectable"
									@click="addTag( tag.title )"
								>
									{{ tag.title }}
								</li>
							</TransitionGroup>
							<p
								v-else
								class="mb-32"
							>
								No common tags found.
							</p>

							<div class="form__set mb-16">
								<label
									for="new_tag"
									class="form__label"
								>
									Enter a tag
								</label>
								<input
									ref="new-tag-input"
									id="new_tag"
									v-model="new_tag"
									class="form__input form__input--lowercase"
									type="text"
									name="new_tag"
									:disabled="loading"
									@keyup.enter="submitNewTag"
								>
							</div>

							<button
								class="button button--small mb-16"
								:class="{ 'button--loading': loading }"
								:disabled="!new_tag || loading"
								@click="submitNewTag"
							>
								Create tag
							</button>

							<p
								v-if="error_message"
								class="error-message"
							>
								{{ error_message }}
							</p>
						</div>

						<div class="grid__item a6-12 f6-6">
							<img
								v-if="option.image_small_url || option.thumbnail_url"
								:key="option.id"
								class="database-modal__image"
								:src="option.image_small_url || option.thumbnail_url"
								:alt="option.title"
							>
							<TransitionGroup
								name="grid"
								tag="ul"
								class="tags"
							>
								<li
									v-for="tag in option.database_tags"
									:key="tag.id"
									class="tags__tag tags__tag--selected"
								>
									{{ tag.title }}
									<button
										class="tags__remove-tag"
										@click="removeTag( tag.id )"
									/>
								</li>
							</TransitionGroup>
						</div>
					</div>

					<div class="database-modal__buttons">
						<button
							class="database-modal__cancel"
							@click="closeModal"
						>
							Cancel
						</button>
						<button
							class="database-modal__confirm"
							@click="addImageToDatabase"
						>
							Add asset to database
						</button>
					</div>
				</template>

				<template v-else>
					<h2 class="database-modal__heading">
						Are you sure you want to remove this asset from the database?
					</h2>

					<div class="database-modal__buttons">
						<button
							class="database-modal__cancel"
							@click="closeModal"
						>
							Cancel
						</button>
						<button
							class="database-modal__confirm"
							@click="$emit( 'confirm' )"
						>
							Remove
						</button>
					</div>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
import { useDataStore } from '../../stores/data';

export default {
	props: {
		project: {
			required: true,
			type: Object
		},
		option: {
			required: true,
			type: Object
		},
		baseAssetType: {
			required: true,
			type: String
		},
		mode: {
			required: true,
			type: String
		}
	},
	emits: [ 'confirm', 'close-modal', 'close-modal-and-refresh-data' ],
	setup() {
		const data_store = useDataStore();
		const {
			getCommonDatabaseTags,
			getDatabaseTagsByTitle,
			saveDatabaseTag,
			saveTagsToOption
		} = data_store;
		return {
			getCommonDatabaseTags,
			getDatabaseTagsByTitle,
			saveDatabaseTag,
			saveTagsToOption
		};
	},
	data() {
		return {
			new_tag: '',
			common_tags: [],
			tags_have_changed: false,
			loading: false,
			error_message: null,
		};
	},
	computed: {
		filtered_project_tags() {
			return this.project.project_tags.filter( tag => {
				const option_tags = this.option.database_tags || [];
				const option_tag_titles = option_tags.map( option_tag => option_tag.title.toLowerCase() );
				return !option_tag_titles.includes( tag.title.toLowerCase() );
			} );
		},
		filtered_common_tags() {
			return this.common_tags.filter( tag => {
				const option_tags = this.option.database_tags || [];
				const option_tag_titles = option_tags.map( option_tag => option_tag.title.toLowerCase() );
				return !option_tag_titles.includes( tag.title.toLowerCase() );
			} );
		}
	},
	async mounted() {
		if ( this.mode === 'remove' ) {
			return;
		}
		await this.addAllProjectTags();

		this.loading = true;
		try {
			const response = await this.getCommonDatabaseTags();
			if ( response ) {
				this.common_tags = response;
			}
		} catch( error ) {
			// console.log( error );
		}
		this.loading = false;
	},
	methods: {
		closeModal() {
			if ( this.tags_have_changed ) {
				this.$emit( 'close-modal-and-refresh-data' );
			}
			this.$emit( 'close-modal' );
		},
		async addTag( title ) {
			this.error_message = null;
			this.loading = true;

			let matching_tag = null;

			try {
				const response = await this.getDatabaseTagsByTitle( title );
				if ( response.data.tags && response.data.tags.length ) {
					matching_tag = response.data.tags.find( tag => tag.title.toLowerCase() === title.toLowerCase() );
				}
			} catch ( error ) {
				// this.error_message = error;
			}

			if ( !matching_tag ) {
				try {
					const response = await this.saveDatabaseTag( title );
					matching_tag = response.data.save_database_tags_Tag;
				} catch ( error ) {
					this.error_message = error;
					this.loading = false;
					return;
				}
			}

			try {
				if ( !matching_tag ) {
					this.error_message = 'Something went wrong';
					this.loading = false;
					return;
				}

				if ( !this.baseAssetType ) {
					this.error_message = `Invalid baseAssetType: ${this.baseAssetType}`;
				}

				const tag_id = matching_tag.id;
				const tags = [
					...this.option.database_tags.map( tag => parseInt( tag.id ) ),
					parseInt( tag_id )
				];

				const params = { option_id: this.option.id, tags: tags, asset_type: this.baseAssetType };
				const option_response = await this.saveTagsToOption( params );
				const option_entry = option_response.data && option_response.data[`save_revision_options_${this.baseAssetType}_Entry`];

				if ( !option_entry ) {
					this.error_message = 'Failed to save option';
				}

				this.option.database_tags = option_entry.database_tags;
				this.tags_have_changed = true;
			} catch ( error ) {
				this.error_message = error;
				this.loading = false;
				return;
			}

			this.loading = false;
		},
		async addAllProjectTags() {
			this.loading = true;
			this.error_message = null;
			tags_to_add = [];

			for ( let i = 0; i < this.filtered_project_tags.length; i++ ) {
				let matching_tag = null;

				try {
					const response = await this.getDatabaseTagsByTitle( this.filtered_project_tags[i].title );
					if ( response.data.tags && response.data.tags.length ) {
						matching_tag = response.data.tags.find( tag => tag.title.toLowerCase() === this.filtered_project_tags[i].title.toLowerCase() );
					}
				} catch ( error ) {
					// this.error_message = error;
				}

				if ( !matching_tag ) {
					try {
						const response = await this.saveDatabaseTag( this.filtered_project_tags[i].title );
						matching_tag = response.data.save_database_tags_Tag;
					} catch ( error ) {
						this.error_message = error;
						if ( i == this.filtered_project_tags.length - 1 ) {
							this.loading = false;
						}
						continue;
					}
				}

				if ( !matching_tag ) {
					this.error_message = 'Something went wrong';
					if ( i == this.filtered_project_tags.length - 1 ) {
						this.loading = false;
					}
					continue;
				}
				tags_to_add.push( matching_tag );
			}

			try {
				if ( !this.baseAssetType ) {
					this.error_message = `Invalid baseAssetType: ${this.baseAssetType}`;
				}

				const option_tags = this.option.database_tags || [];
				const tags = [
					...option_tags.map( tag => parseInt( tag.id ) ),
					...tags_to_add.map( tag => parseInt( tag.id ) )
				];

				const params = { option_id: this.option.id, tags: tags, asset_type: this.baseAssetType };
				const option_response = await this.saveTagsToOption( params );
				const option_entry = option_response.data && option_response.data[`save_revision_options_${this.baseAssetType}_Entry`];

				if ( !option_entry ) {
					this.error_message = 'Failed to save option';
				}

				this.option.database_tags = option_entry.database_tags;
				this.tags_have_changed = true;
			} catch ( error ) {
				this.error_message = error;
				this.loading = false;
			}

			this.loading = false;
		},
		async removeTag( tag_id ) {
			this.loading = true;
			try {
				if ( !this.baseAssetType ) {
					this.error_message = `Invalid baseAssetType: ${this.baseAssetType}`;
				}

				const option_tags = this.option.database_tags || [];
				const tags = [
					...option_tags
						.filter( tag => tag.id !== tag_id )
						.map( tag => parseInt( tag.id ) )
				];

				const params = { option_id: this.option.id, tags: tags, asset_type: this.baseAssetType };
				const option_response = await this.saveTagsToOption( params );
				const option_entry = option_response.data && option_response.data[`save_revision_options_${this.baseAssetType}_Entry`];

				if ( !option_entry ) {
					this.error_message = 'Failed to save option';
				}

				this.option.database_tags = option_entry.database_tags;
				this.tags_have_changed = true;
			} catch ( error ) {
				this.error_message = error;
				this.loading = false;
				return;
			}

			this.loading = false;
		},
		async submitNewTag() {
			await this.addTag( this.new_tag );
			this.new_tag = '';
			this.$refs['new-tag-input'].focus();
		},
		addImageToDatabase() {
			this.error_message = null;
			if ( !this.option.database_tags || !this.option.database_tags.length || !this.option.database_tags.length ) {
				this.error_message = 'Please add at least one tag to this asset';
				return;
			}
			this.$emit( 'confirm' );
		}
	}
};
</script>