<template>
	<div class="delete-asset-modal-wrapper">
		<div class="delete-asset-modal">
			<button
				class="delete-asset-modal__close"
				aria-label="Close modal"
				@click="closeModal"
			/>
			<div class="delete-asset-modal__content">
				<h2 class="delete-asset-modal__heading">
					Delete option
				</h2>

				<p class="delete-asset-modal__text">
					Are you sure you want to delete this option? This action is <strong>irreversible</strong>.
				</p>


				<p
					v-if="error_message"
					class="error-message"
				>
					{{ error_message }}
				</p>
			</div>

			<div class="delete-asset-modal__buttons">
				<button
					class="button"
					@click="$emit( 'close-modal' )"
				>
					Cancel
				</button>
				<button
					class="button button--fill-danger"
					:class="{ 'button--loading': loading }"
					@click="deleteOption"
				>
					Delete
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import { useDataStore } from '../../stores/data';

export default {
	props: {
		option: {
			required: true,
			type: Object
		},
	},
	emits: [ 'option-deleted', 'close-modal' ],
	setup() {
		const data_store = useDataStore();
		const { deleteOption, isUserStaffSuperAdmin } = data_store;
		return { deleteOption, isUserStaffSuperAdmin };
	},
	data() {
		return {
			loading: false,
			error_message: null,
			user_is_staff_super_admin: false
		};
	},
	async mounted() {
		this.user_is_staff_super_admin = await this.isUserStaffSuperAdmin();
	},
	methods: {
		closeModal() {
			this.$emit( 'close-modal' );
		},
		async deleteOption() {
			if( this.user_is_staff_super_admin ) {
				this.error_message = 'You do not have permission to delete options.';
			};

			this.error_message = null;
			this.loading = true;

			try {
				const response = await this.deleteOption( { option_id: parseInt( this.option.id ) } );
				if ( response && response.data ) {
					if ( response.data.errors ) {
						this.error_message = 'Something went wrong.';
						this.loading = false;
						return;
					}
					this.$emit( 'option-deleted' );
					this.loading = false;
				}
			} catch ( error ) {
				this.error_message = error;
			}
			this.loading = false;
		}
	}
};
</script>