export const UPLOAD_STATUS_AWAITING = 'awaiting';
export const UPLOAD_STATUS_IN_PROGRESS = 'started';
export const UPLOAD_STATUS_COMPLETE = 'complete';

export const UPLOAD_FORMAT_DRAFT = 'draft';
export const UPLOAD_FORMAT_IMAGE = 'image';
export const UPLOAD_FORMAT_VIDEO = 'video';
export const UPLOAD_FORMAT_EXPLORE = 'explore';

export const USER_GROUP_CLIENT = 'client';
export const USER_GROUP_FREELANCER = 'freelancer';
export const USER_GROUP_STAFF = 'staff';

export const USER_TYPE_BUSINESS_DEVELOPMENT = 'business development';
export const USER_TYPE_ACCOUNT_DIRECTOR = 'account director';
export const USER_TYPE_PROJECT_MANAGER = 'project manager';
export const USER_TYPE_LEAD_ARTIST = 'lead artist';
export const USER_TYPE_ARTIST = 'artist';
export const USER_TYPE_CLIENT = 'client';
