import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "sidebar-notification-wrapper" }
const _hoisted_2 = { class: "sidebar-notification__header" }
const _hoisted_3 = { class: "sidebar-notification__activity-image" }
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 1,
  class: "sidebar-notification__project-thumbnail sidebar-notification__project-thumbnail--default"
}
const _hoisted_6 = { class: "sidebar-notification__profile-image" }
const _hoisted_7 = { class: "sidebar-notification__title" }
const _hoisted_8 = { class: "sidebar-notification__label" }
const _hoisted_9 = { class: "sidebar-notification__timestamp" }
const _hoisted_10 = { class: "sidebar-notification__main" }
const _hoisted_11 = { class: "sidebar-notification__content" }
const _hoisted_12 = {
  key: 0,
  class: "sidebar-notification__body"
}
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = { class: "sidebar-notification__comment-metadata" }
const _hoisted_15 = {
  key: 0,
  class: "sidebar-notification__image-attachments"
}
const _hoisted_16 = ["src"]
const _hoisted_17 = {
  key: 1,
  class: "sidebar-notification__files-pending"
}
const _hoisted_18 = {
  key: 2,
  class: "sidebar-notification__links"
}
const _hoisted_19 = {
  key: 1,
  class: "sidebar-notification__body"
}
const _hoisted_20 = {
  key: 0,
  class: "sidebar-notification__thumbnails"
}
const _hoisted_21 = ["src"]
const _hoisted_22 = {
  key: 2,
  class: "sidebar-notification__body"
}
const _hoisted_23 = {
  key: 0,
  class: "sidebar-notification__thumbnails"
}
const _hoisted_24 = ["src"]
const _hoisted_25 = {
  key: 3,
  class: "sidebar-notification__body"
}
const _hoisted_26 = {
  key: 0,
  class: "sidebar-notification__thumbnails"
}
const _hoisted_27 = ["src"]
const _hoisted_28 = {
  key: 4,
  class: "sidebar-notification__body"
}
const _hoisted_29 = {
  key: 0,
  class: "sidebar-notification__thumbnails"
}
const _hoisted_30 = ["src"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ProfilePictureComponent = _resolveComponent("ProfilePictureComponent")
  const _component_router_link = _resolveComponent("router-link")

  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createVNode(_component_router_link, {
      class: "sidebar-notification",
      to: $props.notification.action_url
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            ($options.project_thumbnail_option)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  ($options.project_thumbnail_option.image_small_url || $options.project_thumbnail_option.thumbnail_url)
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 0,
                        src: $options.project_thumbnail_option.image_small_url || $options.project_thumbnail_option.thumbnail_url,
                        class: "sidebar-notification__project-thumbnail"
                      }, null, 8 /* PROPS */, _hoisted_4))
                    : _createCommentVNode("v-if", true)
                ], 64 /* STABLE_FRAGMENT */))
              : (_openBlock(), _createElementBlock("div", _hoisted_5)),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_ProfilePictureComponent, {
                user: $options.related_user,
                size: "xs",
                rounded: true,
                bordered: true
              }, null, 8 /* PROPS */, ["user"])
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("h2", null, _toDisplayString(this.notification.project ? this.notification.project.title : 'Unknown project'), 1 /* TEXT */),
            _createElementVNode("p", _hoisted_8, _toDisplayString($options.notification_label), 1 /* TEXT */)
          ]),
          _createElementVNode("p", _hoisted_9, _toDisplayString($options.timestamp), 1 /* TEXT */)
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            (['OptionCommentPosted', 'DiscussionCommentPosted'].includes($props.notification.type))
              ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                  ($props.notification.entry.comment_content)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: "sidebar-notification__comment-content",
                        innerHTML: $props.notification.entry.comment_content
                      }, null, 8 /* PROPS */, _hoisted_13))
                    : _createCommentVNode("v-if", true),
                  _createElementVNode("div", _hoisted_14, [
                    ($options.getImages( $props.notification.entry.comment_attachments ).length)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.getImages( $props.notification.entry.comment_attachments ), (attachment, index) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: `${$props.notification.entry.id}-image-attachment-${index}`,
                              class: "sidebar-notification__image-attachment-thumbnail"
                            }, [
                              _createElementVNode("img", {
                                class: "sidebar-notification__image-attachment",
                                src: attachment.attachment_thumbnail_url
                              }, null, 8 /* PROPS */, _hoisted_16)
                            ]))
                          }), 128 /* KEYED_FRAGMENT */))
                        ]))
                      : _createCommentVNode("v-if", true),
                    ($options.filesPending( $props.notification.entry ))
                      ? (_openBlock(), _createElementBlock("p", _hoisted_17, _toDisplayString($options.filesPending( $props.notification.entry )) + " attachment(s) processing ", 1 /* TEXT */))
                      : _createCommentVNode("v-if", true),
                    ($options.getFiles( $props.notification.entry.comment_attachments ).length)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.getFiles( $props.notification.entry.comment_attachments ), (attachment, index) => {
                            return (_openBlock(), _createElementBlock("p", {
                              key: `${$props.notification.entry.id}-image-attachment-${index}`,
                              class: "sidebar-notification__pdf-attachment"
                            }, _toDisplayString(attachment.attachment_filename), 1 /* TEXT */))
                          }), 128 /* KEYED_FRAGMENT */))
                        ]))
                      : _createCommentVNode("v-if", true)
                  ])
                ]))
              : ($props.notification.type === 'UploadAdded')
                ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                    ($props.notification.entries)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.notification.entries, (option, index) => {
                            return (_openBlock(), _createElementBlock(_Fragment, {
                              key: option.id
                            }, [
                              (index < 10 && ( option.image_small_url || option.thumbnail_url ))
                                ? (_openBlock(), _createElementBlock("img", {
                                    key: 0,
                                    class: "sidebar-notification__thumbnail",
                                    src: option.image_small_url || option.thumbnail_url
                                  }, null, 8 /* PROPS */, _hoisted_21))
                                : _createCommentVNode("v-if", true)
                            ], 64 /* STABLE_FRAGMENT */))
                          }), 128 /* KEYED_FRAGMENT */))
                        ]))
                      : _createCommentVNode("v-if", true)
                  ]))
                : ($props.notification.type === 'SubmissionCreated')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                      ($props.notification.entry && $props.notification.entry.options)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.notification.entry.options, (option, index) => {
                              return (_openBlock(), _createElementBlock(_Fragment, {
                                key: option.id
                              }, [
                                (index < 10 && ( option.image_small_url || option.thumbnail_url ))
                                  ? (_openBlock(), _createElementBlock("img", {
                                      key: 0,
                                      class: "sidebar-notification__thumbnail",
                                      src: option.image_small_url || option.thumbnail_url
                                    }, null, 8 /* PROPS */, _hoisted_24))
                                  : _createCommentVNode("v-if", true)
                              ], 64 /* STABLE_FRAGMENT */))
                            }), 128 /* KEYED_FRAGMENT */))
                          ]))
                        : _createCommentVNode("v-if", true)
                    ]))
                  : ($props.notification.type === 'OptionClientApproved')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                        ($props.notification.entry)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                              ($props.notification.entry.image_small_url || $props.notification.entry.thumbnail_url)
                                ? (_openBlock(), _createElementBlock("img", {
                                    key: 0,
                                    class: "sidebar-notification__thumbnail",
                                    src: $props.notification.entry.image_small_url || $props.notification.entry.thumbnail_url
                                  }, null, 8 /* PROPS */, _hoisted_27))
                                : _createCommentVNode("v-if", true)
                            ]))
                          : _createCommentVNode("v-if", true)
                      ]))
                    : ($props.notification.type === 'RevisionClientApproved')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                          ($props.notification.entry)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.notification.entry.revision_options, (option, index) => {
                                  return (_openBlock(), _createElementBlock(_Fragment, {
                                    key: option.id
                                  }, [
                                    (index < 10 && ( option.image_small_url || option.thumbnail_url ))
                                      ? (_openBlock(), _createElementBlock("img", {
                                          key: 0,
                                          class: "sidebar-notification__thumbnail",
                                          src: option.image_small_url || option.thumbnail_url
                                        }, null, 8 /* PROPS */, _hoisted_30))
                                      : _createCommentVNode("v-if", true)
                                  ], 64 /* STABLE_FRAGMENT */))
                                }), 128 /* KEYED_FRAGMENT */))
                              ]))
                            : _createCommentVNode("v-if", true)
                        ]))
                      : _createCommentVNode("v-if", true)
          ])
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["to"])
  ]))
}