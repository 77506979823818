
<template>
	<div class="search-bar">
		<template v-if="active_projects_tab !== 'example_projects'">
			<button
				v-if="user_is_staff && active_projects_tab === 'all'"
				class="search-bar__filters-toggle"
				:class="show_filters ? 'search-bar__filters-toggle--toggled': 'search-bar__filters-toggle--untoggled'"
				@click="show_filters = !show_filters"
			>
				{{  show_filters ? 'Close filter' : 'Filter' }}{{ total_filter_count ? ' (' + total_filter_count + ')' : '' }}
			</button>
			<button
				v-if="total_filter_count > 0"
				class="button button--fill button--smallest icon-end icon-end--close"
				@click="handleClearAllFilters"
			>
				Clear all
			</button>
			<div
				class="search-bar__field a4-12"
				@click="focusProjectSearch"
			>
				<input
					ref="project_search_input"
					v-model="project_search_query"
					class="search__input"
					type="text"
					placeholder="Search for a project"
					@input="handleProjectSearchInputChange"
				>
			</div>
		</template>

		<p class="search-bar__count">
			Showing {{ project_pagination.total_count }} project{{ project_pagination.total_count === 1 ? '' : 's' }}
		</p>
	</div>
</template>

<script setup>
import { ref, computed, nextTick } from 'vue';
import { storeToRefs } from 'pinia';
import { useDataStore } from '../../stores/data';
import { useProjectFiltersStore } from '../../stores/project-filters';
import { USER_GROUP_STAFF } from '../../constants';

const data_store = useDataStore();
const filters_store = useProjectFiltersStore();

const { user_group } = storeToRefs( data_store );

const {
	resetProjectPagination,
	resetActiveAdvancedFilters,
	updateQueryStringFromProjectFilters
} = filters_store;

const {
	active_projects_tab,
	show_filters,
	project_search_query,
	project_pagination,
	total_filter_count
} = storeToRefs( filters_store );

const user_is_staff = computed( () => {
	return user_group.value === USER_GROUP_STAFF;
} );

// Project search
const project_search_input = ref( null );

const handleProjectSearchInputChange = async ( e ) => {
	const input = e.target.value;
	setTimeout( async() => {
		if ( project_search_query.value === input ) {
			resetProjectPagination();
			await nextTick();
			updateQueryStringFromProjectFilters();
		}
	}, 500 );
};

const handleClearAllFilters = () => {
	resetActiveAdvancedFilters();
	resetProjectPagination();
	updateQueryStringFromProjectFilters();
}

const focusProjectSearch = () => {
	project_search_input.value.focus();
};
</script>