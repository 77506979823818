<template>
	<CheckboxesDropdownComponent
		id="developer"
		label="Developer"
		placeholder="Search"
		:query="developer.search_query"
		:options="filtered_developers"
		:selection="active_developer_ids"
		:stretch="true"
		:loading="loading"
		@select="handleDeveloperSelect"
		@search="handleDeveloperSearchInputChange"
	/>
</template>

<script setup>
import CheckboxesDropdownComponent from '../CheckboxesDropdownComponent.vue';

import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useProjectFiltersStore } from '../../stores/project-filters';

const props = defineProps( {
	loading: {
		required: false,
		type: Boolean,
		default: false
	}
} );

const filters_store = useProjectFiltersStore();

const {
	setFilterSearchQuery,
	setActiveFilterValue,
	removeActiveFilterValue,
	resetProjectPagination,
	updateQueryStringFromProjectFilters,
} = filters_store;

const { developer, filtered_developers } = storeToRefs( filters_store );

const active_developer_ids = computed( () => {
	return developer.value.active.map( company => company.value );
} );

const handleDeveloperSelect = ( company ) => {
	company.checked ? setActiveFilterValue( company, 'developer' ) : removeActiveFilterValue( company, 'developer' );
	resetProjectPagination();
	updateQueryStringFromProjectFilters();
};

const handleDeveloperSearchInputChange = async ( value ) => {
	setFilterSearchQuery( value, 'developer' );
};
</script>