import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["CheckboxesDropdownComponent"], {
    id: "creative-agency",
    label: "Creative Agency",
    placeholder: "Search",
    query: $setup.creative_agency.search_query,
    options: $setup.filtered_creative_agencies,
    selection: $setup.active_creative_agency_ids,
    stretch: true,
    loading: $props.loading,
    onSelect: $setup.handleAgencySelect,
    onSearch: $setup.handleAgencySearchInputChange
  }, null, 8 /* PROPS */, ["query", "options", "selection", "loading"]))
}