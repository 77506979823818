<template>
	<CheckboxesDropdownComponent
		id="sales-marketing"
		label="Sales &amp; Marketing"
		placeholder="Search"
		:query="sales_marketing.search_query"
		:options="filtered_sales_marketing"
		:selection="active_sales_marketing_ids"
		:stretch="true"
		:loading="loading"
		@select="handleCompanySelect"
		@search="handleCompanySearchInputChange"
	/>
</template>

<script setup>
import CheckboxesDropdownComponent from '../CheckboxesDropdownComponent.vue';

import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useProjectFiltersStore } from '../../stores/project-filters';

const props = defineProps( {
	loading: {
		required: false,
		type: Boolean,
		default: false
	}
} );

const filters_store = useProjectFiltersStore();

const {
	setFilterSearchQuery,
	setActiveFilterValue,
	removeActiveFilterValue,
	resetProjectPagination,
	updateQueryStringFromProjectFilters,
} = filters_store;

const { sales_marketing, filtered_sales_marketing } = storeToRefs( filters_store );

const active_sales_marketing_ids = computed( () => {
	return sales_marketing.value.active.map( company => company.value );
} );

const handleCompanySelect = ( company ) => {
	company.checked ? setActiveFilterValue( company, 'sales_marketing' ) : removeActiveFilterValue( company, 'sales_marketing' );
	resetProjectPagination();
	updateQueryStringFromProjectFilters();
};

const handleCompanySearchInputChange = async ( value ) => {
	setFilterSearchQuery( value, 'sales_marketing' );
};
</script>