<template>
	<div class="discussion-header">
		<h2 class="discussion-header__title">
			{{ discussion.title }}
		</h2>
		<p class="discussion-header__info">
			{{ discussion_start_date }} &nbsp;&nbsp;&#x2022;&nbsp;&nbsp; Started by {{ discussion_author }}
		</p>
	</div>

	<div class="a8-12 c12-12">
		<CommentsComponent
			:comments="sorted_comments"
			comment-type="discussion"
		/>

		<div
			v-if="projectComplete"
			class="mb-48"
		>
			<p>
				Project is now complete and further commenting has been disabled.
			</p>
		</div>

		<div
			v-else
			class="mb-48"
		>
			<CommentFormComponent
				ref="comment-form"
				:discussion-id="discussion.id"
				:no-border="true"
				comment-type="discussion"
				@comment-posted="refreshComments"
			/>
		</div>
	</div>
</template>

<script>
import CommentFormComponent from './CommentFormComponent.vue';
import CommentsComponent from './CommentsComponent.vue';

import moment from 'moment';

export default {
	components: {
		CommentFormComponent,
		CommentsComponent
	},
	props: {
		discussion: { required: true, type: Object },
		comments: { required: true, type: Array },
		projectComplete: { required: false, type: Boolean, default: false }
	},
	emits: [ 'refresh-comments' ],
	computed: {
		sorted_comments() {
			if ( !this.comments ) {
				return [];
			}
			return this.comments.toReversed();
		},
		discussion_start_date() {
			return moment( new Date( this.discussion.postDate ) ).format( 'D MMMM YYYY' );
		},
		discussion_author() {
			return this.discussion.author.fullName;
		}
	},
	methods: {
		refreshComments() {
			this.$emit( 'refresh-comments', this.discussion.id );
		}
	}
};
</script>
