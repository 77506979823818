import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_AssetSidebarComponent = _resolveComponent("AssetSidebarComponent")
  const _component_ActivitySidebarComponent = _resolveComponent("ActivitySidebarComponent")

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["sidebar-wrapper", { 'sidebar-wrapper--expand': $options.is_home_route || $data.expand }])
  }, [
    _createVNode(_Transition, { name: "sidebar" }, {
      default: _withCtx(() => [
        ($options.is_asset_route)
          ? (_openBlock(), _createBlock(_component_AssetSidebarComponent, {
              key: 0,
              ref: "asset-sidebar",
              expand: $data.expand,
              onSetExpand: $options.setExpand
            }, null, 8 /* PROPS */, ["expand", "onSetExpand"]))
          : ($options.is_home_route)
            ? (_openBlock(), _createBlock(_component_ActivitySidebarComponent, {
                key: 1,
                ref: "activity-sidebar",
                expand: $options.is_home_route || $data.expand
              }, null, 8 /* PROPS */, ["expand"]))
            : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    }),
    ($options.is_asset_route)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: "sidebar-wrapper__toggle",
          onClick: _cache[0] || (_cache[0] = $event => ($data.expand = !$data.expand))
        }))
      : _createCommentVNode("v-if", true)
  ], 2 /* CLASS */))
}