<template>
	<div
		class="sidebar-wrapper"
		:class="{ 'sidebar-wrapper--expand': is_home_route || expand }"
	>
		<Transition name="sidebar">
			<AssetSidebarComponent
				v-if="is_asset_route"
				ref="asset-sidebar"
				:expand="expand"
				@set-expand="setExpand"
			/>
			<ActivitySidebarComponent
				v-else-if="is_home_route"
				ref="activity-sidebar"
				:expand="is_home_route || expand"
			/>
		</Transition>
		<button
			v-if="is_asset_route"
			class="sidebar-wrapper__toggle"
			@click="expand = !expand"
		/>
	</div>
</template>

<script>
import ActivitySidebarComponent from './ActivitySidebarComponent.vue';
import AssetSidebarComponent from './AssetSidebarComponent.vue';

export default {
	components: {
		ActivitySidebarComponent,
		AssetSidebarComponent
	},
	data() {
		return {
			expand: false,
		};
	},
	computed: {
		is_asset_route() {
			return this.$route.name === 'projects__project__asset';
		},
		is_home_route() {
			return this.$route.name === 'home';
		},
	},
	watch: {
		is_asset_route( val ) {
			if ( !val ) {
				this.setExpand( false );
			}
		}
	},
	methods: {
		setExpand( value ) {
			this.expand = value;
		},
		showApprovals() {
			if ( !this.$refs[ 'asset-sidebar' ] ) {
				return;
			}
			this.$refs[ 'asset-sidebar' ].showApprovals();
		}
	}
};
</script>
