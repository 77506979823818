<template>
	<div class="mb-64">
		<div v-if="notifications">
			<div class="flex flex--justify-space-between flex--wrap mb-32">
				<p
					class="notifications-total"
					:class="{ 'notifications-total--loading': loading }"
				>
					{{ pendingCount }} new notifications
				</p>
				<button
					v-if="notifications.length && pendingCount > 0"
					class="p--small a a--underline"
					@click="markAllAsRead"
				>
					Mark all as read
				</button>
			</div>
			<div>
				<NotificationsListComponent
					:notifications="notifications"
					@mark-as-read="markAsRead"
				/>
			</div>
		</div>
		<div v-else>
			<p>No new notifications.</p>
		</div>
	</div>
</template>

<script>
import NotificationsListComponent from './NotificationsListComponent';

import { useDataStore } from '../stores/data';
import { storeToRefs  } from 'pinia';

export default {
	components: {
		NotificationsListComponent
	},
	props: {
		notifications: { required: true, type: Array },
		pendingCount: { required: true, type: Number, default: 0 },
		loading: { required: true, type: Boolean }
	},
	emits: [ 'mark-as-read', 'mark-all-as-read' ],
	setup() {
		const data_store = useDataStore();
		const { markNotificationAsRead, markNotificationsAsRead } = data_store;
		const { loading_notifications } = storeToRefs( data_store );

		return { markNotificationAsRead, markNotificationsAsRead, loading_notifications };
	},
	methods: {
		async markAsRead( notification_id ) {
			const response = await this.markNotificationAsRead( notification_id );
			if ( !response ) {
				return;
			}
			this.$emit( 'mark-as-read', notification_id );
		},
		async markAllAsRead() {
			this.$emit( 'mark-all-as-read' );
		}
	}
};
</script>
