<template>
	<Transition name="fade">
		<PageLoaderComponent v-if="loading" />
		<div
			v-else
			class="mb-32"
		>
			<div class="flex flex--wrap flex--justify-space-between mb-32">
				<div class="a7-12 f6-6">
					<h3 class="form__label">
						Jump to:
					</h3>
					<div class="flex flex--column-gap-large flex--row-gap-medium flex--wrap">
						<template v-for="( items, key ) in assets">
							<button
								v-if="items.length > 0"
								:key="'jump-to-' + key"
								class="p--small a a--underline"
								@click="scrollTo( media_types[key].title )"
							>
								{{ media_types[key].title }}
							</button>
							<p
								v-else
								:key="'empty-jump-to-' + key"
								class="p--small flex flex--align-center flex--gap-small"
							>
								{{ media_types[key].title }}
								<button
									v-if="( user_is_staff_admin || user_is_staff_super_admin ) && project.project_status !== 'completed'"
									class="inline-add-asset-button"
									:aria-label="'Add new asset for type: ' + media_types[key].title"
									@click="handleNewAssetClick( media_types[key].title, key )"
								></button>
							</p>
						</template>
					</div>
				</div>

				<div class="a5-12 f6-6 flex flex--gap-medium flex--justify-end flex--align-center">
					<button
						class="p--small a a--underline"
						@click="toggleAllChecked"
					>
						{{ all_assets_checked ? 'Deselect all' : 'Select all' }}
					</button>
					<button
						class="button button--fill"
						:class="{ 'button--loading': loading_download }"
						:disabled="no_assets_checked"
						@click="downloadSelectedAssetsLatestOptions"
					>
						Download selected
					</button>
				</div>
			</div>

			<template
				v-for="( assets_of_type, key ) in assets"
				:key="key"
			>
				<template v-if="assets_of_type.length > 0">
					<h2
						:id="media_types[key].title"
						class="h4"
					>
						{{ media_types[key].title }}
					</h2>

					<div class="grid grid--xs flex flex--wrap mb-32">
						<div
							v-for="asset, index in assets_of_type"
							:key="'asset-' + asset.id"
							class="grid__item a2-12 mb-32"
						>
							<router-link
								:to="{
									name: 'projects__project__asset',
									params: {
										project_id: $route.params.project_id,
										asset_id: asset.id
									}
								}"
								class="image-grid-card"
							>
								<img
									v-if="asset.featured_image_small.url"
									:src="asset.featured_image_small.url"
									:alt="asset.featured_image_small.title"
									class="image-grid-card__image"
								>

								<div
									v-else
									class="image-grid-card__image image-grid-card__image--default"
								/>

								<div
									class="image-grid-card__info"
									:class="{ 'image-grid-card__info--with-revision': asset.last_revision }"
								>
									<p class="image-grid-card__title">
										{{ asset.title }}
									</p>
									<div class="image-grid-card__details-container">
										<div
											v-if="asset.last_revision"
											class="image-grid-card__details"
										>
											<span
												:class="[
													'image-grid-card__stage',
													getRevisionPipClass( asset.last_revision )
												]"
											>
												{{ asset.last_revision.revision_number }}
											</span>
											<span
												v-if="unread_comment_counts[asset.id]"
												class="image-grid-card__comments"
											>
												<span class="image-grid-card__comment-count">{{ unread_comment_counts[asset.id] }}</span>
											</span>
										</div>
										<div
											v-if="user_is_staff_admin || user_is_staff_super_admin"
											class="image-grid-card__action-container"
										>
											<span
												v-if="assetIsThumbnail( asset.id )"
												class="image-grid-card__thumbnail-icon"
											/>
											<button
												class="image-grid-card__action-toggle"
												aria-label="Toggle asset action menu"
												@click.prevent="toggleActionTooltip( asset.id )"
											/>

											<div
												v-if="visible_action_tooltip === asset.id"
												class="image-grid-card__action-tooltip"
												:class="{ 'image-grid-card__action-tooltip--mirrored': ( index + 1 ) % 6 === 0 }"
											>
												<button
													v-if="asset.last_revision"
													class="image-grid-card__set-as-thumbnail"
													:class="{ 'image-grid-card__set-as-thumbnail--loading': setting_thumbnail === asset.id }"
													@click.prevent="toggleAssetAsThumbnail( asset.id )"
												>
													{{ assetIsThumbnail( asset.id ) ? 'Unset as thumbnail' : 'Set as thumbnail' }}
												</button>
												<button
													v-if="project.project_status !== 'completed'"
													class="image-grid-card__delete"
													@click.prevent="openDeleteAssetModal( asset )"
												>
													Delete asset
												</button>
											</div>
										</div>
									</div>
								</div>
								<button
									v-if="[ 'image', 'video' ].includes( media_types[key].base_type ) && ( asset.featured_image_small.url || asset.featured_video_url )"
									class="image-grid-card__fullscreen"
									aria-label="View image fullscreen"
									@click.prevent="openGallery( key, asset.id )"
								/>
								<button
									v-if="media_types[key].base_type === 'image' && asset.featured_image_small.url"
									class="image-grid-card__checkbox"
									:class="{ 'image-grid-card__checkbox--checked': asset.checked }"
									:aria-label="asset.checked ? `Select asset's images for download` : `Deselect asset's images for download`"
									@click.prevent="toggleAssetCheck( key, index )"
								/>
							</router-link>
						</div>

						<div
							v-if="( user_is_staff_admin || user_is_staff_super_admin ) && project.project_status !== 'completed'"
							class="grid__item a2-12 mb-32"
						>
							<button
								class="image-grid-card image-grid-card--add-item"
								@click="handleNewAssetClick( media_types[key].title, key )"
							>
								Add asset
							</button>
						</div>
					</div>
				</template>
			</template>
		</div>
	</Transition>

	<Teleport to="#modal">
		<Transition name="fade">
			<GalleryModalComponent
				v-if="gallery_options && gallery_options.length && show_gallery_modal"
				:options="gallery_options"
				@close-modal="closeGalleryModal"
			/>

			<BasicNewAssetModalComponent
				v-else-if="selected_media_type_id && show_basic_new_asset_modal"
				:project="project"
				:media-type-id="selected_media_type_id"
				@asset-added="$emit('refresh-project')"
				@close-modal="closeBasicNewAssetModal"
			/>

			<NewAssetModalComponent
				v-else-if="selected_media_type_id && show_new_asset_modal"
				:project="project"
				:media-type-id="selected_media_type_id"
				@asset-added="$emit('refresh-project')"
				@close-modal="closeNewAssetModal"
			/>

			<DeleteAssetModalComponent
				v-else-if="asset_to_delete && show_delete_asset_modal"
				:project="project"
				:asset="asset_to_delete"
				@asset-deleted="$emit('refresh-project')"
				@close-modal="closeDeleteAssetModal"
			/>
		</Transition>
	</Teleport>
</template>

<script>
import GalleryModalComponent from './modals/GalleryModalComponent.vue';
import DeleteAssetModalComponent from './modals/DeleteAssetModalComponent.vue';
import BasicNewAssetModalComponent from './modals/BasicNewAssetModalComponent.vue';
import NewAssetModalComponent from './modals/NewAssetModalComponent.vue';
import PageLoaderComponent from './PageLoaderComponent.vue';

import { getAssetThumbnailOption } from '../../helpers';
import { useDataStore } from '../stores/data';
import { storeToRefs } from 'pinia';
import VueScrollTo from 'vue-scrollto';

export default {
	components: {
		GalleryModalComponent,
		DeleteAssetModalComponent,
		BasicNewAssetModalComponent,
		NewAssetModalComponent,
		PageLoaderComponent
	},
	props: {
		project: {
			required: true,
			type: Object
		}
	},
	setup() {
		const data_store = useDataStore();
		const {
			isUserStaffAdmin,
			isUserStaffSuperAdmin,
			createArchive,
			deleteAsset,
			setProjectThumbnail,
			setAlertMessage,
			getUnreadCommentCountsByProjectId
		} = data_store;
		const { media_types } = storeToRefs( data_store );
		return {
			media_types,
			isUserStaffAdmin,
			isUserStaffSuperAdmin,
			createArchive,
			deleteAsset,
			setProjectThumbnail,
			setAlertMessage,
			getUnreadCommentCountsByProjectId
		};
	},
	data() {
		return {
			loading: true,
			assets: null,
			user_is_staff_admin: false,
			user_is_staff_super_admin: false,
			unread_comment_counts: {},
			gallery_options: null,
			selected_media_type_id: null,
			show_gallery_modal: false,
			show_basic_new_asset_modal: false,
			show_new_asset_modal: false,
			show_delete_asset_modal: false,
			loading_download: false,
			grid_key: 1,
			visible_action_tooltip: null,
			asset_to_delete: null,
			setting_thumbnail: null
		};
	},
	computed: {
		all_assets() {
			const all_assets = [];
			Object.keys( this.assets ).forEach( type => {
				all_assets.push( ...this.assets[type] );
			} );
			return all_assets;
		},
		no_assets_checked() {
			return !this.all_assets.find( asset => asset.checked );
		},
		all_assets_checked() {
			let all_checked = true;
			Object.keys( this.assets ).forEach( type => {
				this.assets[type].forEach( ( asset ) => {
					if ( asset.media_type[0].base_type === 'image' && asset.featured_image_small.url && !asset.checked ) {
						all_checked = false;
					}
				} );
			} );
			return all_checked;
		}
	},
	async mounted() {
		this.sortAssetsByType();
		const response = await this.getUnreadCommentCountsByProjectId( parseInt( this.$route.params.project_id ) );
		if ( response.data && response.data.unreadCommentCountPerAsset ) {
			response.data.unreadCommentCountPerAsset.forEach( item => {
				this.unread_comment_counts[item.asset_id] = item.comment_count;
			} );
		}

		this.user_is_staff_admin = await this.isUserStaffAdmin();
		this.user_is_staff_super_admin = await this.isUserStaffSuperAdmin();

		this.loading = false;
	},
	methods: {
		sortAssetsByType() {
			const all_assets = {};

			Object.keys( this.media_types ).forEach( type_id => {
				const filtered_assets = this.project.project_assets
					.filter( asset => {
						const selected_media_type_ids = asset.media_type.map( type => type.id );

						return selected_media_type_ids.includes( type_id );
					} )
					.map( asset => {
						return {
							...asset,
							checked: false,
							featured_image_small: {
								url: getAssetThumbnailOption( asset ) ? getAssetThumbnailOption( asset ).image_small_url || getAssetThumbnailOption( asset ).thumbnail_url : null,
								alt_text: getAssetThumbnailOption( asset ) ? getAssetThumbnailOption( asset ).title : asset.title
							},
							featured_image_large: {
								url: getAssetThumbnailOption( asset ) ? getAssetThumbnailOption( asset ).image_large_url : null,
								alt_text: getAssetThumbnailOption( asset ) ? getAssetThumbnailOption( asset ).title : asset.title
							},
							featured_video_url: getAssetThumbnailOption( asset ) ? getAssetThumbnailOption( asset ).video_url ?? null : null,
							tour_id: getAssetThumbnailOption( asset ) ? getAssetThumbnailOption( asset ).tour_id ?? null : null,
							last_revision: asset.final_revision.length ? { ...asset.final_revision[0], revision_number: 'C' } : this.getLatestRevisionWithOptions( asset ) ?? null,
							comment_count: 2
						};
					} )
				;

				all_assets[type_id] = filtered_assets;
			} );

			this.assets = all_assets;
		},
		toggleAssetCheck( type, index ) {
			this.assets[type][index].checked = !this.assets[type][index].checked;
		},
		toggleAllChecked() {
			let checked_value = true;
			if ( this.all_assets_checked ) {
				checked_value = false;
			}
			Object.keys( this.assets ).forEach( type => {
				this.assets[type].forEach( ( asset, index ) => {
					if ( asset.media_type[0].base_type === 'image' && asset.featured_image_small.url ) {
						this.assets[type][index].checked = checked_value;
					}
				} );
			} );
		},
		async downloadSelectedAssetsLatestOptions() {
			this.loading_download = true;
			this.setAlertMessage( null );

			const option_ids = [];
			const checked_assets = this.all_assets.filter( asset => asset.checked );

			checked_assets.forEach( asset => {
				const revisions_sorted_by_revision_number = asset.asset_revisions.sort( ( a, b ) => {
					if ( a.revision_number[0] < b.revision_number[0] ) {
						return -1;
					}
					if ( a.revision_number[0] > b.revision_number[0] ) {
						return 1;
					}
					return parseInt( a.revision_number.slice( 1 ) ) - parseInt( b.revision_number.slice( 1 ) );
				} );

				option_ids.push( ...revisions_sorted_by_revision_number.at( -1 ).revision_options.map( option => parseInt( option.id ) ) );
			} );

			try {
				const response = await this.createArchive( {
					option_ids: option_ids,
					project_id: parseInt( this.$route.params.project_id )
				} );

				if ( response && response.data ) {
					this.setAlertMessage( "Your download is being prepared, we will email you a link when it's ready" ); // eslint-disable-line quotes
				}
			} catch ( error ) {
				// console.log( error );
			}
			this.loading_download = false;
		},
		getLatestRevisionWithOptions( asset ) {
			const revisions_with_options = asset.asset_revisions.filter( revision => revision.revision_options && revision.revision_options.length );
			if ( !revisions_with_options || !revisions_with_options.length ) {
				return null;
			}

			const sorted_revisions = revisions_with_options
				.slice()
				.sort( ( a, b ) => {
					if ( a.revision_number[0] < b.revision_number[0] ) {
						return -1;
					}
					if ( a.revision_number[0] > b.revision_number[0] ) {
						return 1;
					}
					return parseInt( a.revision_number.slice( 1 ) ) - parseInt( b.revision_number.slice( 1 ) );
				} );
			const latest_revision = sorted_revisions.at( -1 );

			return latest_revision || null;
		},
		getRevisionPipClass( revision ) {
			const has_unapproved_options = revision.revision_options.find( option => option.staff_approval_user.length < 1 );

			if ( has_unapproved_options ) {
				return 'image-grid-card__stage--unapproved';
			}

			const first_char = revision.revision_number[0];

			switch ( first_char ) {
				case 'A':
					return 'image-grid-card__stage--stage-a';
				case 'B':
					return 'image-grid-card__stage--stage-b';
				case 'C':
					return 'image-grid-card__stage--stage-c';
			}
		},
		openGallery( type_id, asset_id ) {
			this.gallery_options = [ ...this.assets[ type_id ] ]
				.filter( asset => asset.featured_image_large.url || asset.featured_video_url )
				.map( ( asset ) => {
					return {
						title: asset.title,
						image_url: asset.featured_image_large ? asset.featured_image_large.url : null,
						video_url: asset.featured_video_url ?? null,
						selected: asset.id === asset_id
					};
				} )
			;
			this.show_gallery_modal = true;
		},
		closeGalleryModal() {
			this.show_gallery_modal = false;
			this.gallery_options = null;
		},
		handleNewAssetClick( media_type_name, media_type_id ) {
			if ( ['Film', 'Virtual Tour'].includes( media_type_name ) ) {
				this.openBasicNewAssetModal( media_type_id );
				return;
			}
			this.openNewAssetModal( media_type_id );
		},
		openBasicNewAssetModal( media_type_id ) {
			this.selected_media_type_id = parseInt( media_type_id );
			this.show_basic_new_asset_modal = true;
		},
		openNewAssetModal( media_type_id ) {
			this.selected_media_type_id = parseInt( media_type_id );
			this.show_new_asset_modal = true;
		},
		closeBasicNewAssetModal() {
			this.selected_media_type_id = null;
			this.show_basic_new_asset_modal = false;
		},
		closeNewAssetModal() {
			this.selected_media_type_id = null;
			this.show_new_asset_modal = false;
		},
		toggleActionTooltip( asset_id ) {
			if ( this.visible_action_tooltip === asset_id ) {
				this.visible_action_tooltip = null;
				return;
			}
			this.visible_action_tooltip = asset_id;
		},
		assetIsThumbnail( asset_id ) {
			if ( !this.project.project_thumbnail || !this.project.project_thumbnail.length ) {
				return false;
			}
			return this.project.project_thumbnail[0].id === asset_id;
		},
		async toggleAssetAsThumbnail( asset_id ) {
			if ( this.assetIsThumbnail( asset_id ) ) {
				await this.unsetProjectThumbnail( asset_id );
				return;
			}
			await this.setAssetAsThumbnail( asset_id );
		},
		async setAssetAsThumbnail( asset_id ) {
			if ( this.setting_thumbnail ) {
				return;
			}
			this.setAlertMessage( null );
			this.setting_thumbnail = asset_id;
			try {
				const response = await this.setProjectThumbnail( {
					project_id: this.project.id,
					asset_id: parseInt( asset_id )
				} );
				if ( response && response.data && response.data.save_projects_default_Entry ) {
					this.$emit('refresh-project');
					this.setAlertMessage( "Project thumbnail updated!" ); // eslint-disable-line quotes
				}
			} catch( error ) {
				this.setAlertMessage( "Something went wrong, please try again" );
				// console.log( error );
			}
			this.setting_thumbnail = null;
		},
		async unsetProjectThumbnail( asset_id ) {
			if ( this.setting_thumbnail ) {
				return;
			}
			this.setAlertMessage( null );
			this.setting_thumbnail = asset_id;
			try {
				const response = await this.setProjectThumbnail( {
					project_id: this.project.id,
					asset_id: null
				} );
				if ( response && response.data && response.data.save_projects_default_Entry ) {
					this.$emit('refresh-project');
					this.setAlertMessage( "Project thumbnail removed." ); // eslint-disable-line quotes
				}
			} catch( error ) {
				this.setAlertMessage( "Something went wrong, please try again" );
				// console.log( error );
			}
			this.setting_thumbnail = null;
		},
		openDeleteAssetModal( asset ) {
			this.asset_to_delete = asset;
			this.show_delete_asset_modal = true;
		},
		closeDeleteAssetModal() {
			this.asset_to_delete = null;
			this.visible_action_tooltip = null;
			this.show_delete_asset_modal = false;
		},
		scrollTo( id ) {
			VueScrollTo.scrollTo( `#${ id }`, {
				container: '#page',
				duration: 500,
				lazy: false
			} );
		},
	}
};
</script>
