
<template>
	<div class="login-page-wrapper">
		<Transition name="fade">
			<PageLoaderComponent
				v-if="loading_page"
			/>
			<div
				v-else
				class="login-page"
			>
				<video
					v-if="login_globals.background_video_url"
					class="login-page__background-video"
					:src="login_globals.background_video_url"
					:poster="login_globals.background_poster_image ? login_globals.background_poster_image.url : null"
					autoplay
					loop
					muted
				/>
				<img
					v-else-if="login_globals.background_poster_image"
					:src="login_globals.background_poster_image.url"
					:alt="login_globals.background_poster_image.alt ?? login_globals.background_poster_image.filename"
					class="login-page__background-image"
				>

				<div class="container login-page__content">
					<router-link
						:to="{ name: 'login' }"
						class="login-page__logo"
					/>

					<div class="login-page__content-inner">
						<div class="grid flex flex--wrap flex--justify-space-between flex--align-center">
							<div
								v-if="login_globals && ( login_globals.title || login_globals.copy )"
								class="grid__item a3-12 c5-12 f6-6"
							>
								<h1 class="h1">
									{{ login_globals.title }}
								</h1>
								<p>
									{{ login_globals.copy }}
								</p>
							</div>

							<div class="grid__item a4-12 c6-12 f6-6">
								<form
									class="login-page__login-form"
									@submit.prevent="submitRequest"
								>
									<div class="mb-24">
										<h2 class="h4">
											Reset password
										</h2>
									</div>
									<div class="form__set">
										<label
											for="email"
											class="form__label"
										>
											Email
										</label>
										<input
											id="email"
											v-model="email"
											class="form__input form__input--stretch"
											type="text"
											name="email"
										>
									</div>

									<p
										v-if="success_message"
									>
										{{ success_message }}
									</p>

									<button
										class="button button--fill button--stretch mt-32 mb-16"
										:class="{ 'button--loading' : loading_reset }"
										type="submit"
									>
										Send reset link
									</button>

									<router-link
										:to="{ name: 'login' }"
										class="a a--underline p--small"
									>
										Back to login
									</router-link>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Transition>
	</div>
</template>

<script>
import PageLoaderComponent from '../components/PageLoaderComponent.vue';
import { useDataStore } from '../stores/data';
import { storeToRefs } from 'pinia';

export default {
	components: {
		PageLoaderComponent
	},
	setup() {
		const data_store = useDataStore();
		const {
			requestPasswordReset,
			getLoginGlobals,
			setAlertMessage
		} = data_store;
		const {
			login_globals,
			user_group
		} = storeToRefs( data_store );

		return {
			requestPasswordReset,
			getLoginGlobals,
			setAlertMessage,
			login_globals,
			user_group
		};
	},
	data() {
		return {
			loading_page: false,
			loading_reset: false,
			success_message: null,
			email: null,
			error_message: null
		};
	},
	async mounted() {
		if ( !this.login_globals ) {
			this.loading_page = true;
			await this.getLoginGlobals();
			this.loading_page = false;
		}
	},
	methods: {
		async submitRequest() {
			this.loading_reset = true;
			this.success_message = null;

			const response = await this.requestPasswordReset( this.email );
			if ( response && response.error ) {
				this.error_message = response.error;
				this.loading_reset = false;
				return;
			}

			this.success_message = response.data.forgottenPassword;
			this.loading_reset = false;
		},
	}
};

</script>
