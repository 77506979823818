<template>
	<div
		class="comment-form"
		:class="[
			{ 'comment-form--alt': commentType === 'discussion' },
			{ 'comment-form--no-border': noBorder }
		]"
	>
		<RichTextEditorComponent
			v-model="content"
			:disabled="loading"
			:placeholder="commentType === 'discussion' ? 'Start writing your comment here...' : 'Leave a comment...'"
		/>

		<CommentFileUploadComponent
			ref="image-upload"
			:comment-id="comment_id"
			@files-added="updateFileStatus"
			@files-removed="updateFileStatus"
			@upload-completed="finishCommentSubmission"
			@upload-failed="finishCommentSubmission( false )"
		/>

		<ul
			v-if="errors.length"
			class="comment-form__errors"
		>
			<li
				v-for="error, index in errors"
				:key="'error-' + index"
				class="comment-form__error"
			>
				{{ error }}
			</li>
		</ul>

		<div
			v-if="!hideButtons"
			class="comment-form__submit-container"
		>
			<button
				class="comment-form__submit"
				:class="{ 'comment-form__submit--loading': loading }"
				:disabled="loading"
				type="submit"
				@click.prevent="submitComment"
			>
				{{ loading ? 'Posting' : 'Post' }}
			</button>
		</div>
	</div>
</template>

<script>
import CommentFileUploadComponent from './uploads/CommentFileUploadComponent.vue';
import RichTextEditorComponent from './RichTextEditorComponent.vue';

import { nextTick } from 'vue';
import { useDataStore } from '../stores/data';

export default {
	components: {
		CommentFileUploadComponent,
		RichTextEditorComponent
	},
	props: {
		optionId: { required: false, type: String, default: '' },
		discussionId: { required: false, type: String, default: '' },
		commentType: { required: false, type: String, default: 'option' },
		hideButtons: { required: false, type: Boolean, default: false },
		noBorder: { required: false, type: Boolean, default: false },
	},
	emits: [ 'comment-posted' ],
	setup() {
		const { postComment } = useDataStore();
		return { postComment };
	},
	data() {
		return {
			content: null,
			loading: false,
			comment_id: null,
			files_pending: 0,
			errors: []
		};
	},
	watch: {
		content( new_val, old_val ) {
			if ( !old_val && new_val ) {
				this.errors = [];
			}
			if ( new_val === '<p></p>' ) {
				this.content = '';
			}
		}
	},
	methods: {
		async submitComment() {
			this.errors = [];
			this.loading = true;
			if ( !this.content ) {
				this.errors[0] = 'Please add some text to your comment';
				this.loading = false;
				return;
			}

			const response = await this.postComment( {
				option_id: parseInt( this.optionId ),
				discussion_id: parseInt( this.discussionId ),
				content: this.content,
				number_of_files_attached: this.files_pending,
				comment_type: this.commentType
			} );
			if ( response.data ) {
				if ( this.commentType === 'option' ) {
					this.comment_id = response.data.save_option_comments_option_comment_Entry.id;
				} else {
					this.comment_id = response.data.save_discussion_comments_discussion_comment_Entry.id;
				}

				if ( this.files_pending ) {
					this.$refs[ 'image-upload' ].submitUpload();
					return;
				}

				this.finishCommentSubmission();
			}
			this.loading = false;
		},
		async finishCommentSubmission( comment_posted = true ) {
			this.comment_id = null;
			this.files_pending = 0;
			this.loading = false;

			await nextTick();
			this.content = null;

			if ( comment_posted ) {
				this.$emit( 'comment-posted' );
			}
		},
		updateFileStatus( files ) {
			this.files_pending = Object.keys( files ).length;
		}
	}
};
</script>
