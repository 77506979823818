<template>
	<div class="approvals">
		<div
			v-if="user_is_staff"
			class="approvals__card"
		>
			<h4 class="approvals__title">
				The Boundary
			</h4>
			<div class="approvals__approval">
				<img
					v-if="option_is_staff_approved && option.staff_approval_user[0].photo"
					:src="option.staff_approval_user[0].photo.url"
					class="approvals__photo"
				>
				<div
					v-else
					class="approvals__photo"
					:class="option_is_staff_approved ? 'approvals__photo--placeholder' : 'approvals__photo--approval-pending'"
				/>

				<div class="approvals__details">
					<p class="approvals__name">
						{{ option_is_staff_approved ? option.staff_approval_user[0].fullName : 'Awaiting approval' }}
					</p>
					<p
						v-if="option_is_staff_approved"
						class="approvals__date"
					>
						Approved on {{ getDate( option.staff_approval_timestamp ) }}
					</p>
				</div>
			</div>
		</div>

		<div
			v-if="option_is_client_approved"
			class="approvals__card"
		>
			<h4 class="approvals__title">
				Client
			</h4>
			<div class="approvals__approval">
				<img
					v-if="option.client_approval_user[0].photo"
					:src="option.client_approval_user[0].photo.url"
					class="approvals__photo"
				>
				<div
					v-else
					class="approvals__photo approvals__photo--placeholder"
				/>

				<div class="approvals__details">
					<p class="approvals__name">
						{{ option.client_approval_user[0].fullName }}
					</p>
					<p class="approvals__date">
						Approved on {{ getDate( option.client_approval_timestamp ) }}
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import moment from 'moment';
import { useDataStore } from '../stores/data';

import {
	USER_GROUP_STAFF
} from '../constants';

export default {
	props: {
		option: { required: true, type: Object }
	},
	setup() {
		const { user_group } = useDataStore();
		return { user_group };
	},
	computed: {
		option_is_staff_approved() {
			if ( !this.option ) {
				return false;
			}
			return this.option.staff_approval_user.length;
		},
		option_is_client_approved() {
			if ( !this.option ) {
				return false;
			}
			return this.option.client_approval_user.length;
		},
		user_is_staff() {
			return this.user_group === USER_GROUP_STAFF;
		}
	},
	methods: {
		getDate( timestamp ) {
			return moment( new Date( timestamp ) ).format( 'D/M/YY' );
		},
	}
};
</script>
