import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "gallery-modal-wrapper"
}
const _hoisted_2 = { class: "gallery-modal" }
const _hoisted_3 = { class: "gallery-modal__header" }
const _hoisted_4 = { class: "gallery-modal__count" }
const _hoisted_5 = { class: "gallery-modal__nav-buttons" }
const _hoisted_6 = ["disabled"]
const _hoisted_7 = ["disabled"]
const _hoisted_8 = {
  key: 0,
  class: "gallery-modal__title"
}
const _hoisted_9 = { class: "gallery-modal__buttons" }
const _hoisted_10 = ["href"]
const _hoisted_11 = ["src"]
const _hoisted_12 = ["src", "alt"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($data.gallery_options && $data.gallery_options.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("p", _hoisted_4, _toDisplayString($options.getCount()), 1 /* TEXT */),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("button", {
                class: _normalizeClass(["gallery-modal__nav-prev", { 'gallery-modal__nav-prev--disabled': $options.first_selected }]),
                disabled: $options.first_selected,
                onClick: _cache[0] || (_cache[0] = (...args) => ($options.viewPrev && $options.viewPrev(...args)))
              }, null, 10 /* CLASS, PROPS */, _hoisted_6),
              _createElementVNode("button", {
                class: _normalizeClass(["gallery-modal__nav-next", { 'gallery-modal__nav-next--disabled': $options.last_selected }]),
                disabled: $options.last_selected,
                onClick: _cache[1] || (_cache[1] = (...args) => ($options.viewNext && $options.viewNext(...args)))
              }, null, 10 /* CLASS, PROPS */, _hoisted_7)
            ]),
            ($options.selected_option.title)
              ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString($options.selected_option.title), 1 /* TEXT */))
              : _createCommentVNode("v-if", true),
            _createElementVNode("div", _hoisted_9, [
              ($props.config.downloadable && $options.selected_option.image_url)
                ? (_openBlock(), _createElementBlock("a", {
                    key: 0,
                    class: "gallery-modal__button",
                    href: $options.selected_option_image_download_url
                  }, " Download ", 8 /* PROPS */, _hoisted_10))
                : _createCommentVNode("v-if", true)
            ]),
            _createElementVNode("button", {
              class: "gallery-modal__close-modal",
              onClick: _cache[2] || (_cache[2] = $event => (_ctx.$emit( 'close-modal' )))
            })
          ]),
          _createElementVNode("div", {
            class: _normalizeClass(["gallery-modal__content", { 'gallery-modal__content--show-sidebar': _ctx.show_comments }])
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(["gallery-modal__content-wrapper", { 'gallery-modal__content-wrapper--full-resolution': $data.show_full_resolution }])
            }, [
              ($options.selected_option.image_url)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    class: _normalizeClass(["gallery-modal__image", { 'gallery-modal__image--full-resolution': $data.show_full_resolution }]),
                    style: _normalizeStyle($options.full_resolution_styles),
                    src: $options.selected_option.image_url,
                    onClick: _cache[3] || (_cache[3] = (...args) => ($options.toggleFullResolution && $options.toggleFullResolution(...args)))
                  }, null, 14 /* CLASS, STYLE, PROPS */, _hoisted_11))
                : ($options.selected_option.video_url)
                  ? (_openBlock(), _createElementBlock("video", {
                      key: 1,
                      class: "gallery-modal__video",
                      src: $options.selected_option.video_url,
                      alt: $options.selected_option.title,
                      autoplay: "",
                      controls: ""
                    }, null, 8 /* PROPS */, _hoisted_12))
                  : _createCommentVNode("v-if", true)
            ], 2 /* CLASS */)
          ], 2 /* CLASS */)
        ])
      ]))
    : _createCommentVNode("v-if", true)
}