
<template>
	<div class="login-page-wrapper">
		<Transition name="fade">
			<PageLoaderComponent
				v-if="loading_page"
			/>
			<div
				v-else
				class="login-page"
			>
				<video
					v-if="login_globals.background_video_url"
					class="login-page__background-video"
					:src="login_globals.background_video_url"
					:poster="login_globals.background_poster_image ? login_globals.background_poster_image.url : null"
					autoplay
					loop
					muted
				/>
				<img
					v-else-if="login_globals.background_poster_image"
					:src="login_globals.background_poster_image.url"
					:alt="login_globals.background_poster_image.alt ?? login_globals.background_poster_image.filename"
					class="login-page__background-image"
				>

				<div class="container login-page__content">
					<div class="login-page__logo" />

					<div class="login-page__content-inner">
						<div class="grid flex flex--wrap flex--justify-space-between flex--align-center">
							<div
								v-if="login_globals && ( login_globals.title || login_globals.copy )"
								class="grid__item a3-12 c5-12 f6-6"
							>
								<h1 class="h1">
									{{ login_globals.title }}
								</h1>
								<p>
									{{ login_globals.copy }}
								</p>
							</div>

							<div class="grid__item a4-12 c6-12 f6-6">
								<form
									class="login-page__login-form"
									@submit.prevent="logIn"
								>
									<div class="mb-24">
										<h2 class="h4">
											Login
										</h2>
									</div>
									<div class="form__set">
										<label
											for="email"
											class="form__label"
										>
											Email
										</label>
										<input
											id="email"
											v-model="email"
											class="form__input form__input--stretch"
											type="email"
											name="email"
										>
									</div>

									<div class="form__set">
										<label
											for="password"
											class="form__label"
										>
											Password
										</label>
										<input
											id="password"
											v-model="password"
											class="form__input form__input--stretch"
											type="password"
											name="password"
										>
									</div>

									<p
										v-if="error_message"
									>
										{{ error_message }}
									</p>

									<button
										class="button button--fill button--stretch mt-32 mb-16"
										:class="{ 'button--loading' : loading_login }"
										type="submit"
									>
										Log in
									</button>

									<router-link
										:to="{ name: 'request-password-reset' }"
										class="a a--underline p--small"
									>
										Forgot your password?
									</router-link>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Transition>
	</div>
</template>

<script>
import PageLoaderComponent from '../components/PageLoaderComponent.vue';
import { useDataStore } from '../stores/data';
import { storeToRefs } from 'pinia';
import { USER_GROUP_STAFF } from '../constants';

export default {
	components: {
		PageLoaderComponent
	},
	setup() {
		const data_store = useDataStore();
		const {
			login,
			getLoginGlobals,
			setAlertMessage,
			resetNotificationFilters
		} = data_store;
		const {
			login_globals,
			user_group
		} = storeToRefs( data_store );

		return {
			login_globals,
			login,
			getLoginGlobals,
			setAlertMessage,
			resetNotificationFilters,
			user_group
		};
	},
	data() {
		return {
			loading_page: true,
			loading_login: false,
			email: null,
			password: null,
			error_message: null
		};
	},
	async mounted() {
		await this.getLoginGlobals();
		this.loading_page = false;
		if ( this.$route.query && this.$route.query.expired ) {
			this.setAlertMessage( 'Your session has expired, please log in.' );
		}
	},
	methods: {
		async logIn() {
			this.loading_login = true;
			this.error_message = null;
			this.setAlertMessage( null );

			const response = await this.login( { email: this.email, password: this.password} );
			if ( response && response.error ) {
				this.error_message = response.error;
				this.loading_login = false;
				return;
			}

			this.loading_login = false;
			this.$emit( 'logged-in' );

			this.resetNotificationFilters();

			if ( this.user_group === USER_GROUP_STAFF ) {
				this.$router.push( { name: 'home' } );
				return;
			}
			this.$router.push( { name: 'projects' } );
		},
	}
};

</script>
