import axios from 'axios';

class CraftGraphqlApiClient {
	constructor( base_url ) {
		this.client = axios.create( {
			baseURL: base_url,
			timeout: 10000,
			//headers: {'X-Custom-Header': 'foobar'}
		} );
	}

	async query( query, variables, headers = {} ) {
		return this.client.post( '', { query: query, variables: variables }, { headers: headers } )
			.then( ( response ) => {
				return response.data;
			} )
			.catch( ( error ) => {
				// if ( error.response ) {
				// 	console.log( error.response ); // eslint-disable-line no-console
				// } else if ( error.request ) {
				// 	console.log( error.request ); // eslint-disable-line no-console
				// } else {
				// 	console.log( 'Error', error.message ); // eslint-disable-line no-console
				// }
				// console.log( error.config ); // eslint-disable-line no-console
			} );
	}
}

export default new CraftGraphqlApiClient( '/api' );
