<template>
	<div class="grid flex flex--wrap flex--justify-space-between flex--align-center">
		<div class="grid__item a4-12 c6-12 f6-6">
			<form
				@submit.prevent="submitForm"
			>
				<div class="form__set">
					<label
						for="current_password"
						class="form__label"
					>
						Current password
					</label>
					<input
						id="current_password"
						v-model="form_fields.current_password"
						class="form__input form__input--stretch"
						type="password"
						name="current_password"
					>
				</div>

				<div class="form__set">
					<label
						for="new_password"
						class="form__label"
					>
						New password
					</label>
					<input
						id="new_password"
						v-model="form_fields.new_password"
						class="form__input form__input--stretch"
						type="password"
						name="new_password"
					>
				</div>

				<div class="form__set">
					<label
						for="confirm_password"
						class="form__label"
					>
						Confirm new password
					</label>
					<input
						id="confirm_password"
						v-model="form_fields.confirm_password"
						class="form__input form__input--stretch"
						type="password"
						name="confirm_password"
					>
				</div>

				<p
					v-if="this.error"
					class="form__error"
				>
					{{  this.error }}
				</p>
				<p
					v-if="this.success"
					class="form__success"
				>
					{{  this.success }}
				</p>

				<button
					class="button button--fill mt-32"
					:class="{ 'button--loading' : loading }"
					type="submit"
				>
					Save
				</button>
			</form>
		</div>
	</div>
</template>

<script>
import { useDataStore } from '../stores/data';
import { storeToRefs } from 'pinia';

export default {
	setup() {
		const data_store = useDataStore();
		const { updatePassword } = data_store;

		return { updatePassword };
	},
	data() {
		return {
			loading: false,
			form_fields: {
				current_password: '',
				new_password: '',
				confirm_password: ''
			},
			error: null,
			success: null
		}
	},
	methods: {
		async submitForm() {
			this.error = null;
			this.success = null;
			this.loading = true;

			if ( this.form_fields.current_password === this.form_fields.new_password ) {
				this.error = 'New password cannot be the same as current password';
				this.loading = false;
				return;
			}
			if ( this.form_fields.new_password !== this.form_fields.confirm_password ) {
				this.error = "New passwords don't match";
				this.loading = false;
				return;
			}

			try {
				const response = await this.updatePassword( {
					current_password: this.form_fields.current_password,
					new_password: this.form_fields.new_password,
					confirm_password: this.form_fields.confirm_password
				} );

				if ( response.data && response.data.updatePassword ) {
					this.success = 'Successfully updated password';
					this.form_fields = {
						current_password: '',
						new_password: '',
						confirm_password: ''
					};
				}
			} catch ( error ) {
				this.error = error;
			}

			this.loading = false;
		}
	}
}
</script>
