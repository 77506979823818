<template>
	<TransitionGroup
		name="list"
		tag="ul"
	>
		<NotificationComponent
			v-for="notification in notifications"
			:key="notification.id"
			:notification="notification"
			@mark-as-read="id => $emit( 'mark-as-read', id )"
		/>
	</TransitionGroup>
</template>

<script>
import NotificationComponent from './NotificationComponent.vue';

export default {
	components: {
		NotificationComponent
	},
	props: {
		notifications: { required: true, type: Array }
	},
	emits: [ 'mark-as-read' ],
	data() {
		return {
			active_attachments: [],
			show_attachment_modal: false
		};
	}
};
</script>
