<template>
	<div
		id="image-upload"
		class="comment-form__drag-drop flex"
		:class="{ 'comment-form__drag-drop--hover': hover }"
	>
		<drag-drop
			v-if="uppy"
			:uppy="uppy"
			:props="uppy_config"
		/>
		<TransitionGroup
			name="list"
			tag="ul"
			class="comment-form__files"
		>
			<li
				v-for="( file, file_id ) in files"
				:key="file_id"
				class="comment-form__file"
			>
				<img
					v-if="file.preview"
					class="comment-form__thumbnail"
					:src="file.preview"
					:alt="file.filename"
				>
				<div
					v-else-if="file.extension === 'pdf'"
					class="comment-form__thumbnail comment-form__thumbnail--pdf"
				/>
				<p class="comment-form__filename">
					{{ file.filename }}
				</p>
				<button
					class="comment-form__remove-file"
					@click="removeFile( file_id )"
				/>
			</li>
		</TransitionGroup>
	</div>
</template>

<script>
import Uppy from '@uppy/core';
import Transloadit from '@uppy/transloadit';
import ThumbnailGenerator from '@uppy/thumbnail-generator';

import { DragDrop } from '@uppy/vue';

import moment from 'moment';

import { useDataStore } from '../../stores/data';

import { convertBytesToSize } from '../../../helpers';

export default {
	name: 'ImageUploadComponent',
	components: {
		DragDrop
	},
	inject: [
		'tl_key',
		'tl_attachment_template_id',
		'tl_comment_discussion_template_id'
	],
	props: {
		commentId: { required: false, type: String, default: null },
		commentType: { required: false, type: String, default: 'option' }
	},
	emits: [ 'upload-completed', 'upload-failed', 'files-added', 'files-removed' ],
	setup() {
		const { deleteOptionComment, deleteDiscussionComment } = useDataStore();
		return { deleteOptionComment, deleteDiscussionComment };
	},
	data() {
		return {
			hover: false,
			uppy: null,
			uppy_config: {
				height: '100%',
				width: '100%',
				locale: {
					strings: {
						poweredBy: 'The Boundary Live',
						dropHereOr: 'Drag & Drop or select files from your computer',
					}
				},
				onDragOver() {
					this.hover = true;
				},
				onDragLeave() {
					this.hover = false;
				}
			},
			files: {},
			errors: {},
			expiry_time: null,
			signature: null,
			allowed_file_types: [
				'.jpg',
				'.jpeg',
				'.pdf',
				'.docx',
				'.doc',
				'.blend',
				'.png',
				'.zip',
				'.rar',
				'.7z',
				'.rvt',
				'.dwg',
				'.3ds',
				'.max',
				'.3dm',
				'.skp',
				'.rfa',
				'.nwd',
				'.dng',
				'.dgn',
				'.tiff',
				'.tif',
				'.pptx',
				'.rtf',
				'.fbx',
				'.cr2',
				'.obj',
				'.ppt',
				'.mp4',
				'.mov',
				'.txt',
				'.dxf',
				'.ai',
				'.psd',
				'.indd'
			]
		};
	},
	computed: {
		uppy_params() {
			return {
				auth: {
					key: this.tl_key,
					expires: this.expiry_time
				},
				template_id: this.tl_attachment_template_id
			};
		},
		uppy_files() {
			if ( !this.uppy ) {
				return [];
			}
			return this.uppy.getFiles();
		},
		assembly_options() {
			return ( file ) => {
				return {
					fields: {
						filename: this.files[file.id].filename,
						comment_id: this.commentId
					},
					waitForEncoding: false,
					params: this.uppy_params,
					signature: `sha1:${this.signature}`
				};
			};
		}
	},
	mounted() {
		this.getSigningString();
	},
	methods: {
		async getSigningString() {
			this.expiry_time = moment.utc().add( 2, 'h' ).format( 'YYYY/MM/DD HH:mm:ss+00:00' );
			try {
				const response = await this.$axios.post( '/upload/sign', {
					'params': JSON.stringify( this.uppy_params )
				} );
				this.signature = response.data;
				this.instantiateUppy();
			} catch ( error ) {
				// console.log( error );
			}
		},
		instantiateUppy() {
			this.uppy = new Uppy( {
				onBeforeFileAdded: this.addFile,
				restrictions: {
					allowedFileTypes: this.allowed_file_types
				}
			} );
			this.uppy.use( ThumbnailGenerator, {
				thumbnailWidth: 120,
				waitForThumbnailsBeforeUpload: true,
			} );
			this.uppy.use( Transloadit, {
				getAssemblyOptions: this.assembly_options
			} );
			this.uppy.on( 'thumbnail:generated', ( file, preview ) => {
				this.files[file.id].preview = preview;
			} );
			this.uppy.on( 'file-added', () => {
				this.$emit( 'files-added', this.files );
			} );
			this.uppy.on( 'file-removed', ( file ) => {
				if ( file.id in this.files ) {
					delete this.files[file.id];
					this.$emit( 'files-removed', this.files );
				}
			} );
			this.uppy.on( 'complete', () => {
				this.files = {};
			} );
		},
		addFile( new_file ) {
			this.files[new_file.id] = {
				preview: new_file.preview,
				filename: new_file.name,
				extension: new_file.extension
			};
		},
		removeFile( file_id ) {
			this.uppy.removeFile( file_id );
		},
		getTotalFileSize( files ) {
			const total_bytes = files
				.map( file => parseInt( file.size ) )
				.reduce( ( prev, cur ) => prev + cur, 0 );

			return convertBytesToSize( total_bytes );
		},
		async submitUpload() {
			this.errors = {};
			this.uppy.upload()
				.then( () => {
					this.$emit( 'upload-completed' );
				} )
				.catch( async() => {
					await this.deleteOptionComment( parseInt( this.commentId ) );
					this.clearUppy();
					this.$emit( 'upload-failed' );
				} );
		},
		clearUppy() {
			this.uppy.cancelAll();
			this.files = {};
		}
	}
};
</script>