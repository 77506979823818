import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["CheckboxesDropdownComponent"], {
    id: "project-status",
    label: "Project Status",
    placeholder: "Search",
    options: $setup.project_status.statuses,
    selection: $setup.active_statuses,
    stretch: true,
    "search-disabled": true,
    onSelect: $setup.handleStatusSelect
  }, null, 8 /* PROPS */, ["options", "selection"]))
}