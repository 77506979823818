
<template>
	<div class="flex flex--gap-large">
		<button
			class="sort-button"
			:class="[
				project_sorting.title_sort_order === 'DESC' ? 'sort-button--desc' : 'sort-button--asc',
				{ 'sort-button--selected': project_sorting.selected_sort_order === 'title' }
			]"
			@click="toggleTitleSortOrder"
		>
			<span class="sort-button__label">
				Name
			</span>
		</button>
		<button
			class="sort-button"
			:class="[
				project_sorting.date_sort_order === 'DESC' ? 'sort-button--desc' : 'sort-button--asc',
				{ 'sort-button--selected': project_sorting.selected_sort_order === 'date' }
			]"
			@click="toggleDateSortOrder"
		>
			<span class="sort-button__label">
				Updated
			</span>
		</button>
	</div>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useProjectFiltersStore } from '../../stores/project-filters';

const filters_store = useProjectFiltersStore();

const {
	setProjectSorting,
	resetProjectPagination,
	updateQueryStringFromProjectFilters
} = filters_store;

const { project_sorting } = storeToRefs( filters_store );

// Sort order
const toggleDateSortOrder = () => {
	resetProjectPagination();
	const sort_order = project_sorting.value.selected_sort_order === 'title' ? 'DESC' : project_sorting.value.date_sort_order === 'DESC' ? 'ASC' : 'DESC';
	setProjectSorting( {
		selected_sort_order: 'date',
		title_sort_order: 'DESC',
		date_sort_order: sort_order
	} );
	updateQueryStringFromProjectFilters();
};

const toggleTitleSortOrder = () => {
	resetProjectPagination();
	const sort_order = project_sorting.value.selected_sort_order === 'date' ? 'DESC' : project_sorting.value.title_sort_order === 'DESC' ? 'ASC' : 'DESC';
	setProjectSorting( {
		selected_sort_order: 'title',
		title_sort_order: sort_order,
		date_sort_order: 'DESC'
	} );
	updateQueryStringFromProjectFilters();
};
</script>