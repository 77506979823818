<template>
	<CheckboxesDropdownComponent
		id="landscape-architect"
		label="Landscape Architect"
		placeholder="Search"
		:query="landscape_architect.search_query"
		:options="filtered_landscape_architects"
		:selection="active_landscape_architect_ids"
		:stretch="true"
		:loading="loading"
		@select="handleArchitectSelect"
		@search="handleArchitectSearchInputChange"
	/>
</template>

<script setup>
import CheckboxesDropdownComponent from '../CheckboxesDropdownComponent.vue';

import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useProjectFiltersStore } from '../../stores/project-filters';

const props = defineProps( {
	loading: {
		required: false,
		type: Boolean,
		default: false
	}
} );

const filters_store = useProjectFiltersStore();

const {
	setFilterSearchQuery,
	setActiveFilterValue,
	removeActiveFilterValue,
	resetProjectPagination,
	updateQueryStringFromProjectFilters,
} = filters_store;

const { landscape_architect, filtered_landscape_architects } = storeToRefs( filters_store );

const active_landscape_architect_ids = computed( () => {
	return landscape_architect.value.active.map( company => company.value );
} );

const handleArchitectSelect = ( company ) => {
	company.checked ? setActiveFilterValue( company, 'landscape_architect' ) : removeActiveFilterValue( company, 'landscape_architect' );
	resetProjectPagination();
	updateQueryStringFromProjectFilters();
};

const handleArchitectSearchInputChange = async ( value ) => {
	setFilterSearchQuery( value, 'landscape_architect' );
};
</script>