import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "slideshow-modal-wrapper"
}
const _hoisted_2 = {
  ref: "modal-header",
  class: "slideshow-modal__header"
}
const _hoisted_3 = {
  key: 0,
  class: "slideshow-modal__title"
}
const _hoisted_4 = { class: "slideshow-modal__content" }
const _hoisted_5 = { class: "slideshow-modal__content-wrapper" }
const _hoisted_6 = ["src"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($props.slideshow && $options.slideshow_url)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          ref: "modal",
          class: "slideshow-modal",
          style: _normalizeStyle($data.size_styles)
        }, [
          _createElementVNode("div", _hoisted_2, [
            ($props.slideshow.title)
              ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString($props.slideshow.title), 1 /* TEXT */))
              : _createCommentVNode("v-if", true),
            _createElementVNode("button", {
              class: "slideshow-modal__close-modal",
              onClick: _cache[0] || (_cache[0] = $event => (_ctx.$emit( 'close-modal' )))
            })
          ], 512 /* NEED_PATCH */),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("iframe", {
                ref: "slides",
                class: "slideshow-modal__slideshow",
                src: $options.slideshow_url,
                frameborder: "0",
                allowfullscreen: "true",
                mozallowfullscreen: "true",
                webkitallowfullscreen: "true"
              }, null, 8 /* PROPS */, _hoisted_6)
            ])
          ])
        ], 4 /* STYLE */)
      ]))
    : _createCommentVNode("v-if", true)
}