import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "rich-text-editor"
}
const _hoisted_2 = { class: "rich-text-editor__menu" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_EditorContent = _resolveComponent("EditorContent")

  return ($data.editor)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("button", {
            class: _normalizeClass(["rich-text-editor__bold", { 'rich-text-editor__bold--active': $data.editor.isActive( 'bold' ) }]),
            onClick: _cache[0] || (_cache[0] = (...args) => ($options.toggleBold && $options.toggleBold(...args)))
          }, null, 2 /* CLASS */),
          _createElementVNode("button", {
            class: _normalizeClass(["rich-text-editor__italic", { 'rich-text-editor__italic--active': $data.editor.isActive( 'italic' ) }]),
            onClick: _cache[1] || (_cache[1] = (...args) => ($options.toggleItalic && $options.toggleItalic(...args)))
          }, null, 2 /* CLASS */),
          _createElementVNode("button", {
            class: _normalizeClass(["rich-text-editor__list", { 'rich-text-editor__list--active': $data.editor.isActive( 'bulletList' ) }]),
            onClick: _cache[2] || (_cache[2] = (...args) => ($options.toggleList && $options.toggleList(...args)))
          }, null, 2 /* CLASS */),
          _createElementVNode("button", {
            class: _normalizeClass(["rich-text-editor__link", { 'rich-text-editor__link--active': $data.editor.isActive( 'link' ) }]),
            onClick: _cache[3] || (_cache[3] = (...args) => ($options.toggleLink && $options.toggleLink(...args)))
          }, null, 2 /* CLASS */)
        ]),
        _createVNode(_component_EditorContent, {
          editor: $data.editor,
          class: "rich-text-editor__content"
        }, null, 8 /* PROPS */, ["editor"])
      ]))
    : _createCommentVNode("v-if", true)
}