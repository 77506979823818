<template>
	<div class="discussion-modal-wrapper">
		<div class="discussion-modal">
			<button
				class="discussion-modal__close"
				aria-label="Close modal"
				@click="closeModal"
			/>
			<div class="discussion-modal__content">
				<h2 class="discussion-modal__heading">
					Start a new discussion
				</h2>

				<div class="form__set mb-16">
					<label
						for="new_tag"
						class="form__label"
					>
						Subject
					</label>
					<input
						id="discussion_subject"
						v-model="discussion_subject"
						class="form__input form__input--stretch"
						type="text"
						name="discussion_subject"
						placeholder="Type the subject of the message..."
						:disabled="loading"
						@keyup.enter="submitNewTag"
					>
				</div>

				<CommentFormComponent
					ref="comment-form"
					:discussion-id="discussion.id"
					:hide-buttons="true"
					comment-type="discussion"
					@comment-posted="finishDiscussionCreation"
				/>

				<p
					v-if="error_message"
					class="error-message"
				>
					{{ error_message }}
				</p>

				<div class="discussion-modal__buttons">
					<button
						class="button"
						@click="closeModal"
					>
						Cancel
					</button>
					<button
						class="button button--fill"
						:class="{ 'button--loading' : loading }"
						:disabled="!discussion_subject || !comment_has_content"
						@click="createNewDiscussion"
					>
						Post
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import CommentFormComponent from '../CommentFormComponent.vue';

import { nextTick } from 'vue';
import { useDataStore } from '../../stores/data';

export default {
	components: {
		CommentFormComponent
	},
	emits: [ 'confirm', 'close-modal', 'discussion-created' ],
	setup() {
		const data_store = useDataStore();
		const { createDiscussion } = data_store;
		return { createDiscussion };
	},
	data() {
		return {
			loading: false,
			discussion_subject: '',
			discussion: false,
			error_message: null
		};
	},
	computed: {
		comment_has_content() {
			return this.$refs['comment-form'].content && this.$refs['comment-form'].content.length > 0;
		}
	},
	methods: {
		closeModal() {
			this.$emit( 'close-modal' );
		},
		async createNewDiscussion() {
			this.error_message = null;
			this.loading = true;

			if ( !this.discussion_subject || !this.comment_has_content ) {
				this.loading = false;
				return;
			}

			try {
				const response = await this.createDiscussion( {
					project_id: parseInt( this.$route.params.project_id ),
					subject: this.discussion_subject
				} );
				if ( response.data.save_project_discussions_default_Entry ) {
					this.discussion = response.data.save_project_discussions_default_Entry;
					await nextTick();
					this.$refs[ 'comment-form' ].submitComment();
				}
			} catch ( error ) {
				this.error_message = error;
				this.loading = false;
			}
		},
		finishDiscussionCreation() {
			this.$emit( 'discussion-created', this.discussion );
		}
	}
};
</script>