import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "alert-banner__content" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["alert-banner", { 'alert-banner--reveal': $setup.alert_message }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("p", null, _toDisplayString($setup.alert_message), 1 /* TEXT */)
    ]),
    _createElementVNode("button", {
      class: "alert-banner__close",
      onClick: _cache[0] || (_cache[0] = (...args) => ($options.closeBanner && $options.closeBanner(...args)))
    })
  ], 2 /* CLASS */))
}