import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, Transition as _Transition, withCtx as _withCtx, Teleport as _Teleport, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "flex flex--justify-space-between flex--align-center flex--gap-extra-large mb-16" }
const _hoisted_2 = { class: "project-filters mb-16" }
const _hoisted_3 = { class: "project-filters__row" }
const _hoisted_4 = { class: "project-filters__column project-filters__column--space-between" }
const _hoisted_5 = {
  key: 0,
  class: "grid grid--stretch grid--xs flex flex--wrap full-width mb-16"
}
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "project-filters__column" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode($setup["ProjectSearchFilterComponent"]),
      ($setup.active_projects_tab === 'all')
        ? (_openBlock(), _createBlock($setup["ProjectSortingFilterComponent"], { key: 0 }))
        : _createCommentVNode("v-if", true)
    ]),
    ($setup.user_is_staff)
      ? (_openBlock(), _createBlock(_Transition, {
          key: 0,
          name: "fade",
          persisted: ""
        }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  ($setup.selected_dropdown_filter_components.length)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.selected_dropdown_filter_components, (filter) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: 'dropdown-filter-component-' + filter.value,
                            class: "grid__item a4-12 c6-12 mb-8"
                          }, [
                            (_openBlock(), _createBlock(_resolveDynamicComponent(filter.component)))
                          ]))
                        }), 128 /* KEYED_FRAGMENT */))
                      ]))
                    : (_openBlock(), _createElementBlock("p", _hoisted_6, " No filters selected ")),
                  _createElementVNode("button", {
                    class: "button mb-16",
                    onClick: _cache[0] || (_cache[0] = $event => ($setup.show_filter_modal = true))
                  }, " Choose Filters "),
                  (_openBlock(), _createBlock(_Teleport, { to: "#modal" }, [
                    _createVNode(_Transition, { name: "fade" }, {
                      default: _withCtx(() => [
                        ($setup.show_filter_modal)
                          ? (_openBlock(), _createBlock($setup["FilterModalComponent"], {
                              key: 0,
                              onCloseModal: _cache[1] || (_cache[1] = $event => ($setup.show_filter_modal = false))
                            }))
                          : _createCommentVNode("v-if", true)
                      ]),
                      _: 1 /* STABLE */
                    })
                  ])),
                  _createVNode($setup["ActiveDropdownFiltersComponent"])
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createVNode($setup["ProjectTagsFilterComponent"])
                ])
              ])
            ], 512 /* NEED_PATCH */), [
              [_vShow, $setup.active_projects_tab !== 'example_projects' && $setup.show_filters]
            ])
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}