<template>
	<Transition name="fade">
		<div
			v-if="submission.options && submission.options.length"
			class="grid grid--xs flex flex--wrap mb-32"
		>
			<div
				v-for="option, index in submission.options"
				:key="'option-' + option.id"
				class="grid__item a6-12 d6-6 mb-8"
			>
				<div
					class="image-grid-card image-grid-card--submission"
					@click="openModal( index )"
				>
					<img
						:src="option.image_small_url || option.thumbnail_url"
						:alt="option.title"
						class="image-grid-card__image"
					>
					<div
						class="image-grid-card__info"
						:class="{ 'image-grid-card__info--with-revision': option.related_revision }"
					>
						<p class="image-grid-card__title">
							{{ option.title }}
						</p>
						<div
							v-if="option.related_revision"
							class="image-grid-card__details-container"
						>
							<div class="image-grid-card__details">
								<span
									:class="[
										'image-grid-card__stage',
										getRevisionPipClass( option )
									]"
								>
									{{ getRevisionPipNumber( option ) }}
								</span>
							</div>
						</div>
					</div>
					<button
						v-if="option.image_small_url"
						class="image-grid-card__checkbox"
						:class="{ 'image-grid-card__checkbox--checked': option.checked }"
						:aria-label="option.checked ? `Select image for download` : `Deselect image for download`"
						@click.stop="toggleOptionCheck( index )"
					/>
				</div>
			</div>
		</div>
	</Transition>

	<Teleport to="#modal">
		<Transition name="fade">
			<CommentsGalleryModalComponent
				v-if="modal_options && modal_options.length && show_modal"
				ref="comments-modal"
				:options="modal_options"
				@close-modal="closeModal"
			/>
		</Transition>
	</Teleport>
</template>

<script>
import CommentsGalleryModalComponent from './modals/CommentsGalleryModalComponent.vue';
import { nextTick } from 'vue';

export default {
	components: {
		CommentsGalleryModalComponent
	},
	props: {
		submission: {
			required: true,
			type: Array
		},
		project: {
			required: true,
			type: Object
		}
	},
	data() {
		return {
			show_modal: false,
			modal_options: null
		};
	},
	async mounted() {
		if ( this.$route.query.option ) {
			this.openModal( this.submission.options.indexOf( this.submission.options.find( option => parseInt( option.id ) === parseInt( this.$route.query.option ) ) ) );
			await nextTick();
			if ( this.$route.query.show_comments ) {
				this.$refs['comments-modal'].showComments();
			}
		}
	},
	methods: {
		toggleOptionCheck( index ) {
			this.submission.options[index].checked = !this.submission.options[index].checked;
		},
		openModal( index ) {
			this.modal_options = [ ...this.submission.options ].map( ( option, i ) => {
				return {
					title: option.title,
					option_id: option.id,
					image_url: option.image_large_url ?? null,
					video_url: option.video_url ?? null,
					selected: index === i,
					router_link: {
						name: 'projects__project__asset',
						params: {
							project_id: this.project.id,
							asset_id: option.related_asset ? option.related_asset.id : null,
						},
						query: {
							revision: option.related_revision ? option.related_revision.id : null,
							option: option.id
						}
					}
				};
			} );
			this.show_modal = true;
		},
		closeModal() {
			this.show_modal = false;
			this.modal_options = null;
		},
		getRevisionPipClass( option ) {
			const related_asset = option.related_asset ?? null;
			const related_revision = option.related_revision ?? null;
			const is_staff_approved = option.staff_approval_user && option.staff_approval_user.length;

			if ( !is_staff_approved ) {
				return 'image-grid-card__stage--unapproved';
			}

			const final_revision = related_asset.final_revision && related_asset.final_revision.length ? related_asset.final_revision[0] : null;

			if ( final_revision && final_revision.id == related_revision.id ) {
				return 'image-grid-card__stage--final';
			}

			const first_char = related_revision.revision_number[0];

			switch ( first_char ) {
				case 'A':
					return 'image-grid-card__stage--stage-a';
				case 'B':
					return 'image-grid-card__stage--stage-b';
			}
		},
		getRevisionPipNumber( option ) {
			const related_asset = option.related_asset ?? null;
			const related_revision = option.related_revision ?? null;
			if ( !related_revision || !related_asset ) {
				return '';
			}

			const final_revision = related_asset.final_revision && related_asset.final_revision.length ? related_asset.final_revision[0] : null;

			if ( final_revision && final_revision.id == related_revision.id ) {
				return 'C';
			}


			return related_revision.revision_number;
		}
	}
};
</script>
