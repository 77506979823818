<template>
	<div class="new-asset-modal-wrapper">
		<div class="new-asset-modal">
			<button
				class="new-asset-modal__close"
				aria-label="Close modal"
				@click="closeModal"
			/>
			<div class="new-asset-modal__content">
				<h2 class="new-asset-modal__heading">
					Add asset
				</h2>

				<p class="mb-16">Please name your asset e.g. EXT13_Pool Deck</p>

				<div class="form__set">
					<label
						for="new_tag"
						class="form__label form__label--large form__label--emphasised"
					>
						Code
					</label>
					<span class="form__label form__label--emphasised">
						Select the code prefix and enter a number (letters allowed if required e.g. 13A)
					</span>

					<div class="flex flex--gap-medium">
						<div class="form__icon form__icon--down">
							<select
								v-model="code_prefix"
								id="code-prefix"
								class="form__select"
								:class="{ 'form__select--error': errors.code_prefix }"
							>
								<option
									value=""
									disabled
									selected
								>
									Select
								</option>
								<option
									v-for="prefix in possible_code_prefixes"
									:key="`prefix-${ prefix }`"
									:value="prefix"
									:disabled="loading"
								>
									{{ prefix }}
								</option>
							</select>
						</div>

						<div
							class="form__set form__set--no-margin"
							:class="{ 'form__set--error': errors.code_number }"
						>
							<input
								id="code-number"
								:value="code_number"
								class="form__input"
								type="text"
								name="title"
								placeholder=""
								:disabled="loading"
								@input="validateCodeInput"
							>
						</div>
					</div>
				</div>

				<div
					class="form__set"
					:class="{ 'form__set--error': errors.title }"
				>
					<label
						for="new_tag"
						class="form__label form__label--large form__label--emphasised"
					>
						Title
					</label>
					<span class="form__label form__label--emphasised">
						Enter a descriptive title for the asset. Letters and numbers only e.g. Apartment 2 Dining Room
					</span>
					<input
						id="title"
						:value="title"
						class="form__input form__input--stretch"
						type="text"
						name="title"
						placeholder=""
						:disabled="loading"
						@input="validateTitleInput"
					>
				</div>

				<p
					v-if="error_message"
					class="error-message"
				>
					{{ error_message }}
				</p>

				<div class="flex flex--align-end flex--gap-large">
					<div class="new-asset-modal__buttons mb-16">
						<button
							class="button"
							:disabled="loading"
							@click="closeModal"
						>
							Cancel
						</button>
						<button
							class="button button--fill"
							:class="{ 'button--loading' : loading }"
							:disabled="loading"
							@click="createNewAsset"
						>
							Add
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { useDataStore } from '../../stores/data';

export default {
	props: {
		project: {
			required: true,
			type: Object
		},
		mediaTypeId: {
			required: true,
			type: Number
		},
	},
	emits: [ 'asset-added', 'close-modal' ],
	setup() {
		const data_store = useDataStore();
		const { createAsset } = data_store;
		return { createAsset };
	},
	data() {
		return {
			loading: false,
			possible_code_prefixes: [ 'EXT', 'INT', 'VIG', 'CIN', 'ANI', 'SHOT' ],
			code_prefix: '',
			code_number: '',
			title: '',
			errors: {},
			error_message: null
		};
	},
	methods: {
		closeModal() {
			this.$emit( 'close-modal' );
		},
		validateCodeInput( e ) {
			const prev_value = this.code_number;
			this.code_number = null;

			const regex = /^[A-Z0-9]*$/;
			const input = e.target.value.toUpperCase();

			if ( regex.test( input ) ) {
				this.code_number = input;
			} else {
				this.code_number = prev_value;
			}
		},
		validateTitleInput( e ) {
			const prev_value = this.title;
			this.title = null;

			const regex = /^[a-zA-Z0-9\ \-]*$/;
			const input = e.target.value;

			if ( regex.test( input ) ) {
				this.title = input;
			} else {
				this.title = prev_value;
			}
		},
		createAssetTitle() {
			return this.code_prefix + this.code_number + '_' + this.title
		},
		async createNewAsset() {
			this.errors = {};
			this.error_message = null;
			this.loading = true;

			if ( !this.code_prefix ) {
				this.errors.code_prefix = 'Please choose a code prefix';
			}
			if ( !this.code_number ) {
				this.errors.code_number = 'Please enter a code';
			}
			if ( !this.title ) {
				this.errors.title = 'Please enter a title';
			}

			if ( Object.keys( this.errors ).length > 0 ) {
				this.error_message = 'Please make sure all fields are filled in';
				this.loading = false;
				return;
			}

			try {
				const new_asset = await this.createAsset( {
					project: this.project,
					asset_title: this.createAssetTitle(),
					media_type_id: this.mediaTypeId
				} );
				if ( new_asset ) {
					this.$emit( 'asset-added', {
						new_asset,
						media_type_id: this.mediaTypeId
					} );
				}
			} catch ( error ) {
				this.error_message = error;
				this.loading = false;
			}
		}
	}
};
</script>