<template>
	<TransitionGroup
		name="list"
		tag="ul"
		class="list-container discussions"
	>
		<li
			v-for="discussion in discussions"
			:key="discussion.id"
			class="discussion-card"
			@click="$emit( 'set-active-discussion', discussion.id )"
		>
			<template v-if="discussion.type === 'discussion'">
				<div class="discussion-card__header">
					<div class="discussion-card__title-wrapper">
						<h2 class="discussion-card__title">
							{{ discussion.title }}
						</h2>

						<div class="discussion-card__comment-details">
							<span class="discussion-card__type-badge discussion-card__type-badge--discussion">
								{{ discussion.type }}
							</span>
							<p class="discussion-card__comment-count">
								{{	getCommentCount( parseInt( discussion.total_comment_count ) ) }}
							</p>
							<p
								v-if="parseInt( discussion.total_attachment_count ) > 0"
								class="discussion-card__attachment-count"
							>
								{{	getAttachmentCount( parseInt( discussion.total_attachment_count ) ) }}
							</p>
						</div>
					</div>
					<p class="discussion-card__date">
						{{ getDate( discussion.latest_comment_date || discussion.postDate ) }}
					</p>
				</div>
				<div
					v-if="discussion.latest_comment_author"
					class="discussion-card__last-comment"
				>
					<div class="discussion-card__last-comment-image">
						<ProfilePictureComponent
						:user="discussion.latest_comment_author"
						size="xs"
						/>
					</div>
					<div class="discussion-card__last-comment-content">
						<h3 class="discussion-card__last-comment-heading">
							Last comment by {{  discussion.latest_comment_author.fullName }}
						</h3>
						<div
							v-html="discussion.latest_comment_content"
							class="discussion-card__last-comment-body"
						/>
					</div>
				</div>
			</template>

			<router-link
				v-else-if="discussion.type === 'asset'"
				:to="discussion.action_url">
				<div class="discussion-card__header">
					<div class="discussion-card__title-wrapper">
						<h2 class="discussion-card__title">
							{{ discussion.title }}
						</h2>

						<div class="discussion-card__comment-details">
							<span class="discussion-card__type-badge discussion-card__type-badge--asset">
								{{ discussion.type }}
							</span>
							<p class="discussion-card__comment-count">
								{{	getCommentCount( discussion.total_comment_count ) }}
							</p>
							<p
								v-if="parseInt( discussion.total_attachment_count ) > 0"
								class="discussion-card__attachment-count"
							>
								{{	getAttachmentCount( discussion.total_attachment_count ) }}
							</p>
						</div>
					</div>
					<p class="discussion-card__date">
						{{ getDate( discussion.latest_comment_date || discussion.postDate ) }}
					</p>
				</div>


				<div class="discussion-card__thumbnail">
					<img
						v-if="discussion.thumbnail_image_url"
						:src="discussion.thumbnail_image_url"
						:alt="discussion.title"
						class="discussion-card__thumbnail-image"
					>
					<div
						v-else
						class="discussion-card__thumbnail-image discussion-card__thumbnail-image--default"
					/>
				</div>

				<div
					v-if="discussion.latest_comment_author"
					class="discussion-card__last-comment"
				>
					<div class="discussion-card__last-comment-image">
						<ProfilePictureComponent
						:user="discussion.latest_comment_author"
						size="xs"
						/>
					</div>
					<div class="discussion-card__last-comment-content">
						<h3 class="discussion-card__last-comment-heading">
							Last comment by {{  discussion.latest_comment_author.fullName }}
						</h3>
						<div
							v-html="discussion.latest_comment_content"
							class="discussion-card__last-comment-body"
						/>
					</div>
				</div>
			</router-link>
		</li>
	</TransitionGroup>
</template>

<script>
import ProfilePictureComponent from './ProfilePictureComponent.vue';

import moment from 'moment';

export default {
	components: {
		ProfilePictureComponent
	},
	props: {
		discussions: { required: true, type: Array }
	},
	emits: [ 'set-active-discussion' ],
	methods: {
		getDate( timestamp ) {
			return moment( new Date( timestamp ) ).format( 'D MMM' );
		},
		getAttachmentCount( count ) {
			return !count || count > 1 ? count + ' attachments' : count + ' attachment';
		},
		getCommentCount( count ) {
			return !count || count > 1 ? count + ' comments' : count + ' comment';
		}
	}
};
</script>
