<template>
	<div class="add-user-modal-wrapper">
		<div class="add-user-modal">
			<button
				class="add-user-modal__close"
				aria-label="Close modal"
				@click="closeModal"
			/>
			<div class="add-user-modal__content">
				<h2 class="add-user-modal__heading">
					Add {{ userType }}
				</h2>

				<template
					v-for="user, index in users"
					:key="'user-' + index"
				>
					<div class="flex flex--align-center flex--gap-medium">
						<div class="form__set">
							<label
								:for="'user-' + index"
								class="form__label"
								:class="{ 'invisible': index > 0 }"
							>
								{{ input_label }}
							</label>

							<div
								v-if="filtered_user_list && filtered_user_list.length"
								class="form__icon form__icon--down"
							>
								<select
									v-model="user.value"
									:id="'user-' + index"
									class="form__select form-select__stretch"
									:class="{ 'form__select--error': errors[ user.id ] }"
								>
									<option
										v-for="available_user in filtered_user_list"
										:key="`submission-${ available_user.value }`"
										:value="available_user.value"
										:disabled="!available_user.value"
									>
										{{ available_user.label }}
									</option>
								</select>
							</div>
							<div v-else>
								<p>
									No users found
								</p>
							</div>
						</div>

						<button
							v-if="index > 0"
							class="icon icon--close mb-16"
							@click="removeUser( index )"
						/>
					</div>

					<div
						v-if="errors[ user.id ]"
						class="form__error"
					>
						<p>
							{{ errors[ user.id ] }}
						</p>
					</div>
				</template>

				<button
					v-if="filtered_user_list && filtered_user_list.length && can_add_multiple"
					class="add-user-modal__add-another-button"
					@click="addUser"
				>
					{{ add_another_label }}
				</button>

				<p
					v-if="errorMessage"
					class="error-message"
				>
					{{ errorMessage }}
				</p>

				<div class="add-user-modal__buttons mb-16">
					<button
						class="button"
						:disabled="loading || !filtered_user_list || !filtered_user_list.length"
						@click="closeModal"
					>
						Cancel
					</button>
					<button
						class="button button--fill"
						:class="{ 'button--loading' : loading }"
						:disabled="loading || !filtered_user_list || !filtered_user_list.length"
						@click="addSelectedUsersToProject"
					>
						Add {{ userType }}
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { useDataStore } from '../../stores/data';
import {
	USER_TYPE_BUSINESS_DEVELOPMENT,
	USER_TYPE_ACCOUNT_DIRECTOR,
	USER_TYPE_PROJECT_MANAGER,
	USER_TYPE_LEAD_ARTIST,
	USER_TYPE_ARTIST,
	USER_TYPE_CLIENT
} from '../../constants';

export default {
	props: {
		project: {
			required: true,
			type: Object
		},
		userList: {
			required: true,
			type: Array
		},
		userType: {
			required: true,
			type: String
		},
		loading: {
			required: true,
			type: Boolean
		},
		errors: {
			required: false,
			type: Array
		},
		errorMessage: {
			required: false,
			type: String
		}
	},
	emits: [ 'add-users-to-project', 'close-modal' ],
	data() {
		return {
			users: [
				{
					id: `user-${Date.now()}`,
					value: null
				}
			]
		};
	},
	computed: {
		filtered_user_list() {
			if ( this.userType === USER_TYPE_ARTIST ) {
				return this.userList.filter( user => !this.project.project_artists.find( artist => artist.id === user.value ) );
			}
			if ( this.userType === USER_TYPE_CLIENT ) {
				return this.userList.filter( user => !this.project.clients.find( client => client.id === user.value ) );
			}
			return this.userList;
		},
		input_label() {
			switch ( this.userType ) {
				case USER_TYPE_BUSINESS_DEVELOPMENT:
					return 'Select a business development director';
				case USER_TYPE_ACCOUNT_DIRECTOR:
					return 'Select an account director';
				case USER_TYPE_LEAD_ARTIST:
					return `Select a lead artist`;
				case USER_TYPE_PROJECT_MANAGER:
					return `Select a project manager`;
				case USER_TYPE_ARTIST:
					return 'Select one or more artists';
				case USER_TYPE_CLIENT:
					return 'Select one or more clients or partners';
				default:
					return 'Select a user';
			}
		},
		add_another_label() {
			if ( this.userType === USER_TYPE_ARTIST ) {
				return 'Add another artist';
			}
			if ( this.userType === USER_TYPE_CLIENT ) {
				return 'Add another client or partner';
			}
		},
		can_add_multiple() {
			return this.userType === USER_TYPE_ARTIST || this.userType === USER_TYPE_CLIENT;
		}
	},
	methods: {
		addUser() {
			this.users.push( {
				id: `user-${Date.now()}`,
				value: null
			} );
		},
		removeUser( index ) {
			this.users.splice( index, 1 );
		},
		closeModal() {
			this.$emit( 'close-modal' );
		},
		addSelectedUsersToProject() {
			this.$emit( 'add-users-to-project', this.users );
		}
	}
};
</script>