<template>
	<div class="container">
		<Transition name="fade">
			<PageLoaderComponent v-if="loading" />
			<div v-else-if="option">
				<div class="mb-32">
					<h1 class="h1">
						Database
					</h1>
				</div>
				<Transition name="fade">
					<div>
						<router-link
							:to="{ name: 'database' }"
							class="back-to mb-32"
						>
							Back to database
						</router-link>

						<div class="flex flex--justify-space-between flex--align-start mb-16">
							<h2 class="h4">
								{{ option.related_project[0].title }} - {{ option.title }}
							</h2>

							<div class="flex flex--gap-medium">
								<button
									class="button button--narrow button--icon icon flex flex--align-center"
									:class="[
										user_likes_option ? 'icon--heart' : 'icon--heart-outline',
										{ 'button--loading': loading_like }
									]"
									:disabled="loading_like"
									@click="toggleLike"
								>
									{{ likes.length }}
								</button>
							</div>
						</div>

						<div class="flex flex--align-center mb-24">
							<router-link
								class="a a--underline"
								:to="{ name: 'projects__project', params: { project_id: option.related_project[0].id } }"
							>
								Go to project
							</router-link>
						</div>

						<img
							v-if="option.image_large_url"
							:src="option.image_large_url"
							:alt="option.title"
							class="mb-64"
						>

						<video
							v-else-if="option.video_url"
							class="mb-64"
							:src="option.video_url"
							:alt="option.title"
							controls
						/>

						<div
							v-else-if="option.tour_id"
							ref="iframe-container"
							class="iframe-container mb-64"
							@mousewheel="preventWindowScroll"
						>
							<iframe
								id="embed"
								class="tour"
								:src="`https://www.viewform.co/tours/${option.tour_id}`"
								frameborder="0"
							/>
						</div>
					</div>
				</Transition>
			</div>
		</Transition>
	</div>
</template>

<script>
import PageLoaderComponent from '../components/PageLoaderComponent.vue';

import { useDataStore } from '../stores/data';
import { storeToRefs } from 'pinia';
import { USER_GROUP_CLIENT } from '../constants';

export default {
	components: {
		PageLoaderComponent
	},
	setup() {
		const data_store = useDataStore();
		const { user_group, user_profile } = storeToRefs( data_store );
		const {
			getDatabaseOptionById,
			getAssetByOptionId,
			getOptionLikes,
			likeOption,
			unlikeOption,
			setBreadcrumbs
		} = data_store;
		return {
			user_group,
			user_profile,
			getDatabaseOptionById,
			getAssetByOptionId,
			getOptionLikes,
			likeOption,
			unlikeOption,
			setBreadcrumbs
		};
	},
	data() {
		return {
			loading: true,
			option: null,
			likes: [],
			loading_like: false
		};
	},
	computed: {
		user_is_client() {
			return this.user_group === USER_GROUP_CLIENT;
		},
		user_likes_option() {
			return this.likes.find( like => parseInt( like.user.id ) === parseInt( this.user_profile.id ) );
		}
	},
	async mounted() {
		if ( this.user_is_client ) {
			this.$router.push( { name: 'home' } );
			return;
		}

		try {
			const response = await this.getDatabaseOptionById( this.$route.params.option_id );
			if ( response.data.entry ) {
				this.option = response.data.entry;
			}
		} catch ( error ) {
			// console.log( error );
		}

		if ( !this.option ) {
			this.$router.push( { name: 'database' } );
			return;
		}

		try {
			const response = await this.getOptionLikes( parseInt( this.$route.params.option_id ) );
			this.likes = response.data.optionLikes;
		} catch ( error ) {
			// console.log( error );
		}

		this.setBreadcrumbs( [
			{
				label: 'Database',
				route: {
					name: 'database'
				},
				icon: 'layout-grid'
			},
			{
				label: `${this.option.related_project[0].title} - ${this.option.title}`,
			}
		] );
		this.loading = false;
	},
	methods: {
		async toggleLike() {
			this.loading_like = true;
			if ( this.user_likes_option ) {
				await this.unlikeOption( parseInt( this.$route.params.option_id ) );
			} else {
				await this.likeOption( parseInt( this.$route.params.option_id ) );
			}

			try {
				const response = await this.getOptionLikes( parseInt( this.$route.params.option_id ) );
				this.likes = response.data.optionLikes;
			} catch ( error ) {
				// console.log( error );
			}

			this.loading_like = false;
		}
	}
};
</script>
