<template>
	<div
		class="alert-banner"
		:class="{ 'alert-banner--reveal': alert_message }"
	>
		<div class="alert-banner__content">
			<p>
				{{ alert_message }}
			</p>
		</div>
		<button
			class="alert-banner__close"
			@click="closeBanner"
		/>
	</div>
</template>

<script>
import { useDataStore } from '../stores/data';
import { storeToRefs } from 'pinia';

export default {
	setup() {
		const data_store = useDataStore();
		const { setAlertMessage } = data_store;
		const { alert_message } = storeToRefs( data_store );
		return {
			alert_message,
			setAlertMessage
		};
	},
	watch: {
		$route() {
			this.setAlertMessage( null );
		}
	},
	methods: {
		closeBanner() {
			this.setAlertMessage( null );
		}
	}
};
</script>
