import { createRouter, createWebHistory } from 'vue-router';

import AssetView from 'views/AssetView.vue';
import BugReportView from 'views/BugReportView.vue';
import DatabaseView from 'views/DatabaseView.vue';
import DatabaseOptionView from 'views/DatabaseOptionView.vue';
import FeatureRequestView from 'views/FeatureRequestView.vue';
import HomeView from 'views/HomeView.vue';
import LoginView from 'views/LoginView.vue';
import MyBoundaryView from 'views/MyBoundaryView.vue';
import MyBoundaryPageView from 'views/MyBoundaryPageView.vue';
import NotificationsView from 'views/NotificationsView.vue';
import ProjectView from 'views/ProjectView.vue';
import ProjectsView from 'views/ProjectsView.vue';
import RequestPasswordResetView from 'views/RequestPasswordResetView.vue';
import SettingsView from 'views/SettingsView.vue';
import SupportView from 'views/SupportView.vue';

const routes = [
	{
		path: '/',
		component: HomeView,
		name: 'home',
		meta: {
			title: 'Home'
		}
	},
	{
		path: '/login',
		component: LoginView,
		name: 'login',
		meta: {
			title: 'Log In'
		}
	},
	{
		path: '/request-password-reset',
		component: RequestPasswordResetView,
		name: 'request-password-reset',
		meta: {
			title: 'Password Reset'
		}
	},
	{
		path: '/projects',
		component: ProjectsView,
		name: 'projects',
		meta: {
			title: 'Projects'
		}
	},
	{
		path: '/projects/:project_id',
		component: ProjectView,
		name: 'projects__project',
		meta: {
			// Title is set in the ProjectView component
		}
	},
	{
		path: '/projects/:project_id/asset/:asset_id',
		component: AssetView,
		name: 'projects__project__asset',
		meta: {
			// Title is set in the AssetView component
		}
	},
	{
		path: '/database',
		component: DatabaseView,
		name: 'database',
		meta: {
			title: 'Database'
		}
	},
	{
		path: '/database/:option_id',
		component: DatabaseOptionView,
		name: 'database__option',
		meta: {
			title: 'Database'
		}
	},
	{
		path: '/notifications',
		component: NotificationsView,
		name: 'notifications',
		meta: {
			title: 'Notifications'
		}
	},
	{
		path: '/support',
		component: SupportView,
		name: 'support',
		meta: {
			title: 'Support'
		}
	},
	{
		path: '/support/bug-report',
		component: BugReportView,
		name: 'support__bug-report',
		meta: {
			title: 'Bug Report'
		}
	},
	{
		path: '/support/feature-request',
		component: FeatureRequestView,
		name: 'support__feature-request',
		meta: {
			title: 'Feature Request'
		}
	},
	{
		path: '/settings',
		component: SettingsView,
		name: 'settings',
		meta: {
			title: 'Settings'
		}
	},
	{
		path: '/my-boundary',
		component: MyBoundaryView,
		name: 'my-boundary',
		meta: {
			title: 'My Boundary'
		}
	},
	{
		path: '/my-boundary/:page',
		component: MyBoundaryPageView,
		name: 'my-boundary__page',
		meta: {
			title: 'My Boundary'
		}
	},
];

export const router = createRouter( {
	history: createWebHistory(),
	routes,
} );
