<template>
	<CheckboxesDropdownComponent
		id="completion-date"
		label="Completion Date"
		:options="completion_date.options"
		:selection="active_completion_dates"
		:stretch="true"
		:search-disabled="true"
		@select="handleOptionSelect"
	/>
</template>

<script setup>
import CheckboxesDropdownComponent from '../CheckboxesDropdownComponent.vue';

import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useProjectFiltersStore } from '../../stores/project-filters';

const filters_store = useProjectFiltersStore();

const {
	setActiveFilterValue,
	removeActiveFilterValue,
	resetProjectPagination,
	updateQueryStringFromProjectFilters,
} = filters_store;

const { completion_date } = storeToRefs( filters_store );

const active_completion_dates = computed( () => {
	return completion_date.value.active.map( option => option.value );
} );

const handleOptionSelect = ( option ) => {
	option.checked ? setActiveFilterValue( option, 'completion_date' ) : removeActiveFilterValue( option, 'completion_date' );
	resetProjectPagination();
	updateQueryStringFromProjectFilters();
};
</script>