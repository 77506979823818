
<template>
	<div class="container">
		<Transition name="fade">
			<PageLoaderComponent v-if="loading" />
			<div v-else>
				<div class="home-header">
					<h1 class="h1">
						Home
					</h1>
					<p class="home-header__welcome">
						Welcome back, {{ first_name }}
					</p>
				</div>

				<div class="divider divider--top divider--stretch mb-32" />

				<div class="mb-32">
					<h2 class="h5">
						My Projects
					</h2>
				</div>

				<div
					class="results-grid"
					:class="{ 'results-grid--loading': loading_my_projects }"
				>
					<TransitionGroup
						v-if="projects && projects.length"
						name="grid"
						tag="ul"
						class="grid grid--xs flex flex--wrap relative mb-32"
					>
						<li
							v-for="project in projects"
							:key="'my-project-' + project.id"
							class="grid__item a3-12 f6-6 mb-8"
						>
							<router-link
								:to="{ name: 'projects__project', params: { project_id: project.id } }"
								class="project-card"
							>
								<img
									v-if="getThumbnailOption( project )"
									:src="getThumbnailOption( project ).image_small_url || getThumbnailOption( project ).thumbnail_url"
									:alt="getThumbnailOption( project ).title"
									class="project-card__image"
								>
								<div
									v-else
									class="project-card__image project-card__image--default"
								/>
								<h2 class="project-card__title">
									{{ project.title }}
								</h2>

								<button
									:ref="`favourite-${project.id}`"
									class="project-card__favourite"
									:class="[
										{ 'project-card__favourite--selected': userHasFavouritedProject( project.id ) },
										{ 'project-card__favourite--toggled': toggled_favourites.includes( project.id ) },
										{ 'project-card__favourite--loading': loading_favourites.includes( project.id ) }
									]"
									@click.prevent="toggleProjectAsFavourite( project.id )"
								/>

								<router-link
									v-if="project.notification_count"
									:to="{ name: 'projects__project', params: { project_id: project.id }, query: { tab: 'notifications' } }"
									class="project-card__notifications"
								>
									<div class="project-card__alerts">
										<span class="project-card__notifications-count">
											{{ project.notification_count }}
										</span>
									</div>
								</router-link>
							</router-link>
						</li>
					</TransitionGroup>
					<p
						v-else
						class="mb-64"
						>
						Not assigned to any projects.
					</p>
					<div
						v-show="total_projects_count > projects.length"
						ref="load_more_my_projects_trigger"
						class="load-more-trigger"
					/>
				</div>
			</div>
		</Transition>
	</div>
</template>

<script>
import PageLoaderComponent from '../components/PageLoaderComponent';
import TabNavigationComponent from '../components/TabNavigationComponent';

import { useDataStore } from '../stores/data';
import { storeToRefs } from 'pinia';
import {
	USER_GROUP_CLIENT,
	USER_GROUP_FREELANCER
} from '../constants';
import { filterOutDuplicates, getProjectThumbnailOption } from '../../helpers';

import { useElementVisibility } from '@vueuse/core';
import { nextTick, ref } from 'vue';

export default {
	components: {
		PageLoaderComponent,
		TabNavigationComponent
	},
	setup() {
		const data_store = useDataStore();
		const {
			getUserRelatedProjects,
			getUserProfile,
			getPendingNotificationsCountByProjectId,
			setBreadcrumbs,
			toggleFavouriteProject,
			user_group
		} = data_store;

		const { user_profile } = storeToRefs( data_store );

		const load_more_my_projects_trigger = ref( null );
		const my_projects_trigger_is_visible = useElementVisibility( load_more_my_projects_trigger );

		return {
			user_profile,
			load_more_my_projects_trigger,
			my_projects_trigger_is_visible,
			getUserRelatedProjects,
			getUserProfile,
			getPendingNotificationsCountByProjectId,
			setBreadcrumbs,
			toggleFavouriteProject,
			user_group
		};
	},
	data() {
		return {
			loading: true,
			loading_my_projects: false,
			projects: [],
			limit: 12,
			page: 1,
			total_projects_count: null,
			toggled_favourites: [],
			loading_favourites: [],
		};
	},
	computed: {
		first_name() {
			return this.user_profile.firstName;
		},
		user_is_not_staff() {
			return this.user_group === USER_GROUP_CLIENT || this.user_group === USER_GROUP_FREELANCER;
		},
	},
	watch: {
		async my_projects_trigger_is_visible( new_value, old_value ) {
			if ( new_value && !old_value && !this.loading_my_projects ) {
				await this.getMoreMyProjects();
			}
		}
	},
	beforeMount() {
		if ( this.user_is_not_staff ) {
			this.$router.push( { name: 'projects' } );
		}
	},
	async mounted() {
		this.loading = true;
		this.setBreadcrumbs( [ { label: 'Home', icon: 'home' } ] );
		await this.fetchMyProjects();

		this.loading = false;
	},
	methods: {
		async fetchMyProjects() {
			this.loading_my_projects = true;

			try {
				let params = {
					limit: this.limit,
					search: '',
					offset: ( this.page - 1 ) & this.limit,
				};

				let response = await this.getUserRelatedProjects( params );

				if ( this.page === 1 ) {
					this.projects = [];
				}

				this.addProjectsToArray( response.data.projects );
				this.total_projects_count = response.data.total_count;
			} catch ( error ) {
				// console.log( error );
			}

			this.fetchNotificationCounts();
			this.loading_my_projects = false;
		},
		async getMoreMyProjects() {
			this.page++;
			await nextTick();
			await this.fetchMyProjects();
		},
		async fetchNotificationCounts() {
			for ( let i = 0; i < this.projects.length; i++ ) {
				try {
					const count = await this.getPendingNotificationsCount( {
						project_ids: [ parseInt( this.projects[i].id ) ]
					} );
					this.projects[i].notification_count = count;
				} catch ( error ) {
					// console.log( error );
				}
			}
		},
		addProjectsToArray( projects ) {
			this.projects.push( ...projects );
			this.projects = filterOutDuplicates( this.projects );
		},
		getThumbnailOption( project ) {
			return getProjectThumbnailOption( project );
		},
		userHasFavouritedProject( project_id ) {
			if ( !this.user_profile ) {
				return false;
			}
			return this.user_profile.favourite_projects.find( project => parseInt( project.id ) === parseInt( project_id ) );
		},
		async toggleProjectAsFavourite( project_id ) {
			const toggled_index = this.toggled_favourites.indexOf( project_id );
			if ( toggled_index ) {
				this.toggled_favourites.splice( this.toggled_favourites.indexOf( project_id ), 1 );
			}
			this.loading_favourites.push( project_id );
			try {
				const response = await this.toggleFavouriteProject( parseInt( project_id ) );
				if ( response.data ) {
					await this.getUserProfile();
				}
			} catch ( error ) {
				// console.log( error );
			}
			this.toggled_favourites.push( project_id );
			this.loading_favourites.splice( this.loading_favourites.indexOf( project_id ), 1 );
			this.$refs[`favourite-${project_id}`][0].blur();
		}
	}
};

</script>
