<template>
	<div class="delete-asset-modal-wrapper">
		<div class="delete-asset-modal">
			<button
				class="delete-asset-modal__close"
				aria-label="Close modal"
				@click="closeModal"
			/>
			<div class="delete-asset-modal__content">
				<h2 class="delete-asset-modal__heading">
					Delete asset
				</h2>

				<p class="delete-asset-modal__text">
					Are you sure you want to delete this asset? This action is <strong>irreversible</strong> and will also delete all stages uploaded to this asset.
				</p>


				<p
					v-if="error_message"
					class="error-message"
				>
					{{ error_message }}
				</p>
			</div>

			<div class="delete-asset-modal__buttons">
				<button
					class="button"
					@click="$emit( 'close-modal' )"
				>
					Cancel
				</button>
				<button
					class="button button--fill-danger"
					:class="{ 'button--loading': loading }"
					@click="deleteAsset"
				>
					Delete
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import { useDataStore } from '../../stores/data';

export default {
	props: {
		asset: {
			required: true,
			type: Object
		},
	},
	emits: [ 'asset-deleted', 'close-modal' ],
	setup() {
		const data_store = useDataStore();
		const { deleteAsset } = data_store;
		return { deleteAsset };
	},
	data() {
		return {
			loading: false,
			error_message: null
		};
	},
	methods: {
		closeModal() {
			this.$emit( 'close-modal' );
		},
		async deleteAsset() {
			this.error_message = null;
			this.loading = true;

			try {
				const response = await this.deleteAsset( { asset_id: parseInt( this.asset.id ) } );
				if ( response && response.data ) {
					this.$emit( 'asset-deleted' );
				}
			} catch ( error ) {
				this.error_message = error;
				this.loading = false;
			}
		}
	}
};
</script>