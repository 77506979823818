import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["CheckboxesDropdownComponent"], {
    id: "completion-date",
    label: "Completion Date",
    options: $setup.completion_date.options,
    selection: $setup.active_completion_dates,
    stretch: true,
    "search-disabled": true,
    onSelect: $setup.handleOptionSelect
  }, null, 8 /* PROPS */, ["options", "selection"]))
}