
<template>
	<div class="container">
		<Transition name="fade">
			<PageLoaderComponent v-if="loading" />
			<div v-else>
				<div class="mb-48">
					<h1 class="h1">
						{{ page.title }}
					</h1>
				</div>
				<ul class="a8-12 f6-6 flex flex--wrap flex--gap-medium mb-24">
					<li
						v-for="block in page.content_blocks"
						:key="`nav-link-${block.id}`"
					>
						<a
							:href="`#${ slugifyTitle( block.heading ) }`"
							class="p--small a a--underline"
						>
							{{ block.heading }}
						</a>
					</li>
				</ul>

				<div class="grid flex flex--wrap-reverse">
					<div class="grid__item a8-12 f6-6 mb-64">
						<div
							v-for="block in page.content_blocks"
							:key="`block-${block.id}`"
							class="my-boundary-content-block"
							:class="getContentBlockClass( block.typeHandle )"
						>
							<template v-if="block.typeHandle === 'heading_and_text'">
								<h2
									:id="slugifyTitle( block.heading )"
									class="my-boundary-content-block__heading"
								>
									{{ block.heading }}
								</h2>
								<!-- eslint-disable vue/no-v-html -->
								<div
									class="my-boundary-content-block__text typeset"
									v-html="block.text"
								/>
							</template>
						</div>
					</div>

					<div class="grid__item a4-12 f6-6">
						<div class="quick-links-container">
							<div class="mb-16">
								<h2 v-if="page.quick_links.length">
									Quick links
								</h2>
							</div>

							<div
								v-for="quick_link in page.quick_links"
								:key="quick_link.external_url"
								class="mb-32"
							>
								<a
									v-if="quick_link.logo_colour.length || quick_link.logo_monochrome.length"
									:href="quick_link.external_url"
									target="_blank"
								>
									<img
										v-if="quick_link.logo_colour.length"
										:src="quick_link.logo_colour[0].url"
										class="quick-link"
									/>
									<img
										v-else-if="quick_link.logo_monochrome.length"
										:src="quick_link.logo_monochrome[0].url"
										class="quick-link"
									/>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Transition>
	</div>
</template>

<script>
import PageLoaderComponent from '../components/PageLoaderComponent';
import MyBoundaryCardComponent from '../components/MyBoundaryCardComponent';

import { useDataStore } from '../stores/data';
import { storeToRefs } from 'pinia';
import { USER_GROUP_STAFF } from '../constants';
import { nextTick } from 'vue';

import slugify from 'slugify';

export default {
	components: {
		PageLoaderComponent,
		MyBoundaryCardComponent
	},
	setup() {
		const data_store = useDataStore();
		const {
			getMyBoundaryPageBySlug,
			setBreadcrumbs
		} = data_store;

		const { user_group } = storeToRefs( data_store );

		return {
			user_group,
			getMyBoundaryPageBySlug,
			setBreadcrumbs
		};
	},
	data() {
		return {
			page: null,
			loading: true
		};
	},
	computed: {
		user_is_staff() {
			return this.user_group === USER_GROUP_STAFF;
		}
	},
	async mounted() {
		this.loading = true;
		if ( !this.user_is_staff ) {
			this.$router.push( { name: 'projects' } );
		}
		try {
			const response = await this.getMyBoundaryPageBySlug( this.$route.params.page );
			if ( response.data && response.data.entry ) {
				this.page = response.data.entry;
			}
		} catch( error ) {
			// console.log( error );
		}
		if ( !this.page ) {
			this.$router.push( { name: 'my-boundary' } );
		}

		this.setBreadcrumbs( [
			{
				label: 'My Boundary',
				icon: 'user-outline',
				route: {
					name: 'my-boundary'
				}
			},
			{ label: this.page.title }
		] );

		this.loading = false;

		await nextTick();
		if ( this.$route.hash ) {
			document.querySelector( this.$route.hash ).scrollIntoView( { behavior: 'smooth', block: 'start' } );
		}
	},
	methods: {
		getContentBlockClass( type_handle ) {
			switch ( type_handle ) {
				case 'heading_and_text':
					return 'my-boundary-content-block--heading-and-text';
			}
		},
		slugifyTitle( title ) {
			return slugify( title ).toLowerCase();
		}
	}
};

</script>
