import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["CheckboxesDropdownComponent"], {
    id: "project-type",
    label: "Project Type",
    placeholder: "Search",
    options: $setup.project_type.types,
    selection: $setup.active_types,
    stretch: true,
    "search-disabled": true,
    loading: $setup.loading_types,
    onSelect: $setup.handleTypeSelect
  }, null, 8 /* PROPS */, ["options", "selection", "loading"]))
}