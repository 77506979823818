<template>
	<li class="sidebar-notification-wrapper">
		<router-link
			class="sidebar-notification"
			:to="notification.action_url"
		>
			<div class="sidebar-notification__header">
				<div class="sidebar-notification__activity-image">
					<template
						v-if="project_thumbnail_option"
					>
						<img
							v-if="project_thumbnail_option.image_small_url || project_thumbnail_option.thumbnail_url"
							:src="project_thumbnail_option.image_small_url || project_thumbnail_option.thumbnail_url"
							class="sidebar-notification__project-thumbnail"
						>
					</template>
					<div
						v-else
						class="sidebar-notification__project-thumbnail sidebar-notification__project-thumbnail--default"
					/>
					<div class="sidebar-notification__profile-image">
						<ProfilePictureComponent
							:user="related_user"
							size="xs"
							:rounded="true"
							:bordered="true"
						/>
					</div>
				</div>
				<div class="sidebar-notification__title">
					<h2>{{ this.notification.project ? this.notification.project.title : 'Unknown project' }}</h2>
					<p class="sidebar-notification__label">
						{{ notification_label }}
					</p>
				</div>
				<p class="sidebar-notification__timestamp">
					{{ timestamp }}
				</p>
			</div>
			<div class="sidebar-notification__main">
				<div class="sidebar-notification__content">
					<div
						v-if="['OptionCommentPosted', 'DiscussionCommentPosted'].includes(notification.type)"
						class="sidebar-notification__body"
					>
						<div
							v-if="notification.entry.comment_content"
							class="sidebar-notification__comment-content"
							v-html="notification.entry.comment_content"
						/>

						<div class="sidebar-notification__comment-metadata">
							<div
								v-if="getImages( notification.entry.comment_attachments ).length"
								class="sidebar-notification__image-attachments"
							>
								<div
									v-for="attachment, index in getImages( notification.entry.comment_attachments )"
									:key="`${notification.entry.id}-image-attachment-${index}`"
									class="sidebar-notification__image-attachment-thumbnail"
								>
									<img
										class="sidebar-notification__image-attachment"
										:src="attachment.attachment_thumbnail_url"
									>
								</div>
							</div>
							<p
								v-if="filesPending( notification.entry )"
								class="sidebar-notification__files-pending"
							>
								{{ filesPending( notification.entry ) }} attachment(s) processing
							</p>

							<div
								v-if="getFiles( notification.entry.comment_attachments ).length"
								class="sidebar-notification__links"
							>
								<p
									v-for="attachment, index in getFiles( notification.entry.comment_attachments )"
									:key="`${notification.entry.id}-image-attachment-${index}`"
									class="sidebar-notification__pdf-attachment"
								>
									{{ attachment.attachment_filename }}
								</p>
							</div>
						</div>
					</div>

					<div
						v-else-if="notification.type === 'UploadAdded'"
						class="sidebar-notification__body"
					>
						<div
							v-if="notification.entries"
							class="sidebar-notification__thumbnails"
						>
							<template
								v-for="option, index in notification.entries"
								:key="option.id"
							>
								<img
									v-if="index < 10 && ( option.image_small_url || option.thumbnail_url )"
									class="sidebar-notification__thumbnail"
									:src="option.image_small_url || option.thumbnail_url"
								>
							</template>
						</div>
					</div>

					<div
						v-else-if="notification.type === 'SubmissionCreated'"
						class="sidebar-notification__body"
					>
						<div
							v-if="notification.entry && notification.entry.options"
							class="sidebar-notification__thumbnails"
						>
							<template
								v-for="option, index in notification.entry.options"
								:key="option.id"
							>
								<img
									v-if="index < 10 && ( option.image_small_url || option.thumbnail_url )"
									class="sidebar-notification__thumbnail"
									:src="option.image_small_url || option.thumbnail_url"
								>
							</template>
						</div>
					</div>

					<div
						v-else-if="notification.type === 'OptionClientApproved'"
						class="sidebar-notification__body"
					>
						<div
							v-if="notification.entry"
							class="sidebar-notification__thumbnails"
						>
							<img
								v-if="notification.entry.image_small_url || notification.entry.thumbnail_url"
								class="sidebar-notification__thumbnail"
								:src="notification.entry.image_small_url || notification.entry.thumbnail_url"
							>
						</div>
					</div>

					<div
						v-else-if="notification.type === 'RevisionClientApproved'"
						class="sidebar-notification__body"
					>
						<div
							v-if="notification.entry"
							class="sidebar-notification__thumbnails"
						>
							<template
								v-for="option, index in notification.entry.revision_options"
								:key="option.id"
							>
								<img
									v-if="index < 10 && ( option.image_small_url || option.thumbnail_url )"
									class="sidebar-notification__thumbnail"
									:src="option.image_small_url || option.thumbnail_url"
								>
							</template>
						</div>
					</div>
				</div>
			</div>
		</router-link>
	</li>
</template>

<script>
import ProfilePictureComponent from './ProfilePictureComponent.vue';
import { getTimePassed, getProjectThumbnailOption } from '../../helpers';

export default {
	components: {
		ProfilePictureComponent
	},
	props: {
		notification: { required: true, type: Object }
	},
	emits: [ 'mark-as-read' ],
	computed: {
		related_user() {
			switch ( this.notification.type ) {
				case 'OptionCommentPosted':
				case 'DiscussionCommentPosted':
				case 'DiscussionCreated':
					if ( !this.notification.entry || !this.notification.entry.author ) {
						return { fullName: 'Unknown user' };
					}
					return this.notification.entry.author;
				default:
					if ( !this.notification.user || !this.notification.user.fullName ) {
						return { fullName: 'Unknown user' };
					}
					return this.notification.user;
			}
		},
		project_thumbnail_option() {
			if ( !this.notification.project ) {
				return null;
			}
			return getProjectThumbnailOption( this.notification.project );
		},
		notification_label() {
			switch ( this.notification.type ) {
				case 'OptionCommentPosted':
				case 'DiscussionCommentPosted':
					return this.comment_notification_label;
				case 'DiscussionCreated':
					return this.discussion_notification_label;
				case 'OptionClientApproved':
					return this.approval_notification_label;
				case 'RevisionClientApproved':
					return this.final_approval_notification_label;
				case 'UploadAdded':
					return this.upload_notification_label;
				case 'SubmissionCreated':
					return this.submission_notification_label;
				default:
					return null;
			}
		},
		comment_notification_label() {
			switch ( this.notification.entry.typeHandle ) {
				case 'option_comment':
					return this.related_user.fullName + ' commented on ' + this.notification.asset.title;
				case 'discussion_comment':
					return this.related_user.fullName + ' left a comment on a discussion in ' + this.notification.project ? this.notification.project.title : 'a project';
				default:
					return null;
			}
		},
		discussion_notification_label() {
			return this.related_user.fullName + ' created a discussion in ' + ( this.notification.project ? this.notification.project.title : 'a project' );
		},
		upload_notification_label() {
			if ( !this.notification.entries || !this.notification.entries.length ) {
				return null;
			}
			return `${this.related_user.fullName} uploaded ${this.notification.entries.length} ${this.notification.entries[0].typeHandle}${this.notification.entries.length > 1 ? 's' : ''}`;
		},
		submission_notification_label() {
			if ( !this.notification.entry || !this.notification.entry.options.length ) {
				return null;
			}
			return `${this.related_user.fullName} created the ${this.notification.entry.title} submission in ${this.notification.project ? this.notification.project.title : 'a project'}`;
		},
		approval_notification_label() {
			if ( !this.notification.entry ) {
				return null;
			}
			return `${this.related_user.fullName} approved ${this.notification.entry.typeHandle === 'image' ? 'an image' : 'a ' + this.notification.entry.typeHandle} in ${this.notification.asset.title}`;
		},
		final_approval_notification_label() {
			if ( !this.notification.entry ) {
				return null;
			}
			return `${this.related_user.fullName} approved ${this.notification.entry.title} as the final asset`;
		},
		timestamp() {
			return getTimePassed( this.notification.dateCreated );
		}
	},
	methods: {
		markAsRead( id ) {
			this.$emit( 'mark-as-read', id );
		},
		filesPending( comment ) {
			if ( !comment.number_of_files_attached ) {
				return 0;
			}
			const files_pending = comment.number_of_files_attached - comment.comment_attachments.length;
			return files_pending <= 0 ? 0 : files_pending;
		},
		getImages( attachments ) {
			if ( !attachments ) {
				return [];
			}
			return attachments.filter( attachment => attachment.attachment_type === 'image' && attachment.attachment_thumbnail_url );
		},
		getFiles( attachments ) {
			if ( !attachments ) {
				return [];
			}
			return attachments.filter( attachment => attachment.attachment_type !== 'image' || !attachment.attachment_thumbnail_url );
		}
	}
};
</script>
